import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Button, Divider, IconButton} from "rsuite";
import {Edit} from "@rsuite/icons";
import TrashIcon from "@rsuite/icons/Trash";
import {cardStyle, headerCardStyle} from "../../../../Styles/ComponentStyles";
import CorrEditContact from "./CorrEditContact";
import {ContentRow} from "../../../ContentRow";
import cloneDeep from "lodash/cloneDeep";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useDispatch, useSelector} from "react-redux";
import {
    ADD_CORR_CONTACT, DELETE_CORR_CONTACT,
    UPDATE_CORR_CONTACT,
    getCorrContacts,
    getCorrId,
    TOGGLE_DLG
} from "../../../../Reducers/CorrugatorSlice";

export const ContactInitialValue = {
    id: '',
    firstname: '',
    name: '',
    jobtitle: '',
    email: '',
    businessphone: '',
    privatephone: ''
};

const Contact = forwardRef(({contactObj}, ref) => {
    const dispatch = useDispatch();
    const corrId = useSelector(getCorrId);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const oldContactObj = cloneDeep(contactObj);
    const [recordForEdit, setRecordForEdit] = useState(null);

    async function handleDeleteAction() {
        await CorrugatorDataServices.removeCorrugatorContact(corrId, contactObj).then((response) => {
            dispatch(DELETE_CORR_CONTACT(contactObj));
        }).catch(error => {
            throw new Error('Failed to delete Corr-Contact', error);
        });
    }

    const addOrEdit = async (contact, resetForm) => {
        // if recordForEdit null than we have a new Record otherwise Record exist already
        // if record exist already, delete from collection and write changed record again
        if (recordForEdit) {
            await CorrugatorDataServices.removeCorrugatorContact(corrId, oldContactObj)
                .then(await CorrugatorDataServices.addCorrugatorContact(corrId, contact)).then((response) => {
                    console.log('THEN RETURN VALUE -->', response);
                    dispatch(UPDATE_CORR_CONTACT(contact));
                }).catch(error => {
                    throw new Error('Failed to update Corr-Contact', error);
                });
        } else {
            contact.id = Date.now().toString();
            await CorrugatorDataServices.addCorrugatorContact(corrId, contact).then((response) => {
                dispatch(ADD_CORR_CONTACT(contact));
            }).catch(error => {
                throw new Error('Failed to add Corr-Contact', error);
            });
        }

        resetForm();
        setRecordForEdit(null);
        dispatch(TOGGLE_DLG(false));
    };

    const openInEditDlg = () => {
        setRecordForEdit(contactObj);
        dispatch(TOGGLE_DLG(true));
    };

    // is used for calling child function from parent
    useImperativeHandle(ref, () => ({
        handleNewContact() {
            dispatch(TOGGLE_DLG(true));
            setRecordForEdit(null);
        }
    }));

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <div style={headerCardStyle}>{contactObj.jobtitle}</div>
                    <div style={{color: 'var(--rs-text-tertiary)'}}>{contactObj.firstname} {contactObj.name}</div>
                    <a href={`mailto:${contactObj.email}`}>{contactObj.email}</a>
                    <ContentRow label={'Office'} value={contactObj.businessphone}/>
                    <ContentRow label={'Cell'} value={contactObj.privatephone}/>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IconButton appearance="subtle" onClick={openInEditDlg} icon={<Edit/>}/>
                        <Divider vertical/>
                        <IconButton appearance="subtle" onClick={handleDeleteAction} icon={<TrashIcon/>}/>
                    </div>
                </div>
            </div>
            <CorrEditContact recordForEdit={recordForEdit} addOrEdit={addOrEdit} open={openDlg} dispatch={dispatch}/>
        </>
    );
});

const CorrContactContent = () => {
    const contactsObj = useSelector(getCorrContacts);
    const childRef = useRef(null);
    console.log('CorrugatorContactContent -> Contact', contactsObj);

    // calling child function
    function handleNewContact() {
        console.log('CHILDREF -->', childRef);
        if (childRef.current)
            childRef.current.handleNewContact();
    }

    return (
        <>
            {contactsObj &&
                <>
                    {contactsObj.map(function (contact, index) {
                        return (
                            <div style={{display: "inline-block", margin: '0.5rem'}} key={index}>
                                <Contact ref={childRef} contactObj={contact}/>
                            </div>
                        )
                    })};
                </>
            }
            {(!contactsObj || contactsObj.length === 0) &&
                <Contact ref={childRef} contactObj={cloneDeep(ContactInitialValue)}/>
            }
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleNewContact} style={{width: '8rem'}}>New Contact</Button>
            </div>
        </>
    );
};

export default CorrContactContent;
