import {Schema} from "rsuite";

const {StringType} = Schema.Types;
const RevoverPasswordValidation = Schema.Model({
    email: StringType()
        .isRequired('This Email is required.')
        .isEmail('Please enter a valid email address.'),
});

export default RevoverPasswordValidation;
