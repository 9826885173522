import {Schema} from "rsuite";

//const {StringType, NumberType} = Schema.Types;
const {StringType} = Schema.Types;
const ContactValidation = Schema.Model({
    name: StringType()
        .isRequired('Name is required.')
        .minLength(2, "Can't be less than 2 characters")
        .maxLength(20, 'Cannot be greater than 20 characters'),
    firstname: StringType()
        .isRequired('First name is required.')
        .minLength(2, "Can't be less than 2 characters")
        .maxLength(20, 'Cannot be greater than 20 characters'),
    jobtitle: StringType()
        .isRequired("Jobtitle is required")
        .minLength(3, "Can't be less than 3 characters")
        .maxLength(30, 'Cannot be greater than 30 characters'),
});

export default ContactValidation;
