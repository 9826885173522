export const DryEndStruct = {
    aoc: false,
    asm: false,
    bdc: false,
    hb1: false,
    hb2: false,
    hq: false,
    kq: false,
    kqf: false,
    sr: false,
    webcleaner: false,
    com: false,
};
