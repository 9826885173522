import React from "react";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import {Button} from "rsuite";
import HBEditOptionsDlg from "./HBEditOptionsDlg";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const HBOptionContent = ({hbId}) => {
    const dispatch = useDispatch();
    // const options = SelectorHBOption(hbId);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Nothing'} value={null}/>
                </div>
           </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <HBEditOptionsDlg hbId={hbId} open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default HBOptionContent;
