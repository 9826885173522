import React, {useState} from "react";
import {Button, ButtonToolbar, Input, InputNumber, InputPicker, MaskedInput, Modal, Toggle} from "rsuite";
import {cardStyle, headerCardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {dataKnifebar, dataLevel, maskOptions} from "../../../../Resources/InputFormData";
import MotorEditCard from "../MotorEditCard";
import {isEqual, cloneDeep} from "lodash";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrHQ, getCorrId, TOGGLE_DLG, UPDATE_HQ} from "../../../../Reducers/CorrugatorSlice";

const HQEditOptionsDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const hq = useSelector(getCorrHQ);
    let tempState = cloneDeep(hq);
    let tempStateOptions = cloneDeep(hq.options);
    const [hqObj, setHQObj] = useState(hq);
    const [hqOptions, setHQOptions] = useState(hq.options);

    // console.log('Edit-HQ-DLG --> hq', hq);
    // console.log('Edit-HQ-DLG --> hqObj', hqObj);

    const handleSaveDlg = async () => {
        // trouble with Motor card....
        // console.log('SAVE-HQMOTORCARD -->', hqOptions);
        const tmp = cloneDeep(hqObj);
        tmp.options.mainmotordrive = hqOptions.mainmotordrive;
        tmp.options.mainmotortype = hqOptions.mainmotortype;
        tmp.options.mainmotorpower = hqOptions.mainmotorpower;
        tmp.options.motorcooling = hqOptions.motorcooling;
        // console.log('SAVE-HQOBJ -->', hqObj);

        await CorrugatorDataServices.updateCorrugator(corrId, {'hq': tmp}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_HQ(tmp));
            handleCloseDlg();
        }).catch(error => {
            throw new Error("HQ OPTIONS WRITE FUCKED UP -->", error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    const handleInputChanged = (value, e) => {
        // id is here the obj property name!!!!!
        setHQObj({...hqObj, options: {...hqObj.options, [e.target.id]: value}});
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='lg'
        >
            <Modal.Header>
                <Modal.Title>HQ - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    <div style={rowStyle}>
                        <div style={labelStyle}>Level</div>
                        <InputPicker
                            data={dataLevel}
                            id={'level'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={hqObj.options.level}
                            onSelect={(value) => setHQObj({...hqObj, options: {...hqObj.options, level: value}})}
                            style={{width: 130}}
                        />
                    </div>
                    <hr style={hrStyle}/>
                    <MotorEditCard optObj={hqOptions} setOptObj={setHQOptions}/>
                    <hr style={hrStyle}/>
                    {/*Knife bar*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Knife bar</div>
                        <InputPicker
                            data={dataKnifebar}
                            id={'knifebar'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={hqObj.options.knifebar}
                            onSelect={(value) => setHQObj({...hqObj, options: {...hqObj.options, knifebar: value}})}
                            style={{width: 130}}
                        />
                    </div>
                </div>

                <div style={cardStyle}>
                    {/*Double Outfeed Motor*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>2x Outfeed Motors</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={hqObj.options.doubleoutfeed}
                            onChange={checked => setHQObj({
                                ...hqObj,
                                options: {
                                    ...hqObj.options,
                                    doubleoutfeed: checked
                                }
                            })}
                        />
                    </div>
                    {/*Load Control*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Load Control</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={hqObj.options.loadcontrol}
                            onChange={checked => setHQObj({
                                ...hqObj,
                                options: {
                                    ...hqObj.options,
                                    loadcontrol: checked
                                }
                            })}
                        />
                    </div>
                    {/*Waste Flap*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Waste Flap</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={hqObj.options.wasteflap}
                            onChange={checked => setHQObj({
                                ...hqObj,
                                options: {
                                    ...hqObj.options,
                                    wasteflap: checked
                                }
                            })}
                        />
                    </div>
                    <hr style={hrStyle}/>
                    <div style={headerCardStyle}>Cut Off PC</div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>PC Type</div>
                        <Input
                            id='cutoffpctype'
                            placeholder='PC Type'
                            value={hqObj.options.cutoffpctype}
                            onChange={handleInputChanged}
                            style={{width: 130}}
                        />
                    </div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>SW Version</div>
                        <Input
                            id='cutoffpcversion'
                            placeholder='Software Version'
                            value={hqObj.options.cutoffpcversion}
                            onChange={handleInputChanged}
                            style={{width: 130}}
                        />
                    </div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>HQ 1</div>
                        <MaskedInput
                            id='cutoffpcipaddress1'
                            value={hqObj.options.cutoffpcipaddress[1]}
                            style={{width: 130}}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            // placeholderChar={'_'}
                            onChange={(value) => setHQObj({
                                ...hqObj,
                                options: {
                                    ...hqObj.options,
                                    cutoffpcipaddress: {...hqObj.options.cutoffpcipaddress, 1: value}
                                }
                            })}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>
                    {(hqObj.options.level === 'TRIPLEX' || hqObj.options.level === 'DUPLEX') &&
                        <div style={rowStyle}>
                            <div style={labelStyle}>HQ 2</div>
                            <MaskedInput
                                id='cutoffpcipaddress2'
                                value={hqObj.options.cutoffpcipaddress[2]}
                                style={{width: 130}}
                                mask={maskOptions[5].mask}
                                guide={true}
                                showMask={true}
                                keepCharPositions={true}
                                placeholder={maskOptions[5].placeholder}
                                // placeholderChar={'_'}
                                onChange={(value) => setHQObj({
                                    ...hqObj,
                                    options: {
                                        ...hqObj.options,
                                        cutoffpcipaddress: {...hqObj.options.cutoffpcipaddress, 2: value}
                                    }
                                })}
                                pipe={value => {
                                    const subips = value.split('.')
                                    const invalidSubips = subips.filter(ip => {
                                        ip = parseInt(ip)
                                        return ip < 0 || ip > 255
                                    })
                                    return invalidSubips.length > 0 ? false : value
                                }}
                            />
                        </div>
                    }

                    {(hqObj.options.level === 'TRIPLEX') &&
                        <div style={rowStyle}>
                            <div style={labelStyle}>HQ 3</div>
                            <MaskedInput
                                id='cutoffpcipaddress3'
                                value={hqObj.options.cutoffpcipaddress[3]}
                                style={{width: 130}}
                                mask={maskOptions[5].mask}
                                guide={true}
                                showMask={true}
                                keepCharPositions={true}
                                placeholder={maskOptions[5].placeholder}
                                // placeholderChar={'_'}
                                onChange={(value) => setHQObj({
                                    ...hqObj,
                                    options: {
                                        ...hqObj.options,
                                        cutoffpcipaddress: {...hqObj.options.cutoffpcipaddress, 3: value}
                                    }
                                })}
                                pipe={value => {
                                    const subips = value.split('.')
                                    const invalidSubips = subips.filter(ip => {
                                        ip = parseInt(ip)
                                        return ip < 0 || ip > 255
                                    })
                                    return invalidSubips.length > 0 ? false : value
                                }}
                            />
                        </div>
                    }
                </div>

                <div style={{display: "flex", flexDirection: 'column'}}>
                    {/*Preprint 1*/}
                    <div style={cardStyle}>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Pre Print 1 Exist</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={hqObj.preprint1.exist}
                                onChange={checked => setHQObj({
                                    ...hqObj,
                                    preprint1: {...hqObj.preprint1, exist: checked}
                                })}
                            />
                        </div>
                        {hqObj.preprint1.exist &&
                            <>
                                <hr style={hrStyle}/>
                                <div style={rowStyle}>
                                    <div style={labelStyle}>No of Top Heads</div>
                                    <InputNumber
                                        id='preprint1.topheads'
                                        value={hqObj.preprint1.topheads}
                                        onChange={(value) => setHQObj({
                                            ...hqObj,
                                            preprint1: {...hqObj.preprint1, topheads: Number(value)}
                                        })}
                                        min={0}
                                        max={5}
                                        style={{width: 80}}
                                    />
                                </div>
                                <div style={rowStyle}>
                                    <div style={labelStyle}>No of Bottom Heads</div>
                                    <InputNumber
                                        id='preprint1.bottomheads'
                                        value={hqObj.preprint1.bottomheads}
                                        onChange={(value) => setHQObj({
                                            ...hqObj,
                                            preprint1: {...hqObj.preprint1, bottomheads: Number(value)}
                                        })}
                                        min={0}
                                        max={5}
                                        style={{width: 80}}
                                    />
                                </div>
                                <div style={rowStyle}>
                                    <div style={labelStyle}>Color</div>
                                    <Toggle
                                        size="md"
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        checked={hqObj.preprint1.color}
                                        onChange={checked => setHQObj({
                                            ...hqObj,
                                            preprint1: {...hqObj.preprint1, color: checked}
                                        })}
                                    />
                                </div>
                                <div style={rowStyle}>
                                    <div style={labelStyle}>Camera</div>
                                    <Toggle
                                        size="md"
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        checked={hqObj.preprint1.camera}
                                        onChange={checked => setHQObj({
                                            ...hqObj,
                                            preprint1: {...hqObj.preprint1, camera: checked}
                                        })}
                                    />
                                </div>
                            </>
                        }
                    </div>

                    {/*Preprint 2*/}
                    <div style={cardStyle}>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Pre Print 2 Exist</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={hqObj.preprint2.exist}
                                onChange={checked => setHQObj({
                                    ...hqObj,
                                    preprint2: {...hqObj.preprint2, exist: checked}
                                })}
                            />
                        </div>
                        {hqObj.preprint2.exist &&
                            <>
                                <hr style={hrStyle}/>
                                <div style={rowStyle}>
                                    <div style={labelStyle}>No of Top Heads</div>
                                    <InputNumber
                                        id='preprint2.topheads'
                                        value={hqObj.preprint2.topheads}
                                        onChange={(value) => setHQObj({
                                            ...hqObj,
                                            preprint2: {...hqObj.preprint2, topheads: Number(value)}
                                        })}
                                        min={0}
                                        max={5}
                                        style={{width: 80}}
                                    />
                                </div>
                                <div style={rowStyle}>
                                    <div style={labelStyle}>No of Bottom Heads</div>
                                    <InputNumber
                                        id='preprint2.bottomheads'
                                        value={hqObj.preprint2.bottomheads}
                                        onChange={(value) => setHQObj({
                                            ...hqObj,
                                            preprint2: {...hqObj.preprint2, bottomheads: Number(value)}
                                        })}
                                        min={0}
                                        max={5}
                                        style={{width: 80}}
                                    />
                                </div>
                                <div style={rowStyle}>
                                    <div style={labelStyle}>Color</div>
                                    <Toggle
                                        size="md"
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        checked={hqObj.preprint2.color}
                                        onChange={checked => setHQObj({
                                            ...hqObj,
                                            preprint2: {...hqObj.preprint2, color: checked}
                                        })}
                                    />
                                </div>
                                <div style={rowStyle}>
                                    <div style={labelStyle}>Camera</div>
                                    <Toggle
                                        size="md"
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        checked={hqObj.preprint2.camera}
                                        onChange={checked => setHQObj({
                                            ...hqObj,
                                            preprint2: {...hqObj.preprint2, camera: checked}
                                        })}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>

                {/*Preprint 3*/}
                {/*<div style={cardStyle}>*/}
                {/*    <div style={rowStyle}>*/}
                {/*        <div style={labelStyle}>Pre Print 3 Exist</div>*/}
                {/*        <Toggle*/}
                {/*            size="md"*/}
                {/*            checkedChildren="Yes"*/}
                {/*            unCheckedChildren="No"*/}
                {/*            checked={hqObj.preprint3.exist}*/}
                {/*            onChange={checked => setHQObj({...hqObj, preprint3: {...hqObj.preprint3, exist: checked}})}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <hr/>*/}
                {/*    <div style={rowStyle}>*/}
                {/*        <div style={labelStyle}>No of Top Heads</div>*/}
                {/*        <InputNumber*/}
                {/*            id='preprint3.topheads'*/}
                {/*            value={hqObj.preprint3.topheads}*/}
                {/*            onChange={(value) => setHQObj({...hqObj, preprint3: {...hqObj.preprint3, topheads: Number(value)}})}*/}
                {/*            min={0}*/}
                {/*            max={5}*/}
                {/*            style={{width: 80}}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div style={rowStyle}>*/}
                {/*        <div style={labelStyle}>No of Bottom Heads</div>*/}
                {/*        <InputNumber*/}
                {/*            id='preprint3.bottomheads'*/}
                {/*            value={hqObj.preprint3.bottomheads}*/}
                {/*            onChange={(value) => setHQObj({...hqObj, preprint3: {...hqObj.preprint3, bottomheads: Number(value)}})}*/}
                {/*            min={0}*/}
                {/*            max={5}*/}
                {/*            style={{width: 80}}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div style={rowStyle}>*/}
                {/*        <div style={labelStyle}>Color</div>*/}
                {/*        <Toggle*/}
                {/*            size="md"*/}
                {/*            checkedChildren="Yes"*/}
                {/*            unCheckedChildren="No"*/}
                {/*            checked={hqObj.preprint3.color}*/}
                {/*            onChange={checked => setHQObj({...hqObj, preprint3: {...hqObj.preprint3, color: checked}})}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div style={rowStyle}>*/}
                {/*        <div style={labelStyle}>Color</div>*/}
                {/*        <Toggle*/}
                {/*            size="md"*/}
                {/*            checkedChildren="Yes"*/}
                {/*            unCheckedChildren="No"*/}
                {/*            checked={hqObj.preprint3.camera}*/}
                {/*            onChange={checked => setHQObj({...hqObj, preprint3: {...hqObj.preprint3, camera: checked}})}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, hqObj) && isEqual(tempStateOptions, hqOptions)}
                            onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

HQEditOptionsDlg.propTypes = {
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default HQEditOptionsDlg;
