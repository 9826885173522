import React, {useEffect, useState} from "react";
import {Button, ButtonToolbar, Input, InputNumber, InputPicker, MaskedInput, Modal, Tooltip, Whisper,} from "rsuite";
import {dataManufacurer, dataPlCType, dataVolt, dataWidth, maskOptions} from "../../../../Resources/InputFormData";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {SelectorMachineGeneral} from "../../Selector/SelectorMachineGeneral";
import {cloneDeep, isEqual} from "lodash";
import {SelectMachineNameArray} from "../../Selector/SelectMachineNameArray";
import {getActiveMachine, getCorrId, TOGGLE_DLG, UPDATE_MACHINE_GENERAL} from "../../../../Reducers/CorrugatorSlice";
import {useSelector} from "react-redux";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";

const GeneralEditDlg = ({open, dispatch}) => {
    const machineFilter = useSelector(getActiveMachine);
    const general = SelectorMachineGeneral(machineFilter);
    const corrId = useSelector(getCorrId);
    let machineNameArray = SelectMachineNameArray(machineFilter);
    const tempState = cloneDeep(general);
    const [genObj, setGenObj] = useState(general);

    useEffect(() => {
        setGenObj(general);
        machineNameArray = SelectMachineNameArray(machineFilter);
    }, [machineFilter, general])

    const handleSaveDlg = async () => {
        const tmp = [machineFilter, '.', 'general'].join('');
        console.log('GENERAL-EDIT-DLG --> tmp', tmp);
        await CorrugatorDataServices.updateCorrugator(corrId, {[tmp]: genObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_MACHINE_GENERAL({machineIdStr: machineFilter, obj: genObj}));
            dispatch(TOGGLE_DLG(false));
        }).catch(error => {
            throw new Error(`GENERAL ${machineFilter} OPTIONS WRITE FUCKED UP -->`, error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    const handleInputChanged = (value, e) => {
        // id is here the obj property name!!!!!
        setGenObj({...genObj, [e.target.id]: value});
    };

    return (
        <Modal
            className={'rs-theme-dark'}
            open={open}
            size={'lg'}
            style={{marginTop: '2rem'}}
            overflow={true}
            backdrop={'static'}
            autoFocus={true}
            enforceFocus={true}
            onClose={handleCloseDlg}
        >
            <Modal.Header>
                <Modal.Title>{machineFilter.toUpperCase()} - General</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', justifyContent: 'start'}}>
                    <div style={cardStyle}>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Manufacturer:</div>
                            <InputPicker
                                id={'manufacturer'}
                                placeholder='Select Manufacturer'
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={genObj.manufacturer}
                                onSelect={(value) => setGenObj({...genObj, manufacturer: value})}
                                data={dataManufacurer}
                                style={{width: '8.75rem'}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Machine name:</div>
                            <InputPicker
                                id={'machinename'}
                                placeholder='Select Name'
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={genObj.machinename}
                                onSelect={(value) => setGenObj({...genObj, machinename: value})}
                                data={machineNameArray}
                                style={{width: '8.75rem'}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Machine type:</div>
                            <Whisper followCursor speaker={<Tooltip>Only used for new Machine Generation</Tooltip>}>
                                <Input
                                    id='machinetype'
                                    placeholder='Machine type'
                                    value={genObj.machinetype}
                                    onChange={handleInputChanged}
                                    style={{width: '8.75rem'}}
                                />
                            </Whisper>
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Machine No:</div>
                            <Whisper followCursor speaker={<Tooltip>Machine or Serial Number</Tooltip>}>
                                <Input
                                    id='machinenumber'
                                    placeholder='Machine number'
                                    value={genObj.machinenumber}
                                    onChange={handleInputChanged}
                                    style={{width: '8.75rem'}}
                                />
                            </Whisper>
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Work No:</div>
                            <Whisper followCursor
                                     speaker={<Tooltip>Only used for old Machine Generation</Tooltip>}>
                                <Input
                                    id='worknumber'
                                    placeholder='Work number'
                                    value={genObj.worknumber}
                                    onChange={handleInputChanged}
                                    style={{width: '8.75rem'}}
                                />
                            </Whisper>
                        </div>
                    </div>
                    <div style={cardStyle}>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Year of constr.:</div>
                            <Whisper followCursor
                                     speaker={<Tooltip>Year 1900 is eqal to not avaivable</Tooltip>}>
                                <InputNumber
                                    id='built'
                                    value={genObj.built}
                                    onChange={(value) => setGenObj({...genObj, built: Number(value)})}
                                    min={1900}
                                    max={2050}
                                    style={{width: '7.5rem'}}
                                />
                            </Whisper>
                        </div>
                        <hr style={hrStyle}/>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Volt:</div>
                            <InputPicker
                                id='volt'
                                placeholder='Select Voltage'
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={genObj.volt}
                                onSelect={(value) => setGenObj({...genObj, volt: value})}
                                data={dataVolt}
                                style={{width: '7.5rem'}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Current:</div>
                            <InputNumber
                                id='current'
                                placeholder='Electric current'
                                postfix="Amp"
                                value={genObj.current}
                                onChange={(value) => setGenObj({...genObj, current: Number(value)})}
                                style={{width: '7.5rem'}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>KVA:</div>
                            <Whisper followCursor speaker={<Tooltip>Apparent Power</Tooltip>}>
                                <InputNumber
                                    id='kva'
                                    placeholder='Apparent Power'
                                    postfix='KVA'
                                    value={genObj.kva}
                                    onChange={(value) => setGenObj({...genObj, kva: Number(value)})}
                                    style={{width: '7.5rem'}}
                                />
                            </Whisper>
                        </div>
                    </div>
                    <div style={cardStyle}>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Plc type:</div>
                            <InputPicker
                                id='plctype'
                                placeholder='Select PLC type'
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={genObj.plctype}
                                onSelect={(value) => setGenObj({...genObj, plctype: value})}
                                data={dataPlCType}
                                style={{width: '8.75rem'}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>IP Address:</div>
                            <Whisper trigger='hover' placement='top' speaker={<Tooltip>Use leading 0 like 172.030.063.023</Tooltip>}>
                                <MaskedInput
                                    id='ipaddressplc'
                                    value={genObj.ipaddressplc}
                                    mask={maskOptions[5].mask}
                                    guide={true}
                                    showMask={true}
                                    keepCharPositions={true}
                                    placeholder={maskOptions[5].placeholder}
                                    style={{width: '8.75rem'}}
                                    // placeholderChar={'_'}
                                    onChange={(value) => setGenObj({...genObj, ipaddressplc: value})}
                                    pipe={value => {
                                        const subips = value.split('.')
                                        const invalidSubips = subips.filter(ip => {
                                            ip = parseInt(ip)
                                            return ip < 0 || ip > 255
                                        })
                                        return invalidSubips.length > 0 ? false : value
                                    }}
                                />
                            </Whisper>
                        </div>
                        <hr style={hrStyle}/>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Max-Speed:</div>
                            <InputNumber
                                id='speed'
                                placeholder='Speed'
                                value={genObj.speed}
                                onChange={(value) => setGenObj({...genObj, speed: Number(value)})}
                                max={500}
                                postfix={'m/min'}
                                style={{width: 140}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Max Paper Width:</div>
                            <InputPicker
                                id='width'
                                placeholder='Select Width'
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={genObj.width}
                                onSelect={(value) => setGenObj({...genObj, width: value})}
                                data={dataWidth}
                                style={{width: 140}}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Whisper trigger='hover' placement='top' speaker={<Tooltip>I hope you <b>know</b> what you are doing!</Tooltip>}>
                        <Button disabled={isEqual(tempState, genObj)} onClick={handleSaveDlg}>Save</Button>
                    </Whisper>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default GeneralEditDlg;
