import React from "react";
import {Modal} from "rsuite";
import {CorrHotelForm} from "./CorrHotelForm";
import {TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const CorrEditHotel = ({recordForEdit, addOrEdit, open, dispatch}) => {
    console.log('EditCorr Hotel DLG', recordForEdit);

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>
                    {(recordForEdit === null) ? 'New Hotel' : 'Edit Hotel'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CorrHotelForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
            </Modal.Body>
        </Modal>
    );
};

export default CorrEditHotel;
