import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import TimesheetDataServices from "../../Services/TimesheetDataService";
import {TimesheetStruct} from "../../InitialDataStructures/Timesheets/Timesheet";
import {v4} from "uuid";
import {cloneDeep} from "lodash";
import {str_failed, str_idle, str_loading, str_succeeded} from "../../Helpers/GeneralHelpers";


export const ASYNC_FETCH_TIMESHEET_LIST = createAsyncThunk('timeSheet/ASYNC_FETCH_TIMESHEET_LIST', async ({user, facility, machine, starttime, endtime, remote}) => {
    try {
        // console.log(`ASYNC_FETCH_TIMESHEET_LIST -> User: ${user} - Facility: ${facility} - Machine: ${machine} - StartTime: ${starttime} - EndTime: ${endtime}`);
        const docColl = await TimesheetDataServices.getFilteredTimeSheets({user, facility, machine, starttime, endtime, remote});
        const docs = docColl.docs;
        const allTimeSheetsDic = [];
        docs.forEach((doc) => {
            // console.log(`ASYNC_FETCH_TIMESHEET_LIST: ${doc.id} => ${doc.data()}`);
            allTimeSheetsDic.push({id: doc.id, docData: doc.data()});
        });
        return allTimeSheetsDic;
    } catch (error) {
        //throw new Error('ASYNC_FETCH_TIMESHEET_LIST --> :', error.msg);
        return error.message;
    }
});

export const ASYNC_ADD_TIMESHEET_OBJ = createAsyncThunk('timeSheet/ASYNC_ADD_TIMESHEET_OBJ', async (timeSheetObject) => {
    // console.log("SLICE ASYNC_ADD_TIMESHEET ID", timeSheetObject.id);
    // console.log("SLICE ASYNC_ADD_TIMESHEET docData", timeSheetObject.docData);
    try {
        return await TimesheetDataServices.addTimesheetDocument(timeSheetObject);
    } catch (error) {
        return error.message;
    }
});

export const ASYNC_UPDATE_TIMESHEET_OBJ = createAsyncThunk('timeSheet/ASYNC_UPDATE_TIMESHEET_OBJ', async (timeSheetObject) => {
    // console.log("SLICE ASYNC_UPDATE_TIMESHEET ID", timeSheetObject.id);
    // console.log("SLICE ASYNC_UPDATE_TIMESHEET docData", timeSheetObject.docData);
    try {
        return await TimesheetDataServices.updateTimesheetDocument(timeSheetObject);
    } catch (error) {
        return error.message;
    }
});

export const ASYNC_DELETE_TIMESHEET_OBJ = createAsyncThunk('timeSheet/ASYNC_DELETE_TIMESHEET_OBJ', async (timesheet_id) => {
    // console.log("SLICE ASYNC_DELETE_TIMESHEET ID", timesheet_id);
    try {
        await TimesheetDataServices.deleteTimesheetDocument(timesheet_id);
    } catch (error) {
        return error.message;
    }
});

const initialState = {
    openTimesheetDlg: false,
    modeTimeSheetDlg: '', // NEW or EDIT
    selectedUser: '',
    statusFetchList: str_idle,
    errorFetchList: null,
    statusAddTimeSheetObj: str_idle,
    errorAddTimeSheetObj: null,
    statusUpdateTimeSheetObj: str_idle,
    errorUpdateTimeSheetObj: null,
    statusDeleteTimeSheetObj: str_idle,
    errorDeleteTimeSheetObj: null,
    timeSheets: [],
    actualTimeSheetObj: {id: v4(), docData: cloneDeep(TimesheetStruct)},
    // TODO: needs to be testet
    // I cant use Object.create here because it is not serializable -> Prototype is the problem
    //actualTimeSheetObj: {id: v4(), docData: Object.create(TimesheetStruct)},
};

const timesheetSlice = createSlice({
    name: 'timeSheet',
    initialState,
    reducers: {
        TIMESHEET_TOGGLE_DLG: (state, action) => {
            // spread the time sheet object into his properties
            const {open, timeSheet, mode} = action.payload;
            // console.log('TIMESHEET-SLICE -> TimeSheetObj', timeSheet);
            // console.log('TIMESHEET-SLICE -> payload', action.payload);
            state.actualTimeSheetObj = timeSheet;
            state.modeTimeSheetDlg = mode;
            state.openTimesheetDlg = open;
            // console.log('TIMESHEET-SLICE -> actualTimeSheetObj', state.actualTimeSheetObj);
        },
        SET_TIMESHEET_FETCH_LIST_STATUS: (state, {payload}) => {
            state.statusFetchList = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(ASYNC_FETCH_TIMESHEET_LIST.pending, (state) => {
                    state.statusFetchList = str_loading;
                }
            )
            .addCase(ASYNC_FETCH_TIMESHEET_LIST.fulfilled, (state, action) => {
                    state.statusFetchList = str_succeeded;
                    // console.log('ASYNC_FETCH_TIMESHEET_LIST -> Return', action.payload);
                    if (action.payload !== undefined) {
                        state.timeSheets = action.payload;
                    }
                }
            )
            .addCase(ASYNC_FETCH_TIMESHEET_LIST.rejected, (state, action) => {
                    state.statusFetchList = str_failed;
                    state.errorFetchList = action.error.message;
                }
            )
            .addCase(ASYNC_ADD_TIMESHEET_OBJ.pending, (state) => {
                    state.statusAddTimeSheetObj = 'loading';
                }
            )
            .addCase(ASYNC_ADD_TIMESHEET_OBJ.fulfilled, (state, action) => {
                    state.statusAddTimeSheetObj = str_succeeded;
                    // action.meta.arg returns the timeSheetObj
                    const timeSheetObj = action.meta.arg;
                    console.log('ASYNC_ADD_TIMESHEET_OBJ FULFILLED ->', timeSheetObj);
                    state.timeSheets.push(timeSheetObj);
                }
            )
            .addCase(ASYNC_ADD_TIMESHEET_OBJ.rejected, (state, action) => {
                    state.statusAddTimeSheetObj = str_failed;
                    // Not sure it works like this. -> should be action.message
                    state.errorDeleteTimeSheetObj = action.error.message;
                }
            )
            .addCase(ASYNC_UPDATE_TIMESHEET_OBJ.pending, (state) => {
                    state.statusUpdateTimeSheetObj = str_loading;
                }
            )
            .addCase(ASYNC_UPDATE_TIMESHEET_OBJ.fulfilled, (state, action) => {
                    state.statusUpdateTimeSheetObj = str_succeeded;
                    // action.meta.arg returns the timeSheetObj
                    const timeSheetObj = action.meta.arg;
                    console.log('ASYNC_UPDATE_TIMESHEET_OBJ FULFILLED ->', timeSheetObj);
                    const objIndex = state.timeSheets.findIndex(sheet => sheet.id === timeSheetObj.id);
                    if (objIndex > -1) {
                        state.timeSheets.splice(objIndex, 1, timeSheetObj);
                    }
                    state.statusUpdateTimeSheetObj = str_idle;
                }
            )
            .addCase(ASYNC_UPDATE_TIMESHEET_OBJ.rejected, (state, action) => {
                    state.statusUpdateTimeSheetObj = str_failed;
                    console.log('ASYNC_UPDATE_TIMESHETT_OBJ REJECT', action);
                    // Not sure it works like this. -> should be action.message
                    state.errorUpdateTimeSheetObj = action.error.message;
                }
            )
            .addCase(ASYNC_DELETE_TIMESHEET_OBJ.pending, (state) => {
                    state.statusDeleteTimeSheetObj = 'loading';
                }
            )
            .addCase(ASYNC_DELETE_TIMESHEET_OBJ.fulfilled, (state, action) => {
                    state.statusDeleteTimeSheetObj = str_succeeded;
                    // action.meta.arg returns the timeSheet_id
                    const timeSheet_id = action.meta.arg;
                    console.log('ASYNC_DELETE_TIMESHEET id', timeSheet_id);
                    const objIndex = state.timeSheets.findIndex(sheet => sheet.id === timeSheet_id);
                    if (objIndex > -1) {
                        state.timeSheets.splice(objIndex, 1);
                    }
                }
            )
            .addCase(ASYNC_DELETE_TIMESHEET_OBJ.rejected, (state, action) => {
                    console.log('ASYNC_DELETE_TIMESHEET ERROR', action);
                    state.statusDeleteTimeSheetObj = str_failed;
                    // Not sure it works like this. -> should be action.message
                    state.errorDeleteTimeSheetObj = action.error.message;
                }
            )
    }
});

export const {
    TIMESHEET_TOGGLE_DLG,
    SET_TIMESHEET_FETCH_LIST_STATUS,
} = timesheetSlice.actions;

export default timesheetSlice.reducer;


export const getTimesheetFetchListStatus = (state) => state.timesheetReducer.statusFetchList;
export const getTimesheetFetchListError = (state) => state.timesheetReducer.errorFetchList;
export const getTimesheetAddStatus = (state) => state.timesheetReducer.statusAddTimeSheetObj;
export const getTimesheetAddError = (state) => state.timesheetReducer.errorAddTimeSheetObj;
export const getTimesheetUpdateStatus = (state) => state.timesheetReducer.statusUpdateTimeSheetObj;
export const getTimesheetUpdateError = (state) => state.timesheetReducer.errorUpdateTimeSheetObj;
export const getTimesheetDeleteStatus = (state) => state.timesheetReducer.statusDeleteTimeSheetObj;
export const getTimesheetDeleteError = (state) => state.timesheetReducer.errorDeleteTimeSheetObj;
export const getTimesheetArray = (state) => state.timesheetReducer.timeSheets;
export const getActualTimeSheet = (state) => state.timesheetReducer.actualTimeSheetObj;
export const getModeTimeSheetDlg = (state) => state.timesheetReducer.modeTimeSheetDlg;
export const getTimeSheetById = (state, timeSheet_id) => state.timesheetReducer.timeSheets.find(timeSheet => timeSheet.id === timeSheet_id);
// using it like this
// useSelector((state) => getTimeSheetById(state, timeSheet_id));




