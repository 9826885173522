import {getCorrObj} from "../../../Reducers/CorrugatorSlice";
import {useSelector} from "react-redux";

export const SelectorFacer = (facerId) => {
    const corrObj = useSelector(getCorrObj);
    console.log('Selector Facer', facerId, corrObj.facer1);

    switch (facerId) {
        case 1:
            return corrObj.facer1;
        case 2:
            return corrObj.facer2;
        case 3:
            return corrObj.facer2;
        default:
            throw new Error('Unhandled SelectorFacer type');
    }
};
