import React from "react";
import {Button} from "rsuite";
import {useSelector} from "react-redux";
import {getCorrId} from "../Reducers/CorrugatorSlice";
import CorrugatorDataServices from "../Services/CorrugatorDataServices";
import {general} from "../InitialDataStructures/Machine/General";

const TestPage = () => {
    const corrId = useSelector(getCorrId);

    async function handleOnClick() {
        await CorrugatorDataServices.updateCorrugator(corrId, {'ccd.general': general}).catch((error) => {
            throw new Error('Failed to update Corrugator', error);
        });
    }

    return (
        <>
            <h3> Test </h3>
            <Button onClick={handleOnClick}>Write</Button>
        </>
    );
};

export default TestPage;
