import {general} from "../General";
import {SROptions} from "./SROptions";
import {SRSection} from "./SRSection";

export const sr = {
    general: general,
    options: SROptions,
    section1: SRSection,
    section2: SRSection,
}
