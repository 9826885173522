import React from "react";
import {fieldStyle, labelStyle, rowCardStyle} from "../Styles/ComponentStyles";

export const ContentRow = ({label, value}) => {

    return(
        <div style={rowCardStyle}>
            <div style={labelStyle}>{label}</div>
            <div style={fieldStyle}>{value}</div>
        </div>
    );
};
