import {createSlice} from "@reduxjs/toolkit";
import {FileUploadStruct} from "../InitialDataStructures/Corrugator/FileUploadStruct";

const initialState = {
    openEditDlg: false,
    myUploadObj: FileUploadStruct,
    targetPath: '',
    disableUpload: true,
};

const uploadSlice = createSlice({
    name: 'uploadDoc',
    initialState,
    reducers: {
        TOGGLE_EDIT_DLG: (state, action) => {
            state.openEditDlg = action.payload;
        },
        FILL_UPLOAD_OBJ: (state, {payload}) => {
            state.myUploadObj = payload;
        },
        RESET_UPLOAD_OBJ: (state) => {
            state.myUploadObj = FileUploadStruct;
        },
        SET_TARGET_PATH: (state, {payload}) => {
            state.targetPath = payload;
        },
        SET_UPLOAD_OBJ_VERSION: (state, {payload}) => {
            state.myUploadObj.version = payload;
        },
        SET_UPLOAD_OBJ_VERSION1: (state, {payload}) => {
            state.myUploadObj.version1 = payload;
        },
        SET_UPLOAD_OBJ_DESCRIPTION: (state, {payload}) => {
            state.myUploadObj.description = payload;
        },
        SET_UPLOAD_OBJ_CHANGEREASON: (state, action) => {
            state.myUploadObj.changereason = action.payload;
        },
        SET_UPLOAD_OBJ_STORAGEPATH: (state, action) => {
            state.myUploadObj.storagepath = action.payload;
        },
    }
});

export const {
    TOGGLE_EDIT_DLG,
    FILL_UPLOAD_OBJ,
    RESET_UPLOAD_OBJ,
    SET_TARGET_PATH,
    SET_UPLOAD_OBJ_VERSION,
    SET_UPLOAD_OBJ_VERSION1,
    SET_UPLOAD_OBJ_DESCRIPTION,
    SET_UPLOAD_OBJ_CHANGEREASON,
    SET_UPLOAD_OBJ_STORAGEPATH,
} = uploadSlice.actions;

export default uploadSlice.reducer;

export const getTargetPath = (state) => state.uploadReducer.targetPath;
export const getUploadObj = (state) => state.uploadReducer.myUploadObj;