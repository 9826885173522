import {Outlet} from "react-router-dom";
import CustomNavBar from "../Components/CustomNavBar";
import {Container} from "rsuite";

const stickyStyle = {
    position: 'sticky',
    top: 10,
    width: '100%',
    height: '50px',
    zIndex: 2147483647
};

const SharedLayout = () => {
    return (
        <Container>
            {/*<CustomNavBar style={{position: 'fixed'}}/>*/}
            <CustomNavBar style={stickyStyle}/>
            {/*<CustomNavBar/>*/}
            <Outlet style={{top: 0, left: 0, height: '100vh', width: '100vw'}}/> {/* Everything above is shared across all pages*/}
        </Container>
    );
};

export default SharedLayout;
