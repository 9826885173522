import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CorrugatorDataServices from "../Services/CorrugatorDataServices";
import {MachineName, MachineTab} from "../Resources/StringConstants";
import {deleteObject, ref} from "firebase/storage";
import {prattStorage} from "../firebase_config";
import {str_idle} from "../Helpers/GeneralHelpers";

export const FETCH_CORR_OBJ = createAsyncThunk('corrObj/FETCH_CORR_OBJ', async (corrId) => {
    try {
        const docObj = await CorrugatorDataServices.getCorrugator(corrId);
        if (docObj.exists()) {
            return docObj.data();
        }
    } catch (error) {
        console.error('FETCH-CORR-OBJ --> error', error.msg);
    }
});

export const FETCH_DOC_COLL_OBJ = createAsyncThunk('corrObj/FETCH_DOC_COLL_OBJ', async (corrId) => {
    try {
        const docColl = await CorrugatorDataServices.getAllDocsForCorrId(corrId);
        const docs = docColl.docs;
        const allDocs = [];
        docs.forEach((doc) => {
            allDocs.push(doc.data());
        });
        return allDocs;
    } catch (error) {
        console.error('FETCH-DOCCOLL-OBJ --> error', error.msg);
    }
});

export const WRITE_NEW_DOC_OBJ = createAsyncThunk('corrObj/WRITE_NEW_DOC_OBJ', async (docObj) => {
    await CorrugatorDataServices.createFileDocument(docObj);
    return docObj;
});

export const WRITE_UPDATE_DOC_OBJ = createAsyncThunk('corrObj/WRITE_UPDATE_DOC_OBJ', async (docObj) => {
    await CorrugatorDataServices.createFileDocument(docObj);
    return docObj;
});

export const DELETE_DOC_OBJ = createAsyncThunk('corrObj/DELETE_DOC_OBJ', async (docObj) => {
    // console.log('DELETE_DOC_OBJ --> docObj', docObj);
    // delete collection document
    await CorrugatorDataServices.deleteFileDocument(docObj.id);
    // delete file in firebase storage
    const path = [docObj.refcollection, '/', docObj.refmachine, '/', docObj.refcategory, '/', docObj.filename].join('');
    await deleteObject(ref(prattStorage, path));
    return docObj;
});


export const FilterDocArray = (corrDocArray, machineFilter, categoryFilter) => {
    let tmpArray;
    tmpArray = corrDocArray.filter(function (fileItem) {
        return (fileItem.refmachine === machineFilter && fileItem.refcategory === categoryFilter);
    }).sort((a, b) => {
        return (a.timestamp.second > b.timestamp.second) ? -1 : 1
    });
    return tmpArray;
};


const initialState = {
    corrId: '',
    user: '',
    corrObj: {},
    corrDocArray: [],
    drawingDocArray: [],
    plcDocArray: [],
    driveDocArray: [],
    variousDocArray: [],
    isLoading: true,
    hasError: false,
    openAboutDlg: false,
    openDlg: false,
    openCorrBaseDlg: false,
    openCorrGeneralDlg: false,
    openCorrAddressDlg: false,
    openCorrIPDlg: false,
    openCorrVisuDlg: false,
    message: '',
    expandSidebar: true,
    activeMachine: '',
    activeCategory: '',
    newCorrugator: false,
    status: str_idle,
};

const fillDocArrays = (state) => {
    state.drawingDocArray = FilterDocArray(state.corrDocArray, state.activeMachine, MachineTab.drawings);
    state.plcDocArray = FilterDocArray(state.corrDocArray, state.activeMachine, MachineTab.plc);
    state.driveDocArray = FilterDocArray(state.corrDocArray, state.activeMachine, MachineTab.drives);
    state.variousDocArray = FilterDocArray(state.corrDocArray, state.activeMachine, MachineTab.various);
};

const corrObjSlice = createSlice({
    name: 'corrObj',
    initialState,
    reducers: {
        RESET_ERROR: (state) => {
            state.hasError = false;
            state.message = '';
        },
        SET_CORR_ID: (state, {payload}) => {
            state.corrId = payload;
        },
        SET_LOADING: (state, action) => {
            state.isLoading = action.payload;
        },
        TOGGLE_DLG: (state, action) => {
            state.openDlg = action.payload;
        },
        TOGGLE_ABOUT_DLG: (state, action) => {
            state.openAboutDlg = action.payload;
        },
        TOGGLE_CORR_BASE_DLG: (state, action) => {
            state.openCorrBaseDlg = action.payload;
        },
        TOGGLE_CORR_GENERAL_DLG: (state, action) => {
            state.openCorrGeneralDlg = action.payload;
        },
        TOGGLE_CORR_ADDRESS_DLG: (state, action) => {
            state.openCorrAddressDlg = action.payload;
        },
        TOGGLE_CORR_IP_DLG: (state, action) => {
            state.openCorrIPDlg = action.payload;
        },
        TOGGLE_CORR_VISU_DLG: (state, action) => {
            state.openCorrVisuDlg = action.payload;
        },
        SET_NEW_CORRUGATOR: (state, {payload}) => {
            state.newCorrugator = payload;
        },
        TOGGLE_SIDEBAR: (state, action) => {
            state.expandSidebar = action.payload
        },
        UPDATE_CORR_OBJ: (state, action) => {
            state.corrObj = action.payload;
        },
        UPDATE_CORR_BASE: (state, action) => {
            state.corrObj.corrbase = action.payload;
        },
        UPDATE_CORR_ADDRESS: (state, action) => {
            state.corrObj.address = action.payload;
        },
        UPDATE_DRY_END: (state, action) => {
            state.corrObj.dryend = action.payload;
        },
        UPDATE_WET_END: (state, action) => {
            state.corrObj.wetend = action.payload;
        },
        UPDATE_AOC_OPTIONS: (state, action) => {
            state.corrObj.aoc.options = action.payload;
        },
        UPDATE_ASM_OPTIONS: (state, action) => {
            state.corrObj.asm.options = action.payload;
        },
        UPDATE_BDC_OPTIONS: (state, action) => {
            state.corrObj.bdc.options = action.payload;
        },
        UPDATE_BLC_OPTIONS: (state, action) => {
            state.corrObj.blc.options = action.payload;
        },
        UPDATE_DF_OPTIONS: (state, action) => {
            state.corrObj.df.options = action.payload;
        },
        UPDATE_GU_OPTIONS: (state, action) => {
            state.corrObj.gu.options = action.payload;
        },
        UPDATE_HQ: (state, action) => {
            state.corrObj.hq = action.payload;
        },
        UPDATE_KQ_OPTIONS: (state, action) => {
            state.corrObj.kq.options = action.payload;
        },
        UPDATE_KQF_OPTIONS: (state, action) => {
            state.corrObj.kqf.options = action.payload;
        },
        UPDATE_SR: (state, action) => {
            state.corrObj.sr = action.payload;
        },
        UPDATE_RS_OPTIONS: (state, action) => {
            const {id, obj} = action.payload;
            switch (id) {
                case 1:
                    state.corrObj.rs1.options = obj;
                    break;
                case 2:
                    state.corrObj.rs2.options = obj;
                    break;
                case 3:
                    state.corrObj.rs3.options = obj;
                    break;
                case 4:
                    state.corrObj.rs4.options = obj;
                    break;
                case 5:
                    state.corrObj.rs5.options = obj;
                    break;
                case 6:
                    state.corrObj.rs6.options = obj;
                    break;
                case 7:
                    state.corrObj.rs7.options = obj;
                    break;
                default:
                    throw new Error(`Unhandled sub action type RS`);
            }
        },
        UPDATE_SP_OPTIONS: (state, action) => {
            const {id, obj} = action.payload;
            switch (id) {
                case 1:
                    state.corrObj.sp1.options = obj;
                    break;
                case 2:
                    state.corrObj.sp2.options = obj;
                    break;
                case 3:
                    state.corrObj.sp3.options = obj;
                    break;
                case 4:
                    state.corrObj.sp4.options = obj;
                    break;
                case 5:
                    state.corrObj.sp5.options = obj;
                    break;
                case 6:
                    state.corrObj.sp6.options = obj;
                    break;
                case 7:
                    state.corrObj.sp7.options = obj;
                    break;
                default:
                    throw new Error(`Unhandled sub action type SP`);
            }
        },
        UPDATE_FACER_OPTIONS: (state, action) => {
            const {id, obj} = action.payload;
            switch (id) {
                case 1:
                    state.corrObj.facer1.options = obj;
                    break;
                case 2:
                    state.corrObj.facer2.options = obj;
                    break;
                case 3:
                    state.corrObj.facer3.options = obj;
                    break;
                default:
                    throw new Error(`Unhandled action type Facer`);
            }
        },
        UPDATE_HB_OPTIONS: (state, action) => {
            const {id, obj} = action.payload;
            switch (id) {
                case 1:
                    state.corrObj.hb1.options = obj;
                    break;
                case 2:
                    state.corrObj.hb2.options = obj;
                    break;
                default:
                    throw new Error(`Unhandled action type HB`);
            }
        },
        UPDATE_WEBTROL_OPTIONS: (state, action) => {
            state.corrObj.webtrol.options = action.payload;
        },
        UPDATE_COM_OPTIONS: (state, action) => {
            state.corrObj.com.options = action.payload;
        },
        UPDATE_CCD_OPTIONS: (state, action) => {
            state.corrObj.ccd.options = action.payload;
        },
        UPDATE_VISU_LOCAL: (state, action) => {
            const {machineFilter, visuLocalObj} = action.payload;
            // console.log('UPDATEVISU-LOCAL --> machineIdStr', machineFilter);
            switch (machineFilter) {
                case MachineName.aoc:
                    state.corrObj.aoc.general.visu1 = visuLocalObj;
                    break;
                case MachineName.asm:
                    state.corrObj.asm.general.visu1 = visuLocalObj;
                    break;
                case MachineName.bdc:
                    state.corrObj.bdc.general.visu1 = visuLocalObj;
                    break;
                case MachineName.blc:
                    state.corrObj.blc.general.visu1 = visuLocalObj;
                    break;
                case MachineName.df:
                    state.corrObj.df.general.visu1 = visuLocalObj;
                    break;
                case MachineName.facer1:
                    state.corrObj.facer1.general.visu1 = visuLocalObj;
                    break;
                case MachineName.facer2:
                    state.corrObj.facer2.general.visu1 = visuLocalObj;
                    break;
                case MachineName.facer3:
                    state.corrObj.facer3.general.visu1 = visuLocalObj;
                    break;
                case MachineName.gu:
                    state.corrObj.gu.general.visu1 = visuLocalObj;
                    break;
                case MachineName.hb1:
                    state.corrObj.hb1.general.visu1 = visuLocalObj;
                    break;
                case MachineName.hb2:
                    state.corrObj.hb2.general.visu1 = visuLocalObj;
                    break;
                case MachineName.hq:
                    state.corrObj.hq.general.visu1 = visuLocalObj;
                    break;
                case MachineName.kq:
                    state.corrObj.kq.general.visu1 = visuLocalObj;
                    break;
                case MachineName.kqf:
                    state.corrObj.kqf.general.visu1 = visuLocalObj;
                    break;
                case MachineName.sr:
                    state.corrObj.sr.general.visu1 = visuLocalObj;
                    break;
                case MachineName.webtrol:
                    state.corrObj.webtrol.general.visu1 = visuLocalObj;
                    break;
                case MachineName.com:
                    state.corrObj.com.general.visu1 = visuLocalObj;
                    break;
                case MachineName.ccd:
                    state.corrObj.ccd.general.visu1 = visuLocalObj;
                    break;
                case MachineName.rs1:
                    state.corrObj.rs1.general.visu1 = visuLocalObj;
                    break;
                case MachineName.rs2:
                    state.corrObj.rs2.general.visu1 = visuLocalObj;
                    break;
                case MachineName.rs3:
                    state.corrObj.rs3.general.visu1 = visuLocalObj;
                    break;
                case MachineName.rs4:
                    state.corrObj.rs4.general.visu1 = visuLocalObj;
                    break;
                case MachineName.rs5:
                    state.corrObj.rs5.general.visu1 = visuLocalObj;
                    break;
                case MachineName.rs6:
                    state.corrObj.rs6.general.visu1 = visuLocalObj;
                    break;
                case MachineName.rs7:
                    state.corrObj.rs7.general.visu1 = visuLocalObj;
                    break;
                case MachineName.sp1:
                    state.corrObj.sp1.general.visu1 = visuLocalObj;
                    break;
                case MachineName.sp2:
                    state.corrObj.sp2.general.visu1 = visuLocalObj;
                    break;
                case MachineName.sp3:
                    state.corrObj.sp3.general.visu1 = visuLocalObj;
                    break;
                case MachineName.sp4:
                    state.corrObj.sp4.general.visu1 = visuLocalObj;
                    break;
                case MachineName.sp5:
                    state.corrObj.sp5.general.visu1 = visuLocalObj;
                    break;
                case MachineName.sp6:
                    state.corrObj.sp6.general.visu1 = visuLocalObj;
                    break;
                case MachineName.sp7:
                    state.corrObj.sp7.general.visu1 = visuLocalObj;
                    break;
                default:
                    throw new Error(`Unhandled CORRUGATOR-SLICE MACHINE-GENERAL-VISU1 ${action}`);
            }
        },
        UPDATE_VISU_REMOTE: (state, action) => {
            const {machineFilter, visuRemoteObj} = action.payload;
            switch (machineFilter.toLowerCase()) {
                case MachineName.aoc:
                    state.corrObj.aoc.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.asm:
                    state.corrObj.asm.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.bdc:
                    state.corrObj.bdc.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.blc:
                    state.corrObj.blc.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.df:
                    state.corrObj.df.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.facer1:
                    state.corrObj.facer1.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.facer2:
                    state.corrObj.facer2.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.facer3:
                    state.corrObj.facer3.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.gu:
                    state.corrObj.gu.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.hb1:
                    state.corrObj.hb1.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.hb2:
                    state.corrObj.hb2.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.hq:
                    state.corrObj.hq.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.kq:
                    state.corrObj.kq.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.kqf:
                    state.corrObj.kqf.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.sr:
                    state.corrObj.sr.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.webtrol:
                    state.corrObj.webtrol.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.com:
                    state.corrObj.com.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.ccd:
                    state.corrObj.ccd.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.rs1:
                    state.corrObj.rs1.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.rs2:
                    state.corrObj.rs2.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.rs3:
                    state.corrObj.rs3.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.rs4:
                    state.corrObj.rs4.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.rs5:
                    state.corrObj.rs5.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.rs6:
                    state.corrObj.rs6.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.rs7:
                    state.corrObj.rs7.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.sp1:
                    state.corrObj.sp1.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.sp2:
                    state.corrObj.sp2.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.sp3:
                    state.corrObj.sp3.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.sp4:
                    state.corrObj.sp4.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.sp5:
                    state.corrObj.sp5.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.sp6:
                    state.corrObj.sp6.general.visu2 = visuRemoteObj;
                    break;
                case MachineName.sp7:
                    state.corrObj.sp7.general.visu2 = visuRemoteObj;
                    break;
                default:
                    throw new Error(`Unhandled CORRUGATOR-SLICE MACHINE-GENERAL-VISU1 ${action}`);
            }
        },
        UPDATE_MACHINE_GENERAL: (state, {payload}) => {
            console.log('Update_General', payload)
            const {machineIdStr, obj} = payload;
            console.log('CORRUGATOR-SLICE --> machineIdStr', payload)
            switch (machineIdStr.toLowerCase()) {
                case MachineName.aoc:
                    state.corrObj.aoc.general = obj;
                    break;
                case MachineName.asm:
                    state.corrObj.asm.general = obj;
                    break;
                case MachineName.bdc:
                    state.corrObj.bdc.general = obj;
                    break;
                case MachineName.blc:
                    state.corrObj.blc.general = obj;
                    break;
                case MachineName.df:
                    state.corrObj.df.general = obj;
                    break;
                case MachineName.facer1:
                    state.corrObj.facer1.general = obj;
                    break;
                case MachineName.facer2:
                    state.corrObj.facer2.general = obj;
                    break;
                case MachineName.facer3:
                    state.corrObj.facer3.general = obj;
                    break;
                case MachineName.gu:
                    state.corrObj.gu.general = obj;
                    break;
                case MachineName.hb1:
                    state.corrObj.hb1.general = obj;
                    break;
                case MachineName.hb2:
                    state.corrObj.hb2.general = obj;
                    break;
                case MachineName.hq:
                    state.corrObj.hq.general = obj;
                    break;
                case MachineName.kq:
                    state.corrObj.kq.general = obj;
                    break;
                case MachineName.kqf:
                    state.corrObj.kqf.general = obj;
                    break;
                case MachineName.rs1:
                    console.log('Slice RS1 ->', obj);
                    state.corrObj.rs1.general = obj;
                    break;
                case MachineName.rs2:
                    state.corrObj.rs2.general = obj;
                    break;
                case MachineName.rs3:
                    state.corrObj.rs3.general = obj;
                    break;
                case MachineName.rs4:
                    state.corrObj.rs4.general = obj;
                    break;
                case MachineName.rs5:
                    state.corrObj.rs5.general = obj;
                    break;
                case MachineName.rs6:
                    state.corrObj.rs6.general = obj;
                    break;
                case MachineName.rs7:
                    state.corrObj.rs7.general = obj;
                    break;
                case MachineName.sp1:
                    state.corrObj.sp1.general = obj;
                    break;
                case MachineName.sp2:
                    state.corrObj.sp2.general = obj;
                    break;
                case MachineName.sp3:
                    state.corrObj.sp3.general = obj;
                    break;
                case MachineName.sp4:
                    state.corrObj.sp4.general = obj;
                    break;
                case MachineName.sp5:
                    state.corrObj.sp5.general = obj;
                    break;
                case MachineName.sp6:
                    state.corrObj.sp6.general = obj;
                    break;
                case MachineName.sp7:
                    state.corrObj.sp7.general = obj;
                    break;
                case MachineName.sr:
                    state.corrObj.sr.general = obj;
                    break;
                case MachineName.webtrol:
                    state.corrObj.webtrol.general = obj;
                    break;
                case MachineName.com:
                    state.corrObj.com.general = obj;
                    break;
                case MachineName.ccd:
                    state.corrObj.ccd.general = obj;
                    break;
                default:
                    throw new Error(`Unhandled CORRUGATOR-SLICE MACHINE-GENERAL ${payload}`);
            }
        },
        SET_ACTIVE_MACHINE: (state, action) => {
            state.activeMachine = action.payload;
            fillDocArrays(state);
        },
        SET_ACTIVE_CATEGORY: (state, {payload}) => {
            state.activeCategory = payload;
        },
        GET_DOC_LIST: (state, action) => {
            const {machineFilter, categoryFilter} = action.payload;
            const tmpArray = FilterDocArray(machineFilter, categoryFilter, state.corrDocArray);
            switch (categoryFilter) {
                case MachineTab.drawings:
                    state.drawingsDocArray = tmpArray;
                    break;
                case MachineTab.plc:
                    state.plcDocArray = tmpArray;
                    break;
                case MachineTab.drives:
                    state.drivesDocArray = tmpArray;
                    break;
                case MachineTab.various:
                    state.variousDocArray = tmpArray;
                    break;
                default:
                    throw new Error(`Unhandled CORRUGATOR-SLICE GET_DOC_LIST ${action}`);
            }
        },
        RESET_CORR_DOC: (state) => {
            state.corrDocArray = [];
        },
        DELETE_CORR_DOC: (state, {payload}) => {
            // console.log('CORRUGATOR-SLICE --> DeleteDocItem', payload);
            state.corrDocArray = state.corrDocArray.filter(doc => doc.id !== payload.id);
            fillDocArrays(state);
        },
        UPDATE_CORR_HOTEL: (state, {payload}) => {
            state.corrObj.hotels = state.corrObj.hotels.map(hotel => hotel.id !== payload.id ? hotel : payload);
        },
        DELETE_CORR_HOTEL: (state, {payload}) => {
            state.corrObj.hotels = state.corrObj.hotels.filter(hotel => hotel.id !== payload.id);
        },
        ADD_CORR_HOTEL: (state, {payload}) => {
            state.corrObj.hotels.push(payload);
        },
        UPDATE_CORR_CONTACT: (state, {payload}) => {
            state.corrObj.contacts = state.corrObj.contacts.map(contact => contact.id !== payload.id ? contact : payload);
        },
        DELETE_CORR_CONTACT: (state, {payload}) => {
            state.corrObj.contacts = state.corrObj.contacts.filter(contact => contact.id !== payload.id);
        },
        ADD_CORR_CONTACT: (state, {payload}) => {
            state.corrObj.contacts.push(payload);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(FETCH_CORR_OBJ.fulfilled, (state, action) => {
                state.corrObj = action.payload;
            })
            .addCase(FETCH_CORR_OBJ.rejected, (state, action) => {
                state.hasError = true;
                state.message = action.error.message;
            })
            .addCase(FETCH_DOC_COLL_OBJ.fulfilled, (state, action) => {
                state.corrDocArray = action.payload;
            })
            .addCase(FETCH_DOC_COLL_OBJ.rejected, (state, action) => {
                state.hasError = true;
                state.message = action.error.message;
            })
            .addCase(WRITE_NEW_DOC_OBJ.fulfilled, (state, action) => {
                state.corrDocArray.push(action.payload);
                fillDocArrays(state);
            })
            .addCase(WRITE_NEW_DOC_OBJ.rejected, (state, action) => {
                state.hasError = true;
                state.message = action.error.message;
            })
            .addCase(WRITE_UPDATE_DOC_OBJ.fulfilled, (state, {payload}) => {
                state.corrDocArray = state.corrDocArray.map(doc => doc.id !== payload.id ? doc : payload);
                fillDocArrays(state);
            })
            .addCase(WRITE_UPDATE_DOC_OBJ.rejected, (state, action) => {
                state.hasError = true;
                state.message = action.error.message;
            })
            .addCase(DELETE_DOC_OBJ.fulfilled, (state, {payload}) => {
                console.log('DELETE_DOC_OBJ fulfilled', payload);
                state.corrDocArray = state.corrDocArray.filter(doc => doc.id !== payload.id);
                fillDocArrays(state);
            })
            .addCase(DELETE_DOC_OBJ.rejected, (state, action) => {
                state.hasError = true;
                state.message = action.error.message;
            })

    }
});

export const {
    RESET_ERROR,
    SET_CORR_ID,
    SET_LOADING,
    TOGGLE_DLG,
    TOGGLE_ABOUT_DLG,
    TOGGLE_CORR_BASE_DLG,
    TOGGLE_CORR_GENERAL_DLG,
    TOGGLE_CORR_ADDRESS_DLG,
    TOGGLE_CORR_IP_DLG,
    TOGGLE_CORR_VISU_DLG,
    SET_NEW_CORRUGATOR,
    TOGGLE_SIDEBAR,
    UPDATE_CORR_OBJ,
    UPDATE_CORR_BASE,
    UPDATE_CORR_ADDRESS,
    UPDATE_DRY_END,
    UPDATE_WET_END,
    UPDATE_AOC_OPTIONS,
    UPDATE_ASM_OPTIONS,
    UPDATE_BDC_OPTIONS,
    UPDATE_BLC_OPTIONS,
    UPDATE_DF_OPTIONS,
    UPDATE_GU_OPTIONS,
    UPDATE_HQ,
    UPDATE_KQ_OPTIONS,
    UPDATE_KQF_OPTIONS,
    UPDATE_SR,
    UPDATE_COM_OPTIONS,
    UPDATE_CCD_OPTIONS,
    UPDATE_RS_OPTIONS,
    UPDATE_SP_OPTIONS,
    UPDATE_FACER_OPTIONS,
    UPDATE_HB_OPTIONS,
    UPDATE_WEBTROL_OPTIONS,
    UPDATE_VISU_LOCAL,
    UPDATE_VISU_REMOTE,
    UPDATE_MACHINE_GENERAL,
    SET_ACTIVE_MACHINE,
    SET_ACTIVE_CATEGORY,
    GET_DOC_LIST, RESET_CORR_DOC,
    DELETE_CORR_DOC,
    UPDATE_CORR_HOTEL, DELETE_CORR_HOTEL, ADD_CORR_HOTEL,
    UPDATE_CORR_CONTACT, DELETE_CORR_CONTACT, ADD_CORR_CONTACT,
} = corrObjSlice.actions;

export default corrObjSlice.reducer;

export const getCorrId = (state) => state.corrObjReducer.corrId;
export const getCorrObj = (state) => state.corrObjReducer.corrObj;
export const getCorrHotels = (state) => state.corrObjReducer.corrObj.hotels;
export const getCorrContacts = (state) => state.corrObjReducer.corrObj.contacts;

export const getCorrAOC = (state) => state.corrObjReducer.corrObj.aoc;
export const getCorrASM = (state) => state.corrObjReducer.corrObj.asm;
export const getCorrBDC = (state) => state.corrObjReducer.corrObj.bdc;
export const getCorrBLC = (state) => state.corrObjReducer.corrObj.blc;
export const getCorrDF = (state) => state.corrObjReducer.corrObj.df;
export const getCorrGU = (state) => state.corrObjReducer.corrObj.gu;
export const getCorrHQ = (state) => state.corrObjReducer.corrObj.hq;
export const getCorrKQ = (state) => state.corrObjReducer.corrObj.kq;
export const getCorrKQF = (state) => state.corrObjReducer.corrObj.kqf;
export const getCorrSR = (state) => state.corrObjReducer.corrObj.sr;
export const getCorrWebtrol = (state) => state.corrObjReducer.corrObj.webtrol;
export const getCorrCOM = (state) => state.corrObjReducer.corrObj.com;
export const getCorrCCD = (state) => state.corrObjReducer.corrObj.ccd;

export const getCorrDocArray = (state) => state.corrObjReducer.corrDocArray;
export const getDrawingDocs = (state) => state.corrObjReducer.drawingDocArray;
export const getDriveDocs = (state) => state.corrObjReducer.driveDocArray;
export const getPlcDocs = (state) => state.corrObjReducer.plcDocArray;
export const getVariousDocs = (state) => state.corrObjReducer.variousDocArray;
export const getActiveMachine = (state) => state.corrObjReducer.activeMachine;
export const getActiveCategory = (state) => state.corrObjReducer.activeCategory;
export const getIsLoading = (state) => state.corrObjReducer.isLoading;