export const WetEndStruct = {
    bbr: false,
    blc: false,
    df: false,
    facer: 0,
    gu: false,
    sss: false,
    webtrol: false,
    wec: false,
    zds: false
};
