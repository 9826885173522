import React, {useEffect, useRef, useState} from "react";
import {Button, ButtonToolbar, Form, InputNumber, Rate, SelectPicker, Tooltip, Whisper} from "rsuite";
import HotelValidation from "./HotelValidation";
import {dataStates} from "../../../../Resources/InputFormData";
import 'rsuite/dist/rsuite.min.css';
import {hrStyle} from "../../../../Styles/ComponentStyles";
import {geo} from "../../../../InitialDataStructures/Corrugator/Geo";

const initialFValues = {
    id: '',
    name: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    rating: 0,
    distance: 0,
    geo: geo
};

export const CorrHotelForm = ({recordForEdit, addOrEdit}) => {
    const [formValues, setFormValues] = useState(initialFValues);       // for field values
    const [formError, setFormError] = useState({});         // for field error messages
    const [latitude, setLatitude] = useState(0);         // for field error messages
    const [longitude, setLongitude] = useState(0);         // for field error messages
    const formRef = useRef();

    useEffect(() => {
        if (recordForEdit != null) {
            setFormValues(recordForEdit);
            setLatitude(recordForEdit.geo.latitude);
            setLongitude(recordForEdit.geo.longitude);
        }
    }, [recordForEdit])

    const handleReset = () => {
        if (recordForEdit != null) {
            setFormValues(recordForEdit);
            setLatitude(recordForEdit.geo.latitude);
            setLongitude(recordForEdit.geo.longitude);
        } else {
            setFormValues(initialFValues);
            setLongitude(0);
            setLatitude(0);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (!formRef.current.check()) {
            return;
        }
        addOrEdit(formValues, handleReset);
    };

    return (
        <Form
            model={HotelValidation}
            ref={formRef}
            onChange={formValues => setFormValues(formValues)}
            onCheck={setFormError}
            formError={formError}
            formValue={formValues}
            onSubmit={handleSubmit}
            layout='horizontal'
        >
            {/*<div style={cardStyle}>*/}
            {/*Hotel name*/}
            <Form.Group controlId="name-9">
                <Form.ControlLabel>Hotel Name</Form.ControlLabel>
                <Form.Control name="name"/>
                <Form.HelpText>Required</Form.HelpText>
            </Form.Group>

            <Form.Group controlId="city-9">
                <Form.ControlLabel>City</Form.ControlLabel>
                <Form.Control name="city"/>
                <Form.HelpText>Required</Form.HelpText>
            </Form.Group>

            <Form.Group controlId="street-9">
                <Form.ControlLabel>Street</Form.ControlLabel>
                <Form.Control name="street"/>
                <Form.HelpText>Required</Form.HelpText>
            </Form.Group>

            <Form.Group controlId="zipcode-9">
                <Form.ControlLabel>Zip Code</Form.ControlLabel>
                <Form.Control name="zipcode"/>
            </Form.Group>

            <Form.Group controlId="state-9">
                <Form.ControlLabel>State</Form.ControlLabel>
                <Form.Control name="state" style={{width: '18.8rem'}} data={dataStates} accepter={SelectPicker}/>
            </Form.Group>

            <Form.Group controlId="distance-10">
                <Form.ControlLabel>Distance</Form.ControlLabel>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip>Distance Hotel to the Company</Tooltip>}>
                    <Form.Control name="distance" postfix={'mil'} accepter={InputNumber}/>
                </Whisper>
            </Form.Group>

            <Form.Group controlId="distance-10">
                <Form.ControlLabel>Rating</Form.ControlLabel>
                <Form.Control name="rating" allowHalf accepter={Rate}/>
            </Form.Group>

            <Form.Group controlId="geo-10" style={{display: "flex"}}>
                <Form.ControlLabel>Geopoint</Form.ControlLabel>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip>Latitude</Tooltip>}>
                    <InputNumber value={latitude} onChange={setLatitude} min={-90} max={90} style={{width: '8,13rem'}}/>
                </Whisper>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip>Longitude</Tooltip>}>
                    <InputNumber value={longitude} onChange={setLongitude} min={-180} max={180} style={{width: '8,13rem', marginLeft: '1rem'}}/>
                </Whisper>
            </Form.Group>

            {/*</div>*/}
            <hr style={hrStyle}/>
            <ButtonToolbar>
                <Button onClick={handleSubmit}>Save</Button>
                <Button onClick={handleReset}>Reset</Button>
            </ButtonToolbar>
        </Form>
    );
};
