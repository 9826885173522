import {Loader, Placeholder} from "rsuite";

const BusySpinner = ({title, topic}) => {
    // console.log('RENDER BusySpinner')
    return (
        <div style={{background: 'black', height: 200}}>

            <Placeholder.Paragraph/>
            <Loader size='lg' backdrop center vertical content={<div> {title}.... {topic}</div>}/>

        </div>
    );
};
export default BusySpinner;
