export const contacts = [{
    id: '',
    firstname: '',
    name: '',
    jobtitle: '',
    email: '',
    businessphone: '',
    privatephone: ''
}]



