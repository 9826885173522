import React, {useState} from "react";
import PropTypes from "prop-types";
import {Table, Button} from "rsuite";
import UploadDlg from "./UploadDlg";
import {FileSplitString} from "../Resources/StringConstants";
import {useDispatch, useSelector} from "react-redux";
import {DELETE_DOC_OBJ, getIsLoading, TOGGLE_DLG} from "../Reducers/CorrugatorSlice";
import {cloneDeep} from "lodash";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {FILL_UPLOAD_OBJ, TOGGLE_EDIT_DLG} from "./UploadSlice";
import FileContentEditDlg from "./FileContentEditDlg";
import {ActionCell, FilenameCell, TimestampCell} from "./TableCell";
import {str_DELETE, str_DOWNLOAD, str_EDIT} from "../Helpers/GeneralHelpers";

const {Column, HeaderCell, Cell} = Table;

const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
//    alignItems: 'center'
};

const centerStyle1 = {
    alignItems: 'center'
};

const btnStyle = {
    marginBottom: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    width: '25rem'
};

const FileTable = ({fileList}) => {
    const dispatch = useDispatch();
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const openEditDlg = useSelector((state) => state.uploadReducer.openEditDlg);
    const loading = useSelector(getIsLoading);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    // console.log('RENDER -> FileTable', fileList);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    const onFileAction = async (fileActionObj) => {
        const {file, command} = fileActionObj;
        console.log('STORAGEPATH ->', file.storagepath);

        if (!fileActionObj || file.storagepath === undefined || file.storagepath === '') {
            console.error('FileAction not file object');
            return;
        }

        if (command === str_DOWNLOAD) {
            downloadFile(file);
        } else if (command === str_EDIT) {
            dispatch(FILL_UPLOAD_OBJ(file));
            dispatch(TOGGLE_EDIT_DLG(true));
        } else if (command === str_DELETE) {
            // console.log('FILE-NAME', file);
            let tmpFileName = file.filename.split(FileSplitString);
            // console.log('SPLIT STRING', tmpFileName);
            confirmAlert({
                title: 'Delete File',
                message: tmpFileName[0],
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteFile(file)
                    },
                    {
                        label: 'No',
                        onClick: () => console.log('Click No')
                    }
                ]
            });
        }
    };

    const downloadFile = (file) => {
        window.open(file.storagepath, "_blank")
    };

    const deleteFile = async (file) => {
        await dispatch(DELETE_DOC_OBJ(file)).catch((error) => {
            throw new Error('Failed to delete Upload Obj', error);
        });
    };

    const handleSortColumn = (sortColumn, sortType) => {
        //dispatch(SET_LOADING(true));
        setTimeout(() => {
            //dispatch(SET_LOADING(false));
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const getData = () => {
        if (sortColumn && sortType) {
            let sortList = cloneDeep(fileList);
            return sortList.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                // is colum a Timestamp -> convert to number
                if (typeof y === 'number' && typeof x === 'number') {
                    if (sortType === 'asc') {
                        return x - y;
                    } else {
                        return y - x;
                    }
                }
                // we assume it is a string -> i need a return value for anomynous function
                // if (typeof x === 'string' && typeof y === 'string')
                // {
                if (sortType === 'asc') {
                    return x < y ? -1 : x > y ? 1 : 0;
                } else {
                    return y < x ? -1 : y > x ? 1 : 0;
                }
                // }
            });
        }
        return fileList;
    };

    if (!fileList || fileList.length === 0) {
        return (
            <>
                <div style={{...centerStyle, ...centerStyle1}}>
                    <Button appearance="default" style={btnStyle} onClick={handleOpenDlg}>Upload</Button>
                    {/*<h3 style={{display: 'flex', justifyContent: 'center'}}>No files...</h3>*/}
                </div>
                <div style={centerStyle}>
                    <h3>No files...</h3>
                </div>
                <UploadDlg open={openDlg} dispatch={dispatch}/>
            </>
        );
    } else {
        return (
            <>
                <div>
                    <div style={centerStyle}>
                        <Button onClick={handleOpenDlg} appearance="primary">Upload</Button>
                    </div>

                    <Table
                        id="table"
                        wordWrap={true}
                        height={300}
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        loading={loading}
                        style={{fontSize: 11}}
                    >
                        {/*<Column width={80} align="center" sortable>*/}
                        {/*    <HeaderCell>Version</HeaderCell>*/}
                        {/*    <Cell dataKey='version'/>*/}
                        {/*</Column>*/}

                        <Column width={160} resizable sortable>
                            <HeaderCell>File name</HeaderCell>
                            <FilenameCell dataKey='filename' style={{color: 'white'}}/>
                        </Column>

                        <Column width={160} resizable sortable>
                            <HeaderCell>BHS-Version</HeaderCell>
                            <Cell dataKey='version1' style={{color: 'white'}}/>
                        </Column>

                        <Column flexGrow={200}>
                            <HeaderCell>Description</HeaderCell>
                            <Cell dataKey='description' style={{color: 'white'}}/>
                        </Column>

                        <Column width={260} resizable>
                            <HeaderCell>Reason for change</HeaderCell>
                            <Cell dataKey='changereason' style={{color: 'white'}}/>
                        </Column>

                        <Column width={140} sortable>
                            <HeaderCell>Timestamp</HeaderCell>
                            <TimestampCell dataKey="timestamp"/>
                        </Column>

                        <Column width={60}>
                            <HeaderCell>User</HeaderCell>
                            {/*<Cell>{rowData => <a href={`mailto:${rowData.email}`}>{rowData.email}</a>}</Cell>*/}
                            <Cell dataKey='user'/>
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Action</HeaderCell>
                            <ActionCell dataKey="storagepath" onFileAction={onFileAction}/>
                        </Column>
                    </Table>
                </div>
                <UploadDlg open={openDlg} dispatch={dispatch}/>
                <FileContentEditDlg open={openEditDlg}/>
            </>
        );
    }
};
export default FileTable

FileTable.propTypes = {
    fileList: PropTypes.array,
};
