import React, {useState} from "react";
import {Button, ButtonToolbar, InputNumber, InputPicker, Modal, Toggle} from "rsuite";
import {cardStyle, headerCardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {dataSRDrives, dataSRProfileOffset, dataSRProfiles} from "../../../../Resources/InputFormData";
import {cloneDeep, isEqual} from "lodash";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrId, getCorrSR, TOGGLE_DLG, UPDATE_SR} from "../../../../Reducers/CorrugatorSlice";

const SREditOptionsDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const sr = useSelector(getCorrSR);
    const tempState = cloneDeep(sr);
    const [srObj, setSRObj] = useState(sr);
    // console.log('Edit-SR-DLG --> sr', sr);

    const handleSaveDlg = async () => {
        await CorrugatorDataServices.updateCorrugator(corrId, {'sr': srObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_SR(srObj));
            handleCloseDlg();
        }).catch(error => {
            throw new Error("SR OPTIONS WRITE FUCKED UP -->", error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='md'
        >
            <Modal.Header>
                <Modal.Title>SR - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div>
                    <div style={cardStyle}>
                        {/*Number of Scorer per section*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>No of Scorer</div>
                            <InputNumber
                                id='scorers'
                                value={srObj.options.scorers}
                                onChange={(value) => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        scorers: Number(value)
                                    }
                                })}
                                min={0}
                                max={20}
                                style={{width: 80}}
                            />
                        </div>

                        {/*Number of knifes per section*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>No of Knifes</div>
                            <InputNumber
                                id='scorers'
                                value={srObj.options.knifes}
                                onChange={(value) => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        knifes: Number(value)
                                    }
                                })}
                                min={0}
                                max={12}
                                style={{width: 80}}
                            />
                        </div>
                        <hr style={hrStyle}/>

                        {/*Auxiliary Station Exist*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Auxiliary</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.auxiliary}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        auxiliary: checked
                                    }
                                })}
                            />
                        </div>

                        {/*Trim Knife Exist*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Trim Knife</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.trimknife}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        trimknife: checked
                                    }
                                })}
                            />
                        </div>

                        {/*Split Trim*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Split Trim</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.splittrim}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        splittrim: checked
                                    }
                                })}
                            />
                        </div>

                    </div>
                    <div style={cardStyle}>
                        {/*Auto Calibrationt*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Auto calibration</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.autocalibration}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        autocalibration: checked
                                    }
                                })}
                            />
                        </div>

                        {/*Auto Lubrication*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Auto lubrication</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.autolubrication}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        autolubrication: checked
                                    }
                                })}
                            />
                        </div>

                        {/*Air Blast*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Air Blast</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.airblast}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        airblast: checked
                                    }
                                })}
                            />
                        </div>

                        {/*Board Support*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Board Support</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.boardsupport}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        boardsupport: checked
                                    }
                                })}
                            />
                        </div>

                        <hr style={hrStyle}/>

                        {/*Infeed roll exist*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Infeed roll exist</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.infeedpull}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        infeedpull: checked
                                    }
                                })}
                            />
                        </div>

                        {/*Outfeed roll exist*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Outfeed roll exist</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={srObj.options.outfeedpull}
                                onChange={checked => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        outfeedpull: checked
                                    }
                                })}
                            />
                        </div>

                    </div>
                </div>
                <div>
                    {/*Section 1*/}
                    <div style={cardStyle}>
                        <div style={headerCardStyle}>Section 1</div>
                        {/*Profile 11*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Profile 1</div>
                            <InputPicker
                                data={dataSRProfiles}
                                id={'profile11'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.section1.profile1}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    section1: {
                                        ...srObj.section1,
                                        profile1: value
                                    }
                                })}
                                style={{width: 100}}
                            />
                            <InputPicker
                                data={dataSRProfileOffset}
                                id={'offsetprofile1'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.section1.offsetprofile1}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    section1: {
                                        ...srObj.section1,
                                        offsetprofile1: value
                                    }
                                })}
                                style={{width: 100}}
                            />
                        </div>

                        {/*Profile 12*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Profile 2</div>
                            <InputPicker
                                data={dataSRProfiles}
                                id={'profile12'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.section1.profile2}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    section1: {
                                        ...srObj.section1,
                                        profile2: value
                                    }
                                })}
                                style={{width: 100}}
                            />
                            <InputPicker
                                data={dataSRProfileOffset}
                                id={'offsetprofile2'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.section1.offsetprofile2}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    section1: {
                                        ...srObj.section1,
                                        offsetprofile2: value
                                    }
                                })}
                                style={{width: 100}}
                            />

                        </div>
                    </div>
                    {/*Section 2*/}
                    <div style={cardStyle}>
                        <div style={headerCardStyle}>Section 2</div>
                        {/*Profile 21*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Profile 1</div>
                            <InputPicker
                                data={dataSRProfiles}
                                id={'profile21'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.section2.profile1}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    section2: {
                                        ...srObj.section2,
                                        profile1: value
                                    }
                                })}
                                style={{width: 100}}
                            />
                            <InputPicker
                                data={dataSRProfileOffset}
                                id={'offsetprofile21'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.section2.offsetprofile1}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    section2: {
                                        ...srObj.section2,
                                        offsetprofile1: value
                                    }
                                })}
                                style={{width: 100}}
                            />
                        </div>

                        {/*Profile 22*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Profile 2</div>
                            <InputPicker
                                data={dataSRProfiles}
                                id={'profile22'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.section2.profile2}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    section2: {
                                        ...srObj.section2,
                                        profile2: value
                                    }
                                })}
                                style={{width: 100}}
                            />
                            <InputPicker
                                data={dataSRProfileOffset}
                                id={'offsetprofile22'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.section2.offsetprofile2}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    section2: {
                                        ...srObj.section2,
                                        offsetprofile2: value
                                    }
                                })}
                                style={{width: 100}}
                            />

                        </div>
                    </div>
                    {/*Drive Elau, Schneider, Type*/}
                    <div style={cardStyle}>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Drive Type</div>
                            <InputPicker
                                data={dataSRDrives}
                                id={'tooldrive'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={srObj.options.tooldrive}
                                onSelect={(value) => setSRObj({
                                    ...srObj,
                                    options: {
                                        ...srObj.options,
                                        tooldrive: value
                                    }
                                })}
                                style={{width: 200}}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, srObj)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

SREditOptionsDlg.propTypes = {
    open: PropTypes.bool,
    dispatch: PropTypes.func
};
export default SREditOptionsDlg;
