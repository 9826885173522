export const KnowledgeFileStruct = {
    refmachine: [],
    refcategory: [],
    storagepath: '',
    filename: '',
    timestamp: 0,
    version: '',
    description: '',
    title: '',
    mechanic: false,
    user: '',
};
