import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import PropTypes from "prop-types";
import MachinePanel from "../MachinePanel";
import FacerOptionContent from "./FacerOptionContent";
import {MachineName} from "../../../../Resources/StringConstants";
import {SelectorFacer} from "../../Selector/SelectorFacer";

const FacerContent = ({facerId}) => {
    const facerObj = SelectorFacer(facerId);

    const handleUpload = (para) => {
        //remove white space
        para.refmachine = facerObj.id.replace(/\s+/g, '').toLowerCase();
        //onUploadClick(para);
    };

    return (
        <Panel>
            <MachineHeader machineName={MachineName.facer + facerId.toString()}/>
            <MachinePanel
                // machineFilter={facerObj.id.replace(/\s+/g, '').toLowerCase()}
                machineFilter={MachineName.facer + facerId.toString()}
                optionComponent={<FacerOptionContent facerId={facerId}/>}
                onUploadClick={(para) => handleUpload(para)}
            />
        </Panel>
    );
};

FacerContent.propTypes = {
    facerId: PropTypes.number,
};

export default FacerContent;
