import {Link} from "react-router-dom";
import React from "react";
import {Panel, Content, FlexboxGrid, Container} from "rsuite";

const ErrorPage = () => {
    console.log('RENDER ErrorPage');
    return (
        <Container>
            <Content style={{marginTop: '3.13rem'}}>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={6}>
                        <Panel header={<h2>Error: 404</h2>} bordered>
                            <h5>Page not found</h5>
                            <Link to='/' className='rs-btn' style={{marginTop:'2.5rem'}}> Back Home </Link>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    );
};

export default ErrorPage;
