import {useDispatch, useSelector} from "react-redux";
import {getCorrCCD, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import {ContentRow} from "../../../ContentRow";
import {Button} from "rsuite";
import React from "react";
import CCDEditOptionsDlg from "./CCDEditOptionsDlg";

const CCDOptionContent = () => {
    const dispatch = useDispatch();
    const ccdObj = useSelector(getCorrCCD);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const options = ccdObj.options;

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Light barrier'} value={options.lightbarrier === true ? 'Yes' : 'No'}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg}>Edit</Button>
            </div>
            <CCDEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default CCDOptionContent;