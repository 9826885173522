import {Link} from "react-router-dom";
import React from "react";
import {Panel, Content, FlexboxGrid, Container} from "rsuite";

const SettingPage = () => {
    console.log('RENDER SettingPage');
    return (
        <Container>
            <Content style={{marginTop: '3.13rem'}}>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={18}>
                        <Panel header={<h2>Settings</h2>} bordered>
                            <Link to='/home' className='rs-btn' style={{marginTop:'2.5rem'}}>Back</Link>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    );
};

export default SettingPage;
