import {collection, deleteDoc, doc, getDocs, query, setDoc, where} from "firebase/firestore";
import {db} from "../firebase_config";
import {TimesheetMenu} from "../TestData/TimesheetMenu";
import {str_All} from "../Helpers/GeneralHelpers";

const timesheetDbName = 'timesheets';
const timesheetCollectionRef = collection(db, timesheetDbName);

class TimesheetDataService {
    getFilteredTimeSheets = ({user, facility, machine, starttime, endtime, remote}) => {
        // console.log(`GetAllTimeSheets -> User: ${user} - Facility: ${facility} - Machine: ${machine} - StartTime: ${starttime} - EndTime: ${endtime} - Remote: ${remote}`);

        let query_Conditions = [];

        // when userFilter === 'All' -> no Query conditions === get all Users
        if (user !== TimesheetMenu.find(menue => menue.key === 'all').title) {
            // console.log('getFilteredTimeSheets -> Explicit User selected');
            query_Conditions.push(where('user', '==', user));
        }

        // When facilityFilter === 'All' -> no Query conditions === get all Facilities
        if (facility !== undefined && facility !== str_All) {
            // console.log('getFilteredTimeSheets -> Explicit Facility selected');
            query_Conditions.push(where('facility', '==', facility));
        }

        // When machineFilter === 'All' -> no Query conditions === get all Machines
        if (machine !== undefined && machine !== str_All) {
            // console.log('getFilteredTimeSheets -> Explicit Machine selected');
            query_Conditions.push(where('machine', '==', machine));
        }

        // When machineFilter === 'All' -> no Query conditions === get all Machines
        if (remote !== undefined && remote === true) {
            // console.log('getFilteredTimeSheets -> Explicit Machine selected');
            query_Conditions.push(where('remote', '==', remote));
        }

        query_Conditions.push(where('starttime', '>=', starttime));
        query_Conditions.push(where('starttime', '<=', endtime));

        // console.log('getFilteredTimeSheets Query collection-> ', query_Conditions);

        const docQuery = query(timesheetCollectionRef,
            ...query_Conditions
        );
        return getDocs(docQuery);
    }

    addTimesheetDocument = (timeSheetObject) => {
        // console.log('SERVICE NEW_TIMESHEET_DOC --> id', timeSheetObject.id);
        // console.log('SERVICE NEW_TIMESHEET_DOC --> docData', timeSheetObject.docData);
        return setDoc(doc(db, timesheetDbName, timeSheetObject.id), timeSheetObject.docData);
    };

    updateTimesheetDocument = (timeSheetObject) => {
        // console.log('SERVICE UPDATE_TIMESHEET_DOC --> id', timeSheetObject.id);
        // console.log('SERVICE UPDATE_TIMESHEET_DOC --> docData', timeSheetObject.docData);
        try {
            return setDoc(doc(db, timesheetDbName, timeSheetObject.id), timeSheetObject.docData);
        } catch (error) {
            console.log('SERVICE ERROR', error);
        }
    };

    deleteTimesheetDocument = (timeSheet_id) => {
        // console.log('SERVICE DELETE_TIMESHEET_DOC --> id', timeSheet_id);
        const existingTimesheetDoc = doc(db, timesheetDbName, timeSheet_id);
        if (existingTimesheetDoc) {
            return deleteDoc(existingTimesheetDoc);
        }
    };
}

export default new TimesheetDataService();