import React from "react";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import {Button} from "rsuite";
import WebtrolEditOptionsDlg from "./WebtrolEditOptionsDlg";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {getCorrWebtrol, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const WebtrolOptionContent = () => {
    const dispatch = useDispatch();
    // const webtrolObj = useSelector(getCorrWebtrol);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    // const options = webtrolObj.options;
    // console.log('WEB-OPTION-CONETXT options-->', options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Nothing'} value={null}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <WebtrolEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default WebtrolOptionContent;
