import React from "react";
import {Button, Tooltip, Whisper} from "rsuite";
import {cardStyle, fieldStyle, headerCardStyle, hrStyle, rowCardStyle} from "../../../../Styles/ComponentStyles";
import ASMEditOptionsDlg from "./ASMEditOptionsDlg";
import {dataBrushType, dataDischargeSide, dataLevel, findLabelForKeyInArray} from "../../../../Resources/InputFormData";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {getCorrASM, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const ASMOptionContent = () => {
    const dispatch = useDispatch();
    const asmObj = useSelector(getCorrASM);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const options = asmObj.options;
    // console.log('ASM-OPTION-CONETXT options -->', options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: "start"}}>
                <div style={cardStyle}>
                    <ContentRow label={'Level'} value={findLabelForKeyInArray(dataLevel, options.level)}/>
                    <ContentRow label={'Safty PLC'} value={options.saftyplc === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Sync Brush'} value={options.syncbrush === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Waste Belts'} value={options.wastebelt === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Auto Drop Height'} value={options.autodropheight === true ? 'Yes' : 'No'}/>
                </div>

                <div style={cardStyle}>
                    <div style={headerCardStyle}>Lift Tables</div>
                    <ContentRow label={'Two Motors'} value={options.doublemotortable === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Encoder'} value={options.encodertable === true ? 'Yes' : 'No'}/>
                    <hr style={hrStyle}/>
                    <div style={headerCardStyle}>Stack Chamber Length</div>
                    <div style={rowCardStyle}>
                        <div style={{width: '4rem'}}>AS-1</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(options.chamberlength[1] / 25.4).toFixed(1)} "</Tooltip>}>
                            <div style={fieldStyle}>{options.chamberlength[1]} mm</div>
                        </Whisper>
                    </div>
                    {(options.level === 'TRIPLEX' || options.level === 'DUPLEX') &&
                        <div style={rowCardStyle}>
                            <div style={{width: '4rem'}}>AS-2</div>
                            <Whisper placement="top" trigger="hover"
                                     speaker={<Tooltip>{(options.chamberlength[2] / 25.4).toFixed(1)} "</Tooltip>}>
                                <div style={fieldStyle}>{options.chamberlength[2]} mm</div>
                            </Whisper>
                        </div>
                    }
                    {(options.level === 'TRIPLEX') &&
                        <div style={rowCardStyle}>
                            <div style={{width: '4rem'}}>AS-3</div>
                            <Whisper placement="top" trigger="hover"
                                     speaker={<Tooltip>{(options.chamberlength[3] / 25.4).toFixed(1)} "</Tooltip>}>
                                <div style={fieldStyle}>{options.chamberlength[3]} mm</div>
                            </Whisper>
                        </div>
                    }
                </div>

                <div style={cardStyle}>
                    <div style={headerCardStyle}>Discharge Side</div>
                    <div style={rowCardStyle}>
                        <div style={{width: '4rem'}}>AS-1</div>
                        <div
                            style={fieldStyle}>{findLabelForKeyInArray(dataDischargeSide, options.dischargeside[1])}</div>
                    </div>
                    {(options.level === 'TRIPLEX' || options.level === 'DUPLEX') &&
                        <div style={rowCardStyle}>
                            <div style={{width: '4rem'}}>AS-2</div>
                            <div
                                style={fieldStyle}>{findLabelForKeyInArray(dataDischargeSide, options.dischargeside[2])}</div>
                        </div>
                    }
                    {(options.level === 'TRIPLEX') &&
                        <div style={rowCardStyle}>
                            <div style={{width: '4rem'}}>AS-3</div>
                            <div
                                style={fieldStyle}>{findLabelForKeyInArray(dataDischargeSide, options.dischargeside[3])}</div>
                        </div>
                    }
                    <hr style={hrStyle}/>
                </div>

                <div style={cardStyle}>
                    <div style={headerCardStyle}>Brush Section</div>
                    <div style={rowCardStyle}>
                        <div style={{width: '4rem'}}>AS-1</div>
                        <div style={fieldStyle}>{findLabelForKeyInArray(dataBrushType, options.brushtype[1])}</div>
                    </div>
                    {(options.level === 'TRIPLEX' || options.level === 'DUPLEX') &&
                        <div style={rowCardStyle}>
                            <div style={{width: '4rem'}}>AS-2</div>
                            <div style={fieldStyle}>{findLabelForKeyInArray(dataBrushType, options.brushtype[2])}</div>
                        </div>
                    }
                    {(options.level === 'TRIPLEX') &&
                        <div style={rowCardStyle}>
                            <div style={{width: '4rem'}}>AS-3</div>
                            <div style={fieldStyle}>{findLabelForKeyInArray(dataBrushType, options.brushtype[3])}</div>
                        </div>
                    }
                    <hr style={hrStyle}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <ASMEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default ASMOptionContent
