import React from "react";
import {Button, Panel, Content, FlexboxGrid, Container} from "rsuite";
import {useUserAuth} from './Authentication/UserAuthContext';
import {useNavigate} from "react-router-dom";

const HomePage = () => {
    // console.log('RENDER HomePage');
    const navigate = useNavigate();
    const {logOut} = useUserAuth();

    const handleLogout = async () => {
        await logOut().then(() => {
            navigate("/")
        }).catch((error) => {
            throw new Error('Failed to Logout', error);
        });
    };

    return (
        <Container>
            <Content style={{marginTop: '3.13rem'}}>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={18}>
                        <Panel header={<h2>Im home</h2>} bordered>
                            {/*<Link to='/about' className='rs-btn'>AboutPage</Link>*/}
                            {/*<Link to='/rsbutton' className='rs-btn'>Button</Link>*/}
                            <Button
                                type='submit'
                                appearance="primary"
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    );
};

export default HomePage;
