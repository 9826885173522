import React from "react";
import {Button} from "rsuite";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import GUEditOptionsDlg from "./GUEditOptionsDlg";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {getCorrGU, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const GUOptionContent = () => {
    const dispatch = useDispatch();
    const guObj = useSelector(getCorrGU);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const options = guObj.options;
    // console.log('GU-OPTION-CONETXT options-->', options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Standalone'} value={options.standalone === true ? 'Yes' : 'No'}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg}>Edit</Button>
            </div>
            <GUEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default GUOptionContent;
