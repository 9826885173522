import React from 'react';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {useUserAuth} from "../Pages/Authentication/UserAuthContext";
import {Badge, Button, Nav, Navbar} from "rsuite";
import {CorrugatorDocuments} from "../TestData/CorrugatorDocuments";
import AboutDlg from "./AboutDlg";
import '../App.css'

import Home from '@rsuite/icons/legacy/Home';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import StorageIcon from '@rsuite/icons/Storage';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import DashboardIcon from '@rsuite/icons/Dashboard';
import Cog from "@rsuite/icons/legacy/Cog";
import HistoryIcon from '@rsuite/icons/History';
import {TOGGLE_ABOUT_DLG} from "../Reducers/CorrugatorSlice";
import {KnowledgeMenu} from "../TestData/KnowledgeMenu";
import '../App.css';
import {LOGOUT} from "../Pages/Authentication/UserAuthSlice";
import {TimesheetMenu} from "../TestData/TimesheetMenu";


const CustomNavBar = ({onSelect, activeKey, ...props}) => {
    // console.log('RENDER CUSTOMNAVBAR');
    const openDlg = useSelector((state) => state.corrObjReducer.openAboutDlg);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {logOut, user} = useUserAuth();

    const logOutHandle = async () => {
        await logOut()
            .then(() => {
                dispatch(LOGOUT());
                navigate('/');
            })
            .catch((err) => {
                throw new Error({err});
            })
    };

    return (<>
        <Navbar {...props}>
            <Nav>
                <Nav.Item
                    eventKey='Home'
                    icon={<Home/>}
                    as={NavLink}
                    to="/home"
                >
                    Home
                </Nav.Item>
                <Nav.Item
                    eventKey='Dashboard'
                    icon={<DashboardIcon/>}
                    as={NavLink}
                    to="/home/dashboard"
                >
                    Dashboard
                </Nav.Item>
                <Nav.Menu title="Time sheets" icon={<HistoryIcon/>}>
                    {/*<div style={{height: 500, overflowY: "auto"}}>*/}
                    {TimesheetMenu.map((menue) => (
                        <Nav.Item
                            eventKey={menue.key}
                            key={menue.key}
                            as={NavLink}
                            to={"/home/timesheet/" + menue.key}>{menue.title}
                        </Nav.Item>
                    ))}
                    {/*</div>*/}
                </Nav.Menu>
                <Nav.Menu title="Tech Docus" icon={<SingleSourceIcon/>}>
                    <div style={{height: 500, overflowY: "auto"}}>
                        {KnowledgeMenu.map((menue) => (
                            <Nav.Item
                                eventKey={menue.key}
                                key={menue.key}
                                as={NavLink}
                                to={"/home/knowledge/" + menue.key}>{menue.title}
                            </Nav.Item>
                        ))}
                    </div>
                </Nav.Menu>
            </Nav>
            <Nav justified>
                <Badge content={CorrugatorDocuments.length} style={{top: 0}}>
                    <Nav.Menu title='Corrugators' icon={<StorageIcon/>}>
                        <div style={{height: 500, overflowY: "auto"}}>
                            {CorrugatorDocuments.map((corrId) => (
                                <Nav.Item
                                    eventKey='corrId'
                                    key={corrId}
                                    as={NavLink}
                                    to={"/home/corrugator/" + corrId}>{corrId}
                                </Nav.Item>
                            ))}
                        </div>
                    </Nav.Menu>
                </Badge>
            </Nav>
            {/*<Nav justified>*/}
            {/*    <Nav.Menu title='Test'>*/}
            {/*        {CorrDocu_Test.map((menue, index) => {*/}
            {/*            return (*/}
            {/*                <Nav.Menu title={menue.name} key={index}>*/}

            {/*                    {menue.subMenus.map((subMenue) => {*/}
            {/*                        return (*/}
            {/*                            <Nav.Item*/}
            {/*                                disabled*/}
            {/*                                eventKey={subMenue.id}*/}
            {/*                                key={subMenue.id}*/}
            {/*                                as={NavLink}*/}
            {/*                                to={"/home/corrugator/" + subMenue.id}>{subMenue.name}*/}
            {/*                            </Nav.Item>*/}
            {/*                        )*/}
            {/*                    })}*/}

            {/*                </Nav.Menu>*/}
            {/*            )*/}
            {/*        })}*/}
            {/*    </Nav.Menu>*/}
            {/*</Nav>*/}
            <Nav>
                <Nav.Item
                    eventKey='AboutDlg'
                    icon={<InfoOutlineIcon/>}
                    onClick={() => dispatch(TOGGLE_ABOUT_DLG(true))}
                >
                    About
                </Nav.Item>
            </Nav>
            <Nav pullRight>
                <Nav.Item
                    eventKey='Settings'
                    icon={<Cog/>}
                    as={NavLink}
                    to="/home/settings"
                >
                    Settings
                </Nav.Item>
                <Nav.Item
                    eventKey='Logout'
                    onClick={logOutHandle}
                >
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <p style={{paddingBottom: 0}}>{user.displayName}</p>
                        <Button className='rs-btn-link' style={{paddingTop: 0}}>Logout</Button>
                    </div>
                </Nav.Item>
            </Nav>
        </Navbar>
        <AboutDlg openDlg={openDlg} dispatch={dispatch}/>
    </>);
};

CustomNavBar.propTypes = {
    onSelect: PropTypes.func, activeKey: PropTypes.string
};

export default CustomNavBar;
