export const visu = {
    ipaddress: '',
    screentype: '',
    visutype: '',               // MP377
    access: '',             // Ultra Win CC
    username: '',
    password: '',
    port: 0
}

