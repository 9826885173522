import React from "react";
import PropTypes from "prop-types";
import {cardStyle, hrStyle} from "../../../../Styles/ComponentStyles";
import {SelectorFacerOption} from "../../Selector/SelectorFacerOption";
import {Button} from "rsuite";
import FacerEditOptionsDlg from "./FacerEditOptionsDlg";
import {dataSteamShower, dataSteamSupply, findLabelForKeyInArray} from "../../../../Resources/InputFormData";
import MotorCard from "../MotorCard";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const FacerOptionContent = ({facerId}) => {
    const dispatch = useDispatch();
    const options = SelectorFacerOption(facerId);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    // console.log('FACER-OPTION-CONETXT options-->', options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Corrugator Roll 1'} value={options.rollset1}/>
                    <ContentRow label={'Corrugator Roll 2'} value={options.rollset2}/>
                    <ContentRow label={'Corrugator Roll 3'} value={options.rollset3}/>
                    <hr style={hrStyle}/>
                    <ContentRow label={'Steam shower'}
                                value={findLabelForKeyInArray(dataSteamShower, options.steamshower)}/>
                </div>

                <div style={cardStyle}>
                    <MotorCard optObj={options} />
                </div>

                <div style={cardStyle}>
                    <ContentRow label={'Brake roll liner'} value={options.brakerollliner === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Brake roll medium'} value={options.brakerollmedium === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Tension roll'} value={options.tensionroll === true ? 'Yes' : 'No'}/>
                    <hr style={hrStyle}/>
                    <ContentRow label={'Temperature control'} value={options.tempcontrol === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Steam control'}
                                value={findLabelForKeyInArray(dataSteamSupply, options.steamcontrol)}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <FacerEditOptionsDlg facerId={facerId} open={openDlg} dispatch={dispatch}/>
        </>
    );
};

FacerOptionContent.propTypes = {
    facerId: PropTypes.number,
};

export default FacerOptionContent;
