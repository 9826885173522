import React, {useEffect, useState} from "react";
import {Input, Message, Table} from "rsuite";
import {FileSplitString} from "../../Resources/StringConstants";
import {useDispatch, useSelector} from "react-redux";
import {confirmAlert} from "react-confirm-alert";
import {ActionCell, CheckBoxCell, ExpandCell, ToggleCell} from "../TableCell";
import {str_DELETE, str_DOWNLOAD, str_EDIT, str_failed, str_idle, str_loading, str_succeeded} from "../../Helpers/GeneralHelpers";
import KnowledgeUploadDlg from "./KnowledgeUploadDlg";
import {SET_TIMESHEET_FETCH_LIST_STATUS} from "../Timesheets/TimesheetSlice";
import {ASYNC_DELETE_KNOWLEDGE_DOC, getKnowledgeFetchListError, getKnowledgeFetchListStatus, getKnowledgeList, KNOWLWEDGE_TOGGLE_DLG} from "./KnowledgeSlice";
import {cloneDeep} from "lodash";

const {Column, HeaderCell, Cell} = Table;

const KnowledgeFileTable = () => {
    const dispatch = useDispatch();
    const knowledgeList = cloneDeep(useSelector(getKnowledgeList));
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const knowledgeFetchStatus = useSelector(getKnowledgeFetchListStatus);
    const knowledgeFetchError = useSelector(getKnowledgeFetchListError);
    const openDlg = useSelector((state) => state.knowledgeReducer.openKnowledgeDlg);
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const rowKey = 'id';

    console.log('knowledgeFileTable -> knowledgeList: ', knowledgeList);

    useEffect(() => {
        if (knowledgeFetchStatus === str_loading) {
            setLoading(true);
            setErrorLoading(false);
        } else if (knowledgeFetchStatus === str_succeeded) {
            setLoading(false);
            setErrorLoading(false);
            dispatch(SET_TIMESHEET_FETCH_LIST_STATUS(str_idle))
        } else if (knowledgeFetchStatus === str_failed) {
            setLoading(false);
            setErrorLoading(true);
        }
    }, [dispatch, knowledgeFetchStatus]);

    const renderRowExpanded = rowData => {
        console.log('RowExpanded -> rowData', rowData);
        return (
            <div style={{display: "flex", width: 700}}>
                <div
                    style={{
                        width: 60,
                        height: 120,
                        marginRight: 15,
                        marginLeft: 40
                    }}
                >
                    <p><b>Description:</b></p>
                </div>
                {/*<p>{rowData.docData.description}</p>*/}
                <Input disabled as="textarea" rows={3} placeholder="Description..." value={rowData.docData.description}/>
            </div>
        );
    };
    const handleExpanded = (rowData) => {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const onFileAction = async (fileActionObj) => {
        const {knowledgeDoc, command} = fileActionObj;
        console.log('STORAGEPATH ->', knowledgeDoc.storagepath);

        if (!fileActionObj || knowledgeDoc.storagepath === undefined || knowledgeDoc.storagepath === '') {
            console.error('FileAction not KnowledgeDoc object');
            return;
        }

        if (command === str_DOWNLOAD) {
            await downloadKnowledgeDoc(knowledgeDoc);
        } else if (command === str_EDIT) {
            //dispatch(FILL_UPLOAD_OBJ(file));
            dispatch(KNOWLWEDGE_TOGGLE_DLG(true));
        } else if (command === str_DELETE) {
            console.log('DOCUMENT-NAME', knowledgeDoc);
            let tmpFileName = knowledgeDoc.filename.split(FileSplitString);
            console.log('SPLIT STRING', tmpFileName);
            confirmAlert({
                title: 'Delete Document',
                message: tmpFileName[0],
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteKnowledgeDoc(knowledgeDoc.id)
                    },
                    {
                        label: 'No',
                        onClick: () => console.log('Click No')
                    }
                ]
            });
        }
    };

    const deleteKnowledgeDoc = async (knowledge_id) => {
        console.log('KnowledgeTable delete document', knowledge_id);
        try {
            await dispatch(ASYNC_DELETE_KNOWLEDGE_DOC(knowledge_id)).unwrap()
        } catch(error)  {
            throw new Error('Delete Knowledge Doc failed', error);
        }
    };

    const downloadKnowledgeDoc = async (knowledgeDoc) => {

    }

    const getData = () => {
        if (sortColumn && sortType) {
            let sortList = cloneDeep(knowledgeList);
            return sortList.sort((a, b) => {
                // console.log('Sort Column:', sortColumn);
                // console.log('Sort Type:', sortType); // asc or desc

                let x = a.docData[sortColumn];
                let y = b.docData[sortColumn];

                // console.log('X', x);
                // console.log('Y', y);

                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return knowledgeList;
    };
    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 300);
    };

    if (errorLoading) {
        return (
            <Message showIcon closable type="error" header="Error">{knowledgeFetchError}</Message>
        );
    } else {
        return (
            <div style={{paddingTop: 15}}>
                <Table
                    shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
                    height={600}
                    data={getData()}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    loading={loading}
                    rowKey={rowKey}
                    expandedRowKeys={expandedRowKeys}
                    rowExpandedHeight={150}
                    renderRowExpanded={renderRowExpanded}
                >
                    <Column width={50}>
                        <HeaderCell>#</HeaderCell>
                        <ExpandCell dataKey="description" expandedRowKeys={expandedRowKeys} onChange={handleExpanded}/>
                    </Column>
                    <Column width={600} resizable sortable>
                        <HeaderCell>Title</HeaderCell>
                        <Cell dataKey='docData.title' style={{color: 'white'}}/>
                    </Column>
                    <Column width={100} sortable align="center">
                        <HeaderCell>Mechanic</HeaderCell>
                        <CheckBoxCell dataKey="mechanic"/>
                    </Column>
                    <Column width={100} sortable align="center">
                        <HeaderCell>Language</HeaderCell>
                        <ToggleCell dataKey="language" selection={['DE','EN']} style={{color: 'white'}}/>
                    </Column>
                    <Column width={80} sortable align="center">
                        <HeaderCell>User</HeaderCell>
                        <Cell dataKey="docData.user"/>
                    </Column>
                    <Column width={200} align="center">
                        <HeaderCell>Action</HeaderCell>
                        <ActionCell dataKey="storagepath" onFileAction={onFileAction}/>
                    </Column>
                </Table>
                <KnowledgeUploadDlg open={openDlg}/>
            </div>
        );
    }
};

export default KnowledgeFileTable;