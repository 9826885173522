import React, {useState} from "react";
import {Button, ButtonToolbar, InputNumber, InputPicker, Modal, Toggle} from "rsuite";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {dataEvacuation, dataKnifebar} from "../../../../Resources/InputFormData";
import MotorEditCard from "../MotorEditCard";
import {isEqual, cloneDeep} from "lodash";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrId, getCorrKQ, TOGGLE_DLG, UPDATE_KQ_OPTIONS} from "../../../../Reducers/CorrugatorSlice";

const KQEditOptionsDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const kqObj = useSelector(getCorrKQ);
    const options = kqObj.options;
    const tempState = cloneDeep(options);
    const [optObj, setOptObj] = useState(options);
    // console.log('KQ-EDIT-DLG', options);

    const handleSaveDlg = async () => {
        await CorrugatorDataServices.updateCorrugator(corrId, {'kq.options': optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_KQ_OPTIONS(optObj));
            handleCloseDlg();
        }).catch(error => {
            throw new Error("KQ OPTIONS WRITE FUCKED UP -->", error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            onClose={handleCloseDlg}
            open={open}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='md'
        >
            <Modal.Header>
                <Modal.Title>KQ - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    <MotorEditCard optObj={optObj} setOptObj={setOptObj}/>
                    <hr style={hrStyle}/>
                    {/*Knife bar*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Knife bar</div>
                        <InputPicker
                            data={dataKnifebar}
                            id={'knifebar'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={optObj.knifebar}
                            onSelect={(value) => setOptObj({...optObj, knifebar: value})}
                            style={{width: 100}}
                        />
                    </div>
                </div>
                <div style={cardStyle}>
                    {/*Type of Evacuation*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Evacuation type</div>
                        <InputPicker
                            data={dataEvacuation}
                            id={'evacuation'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={optObj.evacuation}
                            onSelect={(value) => setOptObj({...optObj, evacuation: value})}
                            style={{width: 100}}
                        />
                    </div>
                    {/*Evacuation belt*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Evacuation belt</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.evacuationbelt}
                            onChange={checked => setOptObj({...optObj, evacuationbelt: checked})}
                        />
                    </div>
                    <hr style={hrStyle}/>
                    {/*Variable Sheet Length*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Var Sheet length</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.varsheetlength}
                            onChange={checked => setOptObj({...optObj, varsheetlength: checked})}
                        />
                    </div>
                    <hr style={hrStyle}/>
                    {/*Speed cut 1x*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Speed cut 1x</div>
                        <InputNumber
                            id='speedcut1x'
                            value={optObj.speedcut1x}
                            onChange={(value) => setOptObj({...optObj, speedcut1x: Number(value)})}
                            min={0}
                            max={500}
                            style={{width: 80}}
                        />
                    </div>
                    {/*Speed cut nx*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Speed cut Nx</div>
                        <InputNumber
                            id='speedcutNx'
                            value={optObj.speedcutnx}
                            onChange={(value) => setOptObj({...optObj, speedcutnx: Number(value)})}
                            min={0}
                            max={500}
                            style={{width: 80}}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, optObj)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

KQEditOptionsDlg.propTypes = {
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default KQEditOptionsDlg
