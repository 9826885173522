import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import GUOptionContent from "./GUOptionContent";

const GUContent = () => {

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<GUOptionContent/>}/>
        </Panel>
    );
};

export default GUContent;
