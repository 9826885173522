import React from "react";
import {cardStyle, fieldStyle, hrStyle, labelStyle, rowCardStyle} from "../../../../Styles/ComponentStyles";
import {Button, Tooltip, Whisper} from "rsuite";
import KQEditOptionsDlg from "./KQEditOptionsDlg";
import {dataEvacuation, dataKnifebar, findLabelForKeyInArray} from "../../../../Resources/InputFormData";
import MotorCard from "../MotorCard";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {getCorrKQ, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const KQOptionContent = () => {
    const dispatch = useDispatch();
    const kqObj = useSelector(getCorrKQ);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const options = kqObj.options;
    // console.log('KQ-OPTION-CONETXT options-->', options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <MotorCard optObj={options}/>
                    <hr style={hrStyle}/>
                    <ContentRow label={'Type of Knifebar'}
                                value={findLabelForKeyInArray(dataKnifebar, options.knifebar)}/>
                </div>

                <div style={cardStyle}>
                    <ContentRow label={'Type of Evacuation'}
                                value={findLabelForKeyInArray(dataEvacuation, options.evacuation)}/>
                    <ContentRow label={'Evacuation Belt'} value={options.evacuationbelt === true ? 'Yes' : 'No'}/>
                    <hr style={hrStyle}/>

                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Cut Speed 1x</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(options.speedcut1x / 25.4).toFixed()} ft/min</Tooltip>}>
                            <div style={fieldStyle}>{options.speedcut1x} m/min</div>
                        </Whisper>
                    </div>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Cut Speed Nx</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(options.speedcutnx / 25.4).toFixed()} ft/min</Tooltip>}>
                            <div style={fieldStyle}>{options.speedcutnx} m/min</div>
                        </Whisper>
                    </div>
                    <ContentRow label={'Varialble Sheet length'}
                                value={options.varsheetlength === true ? 'Yes' : 'No'}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <KQEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default KQOptionContent;
