import {MachineName} from "../../../Resources/StringConstants";
import {
    dataAOCType, dataASMType,
    dataBBRType, dataBDCType,
    dataBLCType, dataCCDType, dataCOMType, dataDFType,
    dataFacerType, dataGUType,
    dataHBType, dataHQType,
    dataKQFType, dataKQType,
    dataRSType, dataSplicerType,
    dataSRType, dataWebtrolType
} from "../../../Resources/InputFormData";

export const SelectMachineNameArray = (machineFilter) => {

    switch (machineFilter.toLowerCase()) {
        case MachineName.rs1:
        case MachineName.rs2:
        case MachineName.rs3:
        case MachineName.rs4:
        case MachineName.rs5:
        case MachineName.rs6:
        case MachineName.rs7:
            return dataRSType;
        case MachineName.sp1:
        case MachineName.sp2:
        case MachineName.sp3:
        case MachineName.sp4:
        case MachineName.sp5:
        case MachineName.sp6:
        case MachineName.sp7:
            return dataSplicerType;
        case MachineName.facer1:
        case MachineName.facer2:
        case MachineName.facer3:
            return dataFacerType;
        case MachineName.bbr:
            return dataBBRType;
        case MachineName.blc:
            return dataBLCType;
        case MachineName.webtrol:
            return dataWebtrolType;
        case MachineName.gu:
            return dataGUType;
        case MachineName.df:
            return dataDFType;
        case MachineName.com:
            return dataCOMType;
        case MachineName.ccd:
            return dataCCDType;
        case MachineName.aoc:
            return dataAOCType;
        case MachineName.kq:
            return dataKQType;
        case MachineName.hb1:
        case MachineName.hb2:
            return dataHBType;
        case MachineName.sr:
            return dataSRType;
        case MachineName.bdc:
            return dataBDCType;
        case MachineName.kqf:
            return dataKQFType;
        case MachineName.hq:
            return dataHQType;
        case MachineName.asm:
            return dataASMType;
        default:
            throw new Error('Unhandled SelectorMachine ARRAY');
    }
}
