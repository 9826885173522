import React, {useState} from "react";
import {Container, Content, Header, Notification} from "rsuite";
import MachineHeaderNavBar from "../MachineHeaderNavBar";
import FileTable from "../../FileTable";
import GeneralContent from "./General/GeneralContent";
import {MachineTab} from "../../../Resources/StringConstants";
import VisuContent from "./Visu/VisuContent";
import {
    getDrawingDocs, getDriveDocs, getPlcDocs, getVariousDocs, RESET_ERROR, SET_ACTIVE_CATEGORY
} from "../../../Reducers/CorrugatorSlice";
import {useDispatch, useSelector} from "react-redux";
import InfoContent from "./Info/InfoContent";

const containerStyle = {
    marginBottom: '2.5rem',
    marginRight: '0.5rem',
    marginLeft: '0.5rem'
};

const contentStyle = {
    marginTop: '1.25rem',
    marginLeft: '1.25rem',
    marginRight: '1.25rem'
};

const MachinePanel = ({optionComponent}) => {
    const dispatch = useDispatch();
    const hasError = useSelector((state) => state.corrObjReducer.hasError);
    const message = useSelector((state) => state.corrObjReducer.message);
    const [active, setActive] = useState('general');
    const drawingList = useSelector(getDrawingDocs);
    const plcList = useSelector(getPlcDocs);
    const driveList = useSelector(getDriveDocs);
    const variousList = useSelector(getVariousDocs);

    // console.log('MACHINE-PANEL --> List', useSelector(getCorrDocArray));
    // console.log('MACHINE-PANEL --> drawingList', drawingList);
    // console.log('MACHINE-PANEL --> plcList', plcList);
    // console.log('MACHINE-PANEL --> driveList', driveList);
    // console.log('MACHINE-PANEL --> variousList', variousList);

    const contentSwitch = () => {
        switch (active) {
            case MachineTab.general:
                return <GeneralContent/>;
            case MachineTab.options:
                return optionComponent;
            case MachineTab.visu:
                return <VisuContent/>
            case MachineTab.drawings:
                return <FileTable fileList={drawingList}/>;
            case MachineTab.drives:
                return <FileTable fileList={driveList}/>;
            case MachineTab.plc:
                return <FileTable fileList={plcList}/>;
            case MachineTab.various:
                return <FileTable fileList={variousList}/>;
            case MachineTab.info:
                return <InfoContent/>;
            default:
                return <h3>Holy Shit...what you did??</h3>
        }
    };

    const handleOnSelectNavBar = (eventkey) => {
        setActive(eventkey);
        dispatch(SET_ACTIVE_CATEGORY(eventkey));
    };

    return (
        <Container style={containerStyle}>

            {hasError &&
                <Notification closable onClose={() => dispatch(RESET_ERROR())} type='error' header='Error' style={{width: 400}} duration={0}>
                    <p style={{width: 320}}>{message}</p>
                </Notification>
            }
            <Header>
                <MachineHeaderNavBar
                    onSelect={handleOnSelectNavBar}
                    active={active}
                    countArray={[drawingList.length, driveList.length, plcList.length, variousList.length]}
                />
            </Header>
            <Content style={contentStyle}>
                {contentSwitch()}
            </Content>
        </Container>
    );
};

export default MachinePanel;
