import {Checkbox, Divider, IconButton, Table, Tooltip, Whisper} from "rsuite";
import {Edit} from "@rsuite/icons";
import ExportIcon from "@rsuite/icons/Export";
import TrashIcon from "@rsuite/icons/Trash";
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import React from "react";
import {FileSplitString} from "../Resources/StringConstants";
import {useUserAuth} from "../Pages/Authentication/UserAuthContext";
import {str_DELETE, str_DOWNLOAD, str_EDIT} from "../Helpers/GeneralHelpers";


const {Cell} = Table;

export const ActionCell = ({rowData, dataKey, onFileAction, ...props}) => {

    function handleDownloadAction() {
        if (onFileAction) {
            onFileAction({file: rowData, command: str_DOWNLOAD});
        }
    }

    function handleDeleteAction() {
        //alert(`path:${rowData[dataKey]}`);
        if (onFileAction) {
            onFileAction({file: rowData, command: str_DELETE});
        }
    }

    function handleEditAction() {
        if (onFileAction) {
            onFileAction({file: rowData, command: str_EDIT})
        }
    }

    return (
        <Cell {...props} className="link-group">
            <Whisper followCursor speaker={<Tooltip>Edit Document</Tooltip>}>
                <IconButton appearance="subtle" onClick={handleEditAction} icon={<Edit/>}/>
            </Whisper>
            <Divider vertical/>
            <Whisper followCursor speaker={<Tooltip>Download Document</Tooltip>}>
                <IconButton appearance="subtle" onClick={handleDownloadAction} icon={<ExportIcon/>}/>
            </Whisper>
            <Divider vertical/>
            <Whisper followCursor speaker={<Tooltip>Delete Document</Tooltip>}>
                <IconButton appearance="subtle" onClick={handleDeleteAction} icon={<TrashIcon/>}/>
            </Whisper>
        </Cell>
    );
};

export const FilenameCell = ({rowData, dataKey, ...props}) => {
    return (
        <Cell {...props} className='file-name'>
            <label>{rowData[dataKey].split(FileSplitString)[0]}</label>
        </Cell>
    );
};

export const TimeSheetCell = ({rowData, dataKey, ...props}) => {
    return (
        <Cell {...props} className='machine-name'>
            <label>{rowData.docData[dataKey]}</label>
        </Cell>
    )
}

export const TimestampCell = ({rowData, dataKey, ...props}) => {
    return (
        <Cell {...props} className='link-group'>
            <label>{new Date(rowData[dataKey]).toLocaleString()}</label>
        </Cell>
    );
};

export const MachineCell = ({rowData, dataKey, ...props}) => {
    return (
        <Cell {...props} className='machine-name'>
            <label>{rowData.docData[dataKey].toUpperCase()}</label>
        </Cell>
    )
}

export const TimeDateCell = ({rowData, dataKey, ...props}) => {
    return (
        <Cell {...props} className='date-Time'>
            <label>{new Date(rowData.docData[dataKey]).toLocaleString()}</label>
        </Cell>
    );
};

export const ExpandCell = ({rowData, dataKey, expandedRowKeys, onChange, ...props}) => {
    // console.log('Expanded cell -> row_Data:', rowData);
    // console.log('Expanded cell -> dataKey:', dataKey);

    return (
        <Cell {...props} style={{padding: 5}}>
            <IconButton
                disabled={rowData.docData[dataKey].trim().length === 0}
                appearance="subtle"
                onClick={() => {
                    onChange(rowData);
                }}
                icon={expandedRowKeys.some(key => key === rowData['id']) ? (<CollaspedOutlineIcon/>) : (<ExpandOutlineIcon/>)}
            />
        </Cell>
    );
};

export const CheckBoxCell = ({rowData, dataKey, ...props}) => {
    return (
        <Cell {...props} style={{padding: 0}}>
            <div style={{lineHeight: '46px'}}>
                <Checkbox
                    appearance="subtle"
                    readOnly
                    checked={rowData.docData[dataKey]}
                    inline
                />
            </div>
        </Cell>
    );
};

export const ToggleCell = ({rowData, dataKey, selection, ...props}) => {
    return (
        <Cell {...props} style={{padding: 0}}>
            <div style={{lineHeight: '46px'}}>
                {rowData.docData[dataKey] === true ? <div>{selection[0]}</div> : <div>{selection[1]}</div>}
            </div>
        </Cell>
    );
};
export const HeaderSummary = ({title, summary, unit}) => {
    return (
        <div>
            <label>{title}</label>
            <div
                style={{
                    fontSize: 16,
                    color: '#2eabdf'
                }}
            >
                {summary} {unit}
            </div>
        </div>
    )
};

export const TimesheetActionCell = ({rowData, onTimesheetAction, ...props}) => {
    // you can not change or delete Timesheets, when you are not the owner of the timesheet
    // console.log('ActionCell',rowData.docData.user);
    const timeSheetUser = rowData.docData.user;
    const {user} = useUserAuth();
    const disabled = timeSheetUser !== user.displayName;
    // console.log('ActionCell -> TimeSheetUser',timeSheetUser);
    // console.log('ActionCell -> ActiveUser',user.displayName);
    // console.log('ActionCell -> Disabled',disabled);

    function handleDeleteAction() {
        if (onTimesheetAction) {
            onTimesheetAction({timesheet: rowData, command: str_DELETE});
        }
    }

    function handleEditAction() {
        if (onTimesheetAction) {
            onTimesheetAction({timesheet: rowData, command: str_EDIT})
        }
    }

    return (
        <Cell {...props} className="link-group" style={{display: "flex", alignItems: "center"}}>
            <Whisper followCursor speaker={<Tooltip>Edit Time sheet</Tooltip>}>
                <IconButton appearance="subtle" onClick={handleEditAction} icon={<Edit/>} disabled={disabled}/>
            </Whisper>
            <Divider vertical/>
            <Whisper followCursor speaker={<Tooltip>Delete Time sheet</Tooltip>}>
                <IconButton appearance="subtle" onClick={handleDeleteAction} icon={<TrashIcon/>} disabled={disabled}/>
            </Whisper>
        </Cell>
    );
};



