import {Badge, Divider, Nav, Navbar, Sidebar, Sidenav} from 'rsuite';
import PropTypes from "prop-types";
import {AngleLeft, AngleRight} from '@rsuite/icons/es/icons/legacy';
import {Dashboard} from '@rsuite/icons';
import Group from '@rsuite/icons/legacy/Group';
import ToolsIcon from '@rsuite/icons/Tools';
import {MachineName} from '../../Resources/StringConstants';
import {useDispatch, useSelector} from 'react-redux';
import {getCorrObj, TOGGLE_SIDEBAR} from "../../Reducers/CorrugatorSlice";

const NavToggle = ({expand, onChange}) => {
    return (
        <Navbar appearance='subtle' className='rs-sidenav-toggle'>
            <Nav pullRight>
                <Nav.Item onClick={onChange} style={{width: 56, textAlign: 'center'}}>
                    {expand ? <AngleLeft/> : <AngleRight/>}
                </Nav.Item>
            </Nav>
        </Navbar>
    );
};


const CorrugatorSideBar = ({active, onSelect}) => {
    const dispatch = useDispatch();
    const expandSidebar = useSelector((state) => state.corrObjReducer.expandSidebar);
    const corrObj = useSelector(getCorrObj);
    const wetend = corrObj.wetend;
    const dryend = corrObj.dryend;
    // console.log('RENDER CORRUGATOR-SIDEBAR --> wetend', wetend);
    // console.log('RENDER CORRUGATOR-SIDEBAR --> dryend', dryend);

    const sideBarStyle = {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        height: 700,
//        position: 'sticky',
        paddingTop: '0.63rem',
    };

    const handleSideBar = () => {
        dispatch(TOGGLE_SIDEBAR(false));
    }

    return (
        <div>
            <Sidebar
                style={sideBarStyle}
                width={expandSidebar ? 260 : 56}
                collapsible
            >
                <Sidenav expanded={expandSidebar} defaultOpenKeys={[MachineName.corrugator]} appearance="subtle">
                    <Sidenav.Body style={{overflow: 'auto'}}>
                        <Nav activeKey={active} onSelect={onSelect}>
                            <Nav.Menu
                                eventKey='6'
                                trigger='hover'
                                title='Corrugator'
                                // icon={<Magic/>}
                                placement='rightStart'
                                style={{fontWeight: 'Bold'}}
                            >
                                <Nav.Item eventKey='newCorr' icon={<Dashboard/>}>
                                    {/*we use handleOnClickCustomer to select*/}
                                    New Corrugator
                                </Nav.Item>
                                <Nav.Item eventKey='editCorr' icon={<Dashboard/>}>
                                    {/*we use handleOnClickCustomer to select*/}
                                    Edit Corrugator
                                </Nav.Item>
                            </Nav.Menu>
                            <Divider/>
                            <Nav.Item eventKey='3' icon={<Group/>}>
                                Contacts
                            </Nav.Item>
                            <Nav.Item eventKey='4' icon={<ToolsIcon/>}>
                                <Badge content='10+'>
                                    Open Issues
                                </Badge>
                            </Nav.Item>
                            <Divider/>
                            <Nav.Item eventKey={MachineName.corrugator} icon={<Dashboard/>}>
                                Overview
                            </Nav.Item>
                            <Nav.Menu
                                eventKey='6'
                                trigger='hover'
                                title='Wet End'
                                // icon={<Magic/>}
                                placement='rightStart'
                                style={{fontWeight: 'Bold'}}
                            >
                                {/*<Nav.Item eventKey="wetend">Overview</Nav.Item>*/}

                                <Nav.Menu
                                    eventKey="rs"
                                    trigger="hover"
                                    title="Rollstands"
                                    // icon={<Magic/>}
                                    placement="rightStart"
                                >
                                    <Nav.Item eventKey={MachineName.rs1}>Rollstand 1</Nav.Item>
                                    {wetend.facer >= 1 &&
                                        <Nav.Item eventKey={MachineName.rs2}>Rollstand 2</Nav.Item>}
                                    {wetend.facer >= 1 &&
                                        <Nav.Item eventKey={MachineName.rs3}>Rollstand 3</Nav.Item>}
                                    {wetend.facer >= 2 &&
                                        <Nav.Item eventKey={MachineName.rs4}>Rollstand 4</Nav.Item>}
                                    {wetend.facer >= 2 &&
                                        <Nav.Item eventKey={MachineName.rs5}>Rollstand 5</Nav.Item>}
                                    {wetend.facer >= 3 &&
                                        <Nav.Item eventKey={MachineName.rs6}>Rollstand 6</Nav.Item>}
                                    {wetend.facer >= 3 &&
                                        <Nav.Item eventKey={MachineName.rs7}>Rollstand 7</Nav.Item>}
                                </Nav.Menu>
                                <Nav.Menu
                                    eventKey="sp"
                                    trigger="hover"
                                    title="Splicer"
                                    // icon={<Magic/>}
                                    placement="rightStart"
                                >
                                    <Nav.Item eventKey={MachineName.sp1}>Splicer 1</Nav.Item>
                                    {wetend.facer >= 1 &&
                                        <Nav.Item eventKey={MachineName.sp2}>Splicer 2</Nav.Item>}
                                    {wetend.facer >= 1 &&
                                        <Nav.Item eventKey={MachineName.sp3}>Splicer 3</Nav.Item>}
                                    {wetend.facer >= 2 &&
                                        <Nav.Item eventKey={MachineName.sp4}>Splicer 4</Nav.Item>}
                                    {wetend.facer >= 2 &&
                                        <Nav.Item eventKey={MachineName.sp5}>Splicer 5</Nav.Item>}
                                    {wetend.facer >= 3 &&
                                        <Nav.Item eventKey={MachineName.sp6}>Splicer 6</Nav.Item>}
                                    {wetend.facer >= 3 &&
                                        <Nav.Item eventKey={MachineName.sp7}>Splicer 7</Nav.Item>}
                                </Nav.Menu>
                                <Nav.Menu
                                    eventKey="facer"
                                    trigger="hover"
                                    title="Facer"
                                    // icon={<Magic/>}
                                    placement="rightStart"
                                >
                                    {wetend.facer >= 1 &&
                                        <Nav.Item eventKey={MachineName.facer1}>Facer 1</Nav.Item>}
                                    {wetend.facer >= 2 &&
                                        <Nav.Item eventKey={MachineName.facer2}>Facer 2</Nav.Item>}
                                    {wetend.facer >= 3 &&
                                        <Nav.Item eventKey={MachineName.facer3}>Facer 3</Nav.Item>}
                                </Nav.Menu>
                                {wetend.blc &&
                                    <Nav.Item eventKey={MachineName.blc}>BLC / ZDS</Nav.Item>}
                                {wetend.webtrol &&
                                    <Nav.Item eventKey={MachineName.webtrol}>Webtrol</Nav.Item>}
                                {wetend.gu &&
                                    <Nav.Item eventKey={MachineName.gu}>Glue Unit / PH</Nav.Item>}
                                {wetend.df &&
                                    <Nav.Item eventKey={MachineName.df}>Double Facer</Nav.Item>}
                            </Nav.Menu>
                            {dryend.com &&
                                <Nav.Item eventKey={MachineName.com}>COM Controller</Nav.Item>}
                            <Nav.Menu
                                eventKey='7'
                                trigger='hover'
                                title='Dry End'
                                placement='rightStart'
                                style={{fontWeight: 'Bold'}}
                            >
                                {/* I just like to render if object exist AND more than 3 elements in corrObj.map*/}
                                {/*<Nav.Item eventKey="dryend">Overview</Nav.Item>*/}
                                <Nav.Item eventKey={MachineName.ccd}>CCD</Nav.Item>
                                {dryend.kq &&
                                    <Nav.Item eventKey={MachineName.kq}>KQ</Nav.Item>}
                                {dryend.hb1 &&
                                    <Nav.Item eventKey={MachineName.hb1}>H-Blade 1</Nav.Item>}
                                {dryend.hb2 &&
                                    <Nav.Item eventKey={MachineName.hb2}>H-Blade 2</Nav.Item>}
                                {dryend.sr &&
                                    <Nav.Item eventKey={MachineName.sr}>SR</Nav.Item>}
                                {dryend.aoc &&
                                    <Nav.Item eventKey={MachineName.aoc}>AOC</Nav.Item>}
                                {dryend.bdc &&
                                    <Nav.Item eventKey={MachineName.bdc}>BDC</Nav.Item>}
                                {dryend.kqf &&
                                    <Nav.Item eventKey={MachineName.kqf}>KQF</Nav.Item>}
                                {dryend.hq &&
                                    <Nav.Item eventKey={MachineName.hq}>HQ / CMC</Nav.Item>}
                                {dryend.asm &&
                                    <Nav.Item eventKey={MachineName.asm}>AS</Nav.Item>}
                            </Nav.Menu>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>

                <NavToggle expand={expandSidebar} onChange={handleSideBar}/>
            </Sidebar>
        </div>
    );
};

CorrugatorSideBar.propTypes = {
    active: PropTypes.string,
    onSelect: PropTypes.func,
};

export default CorrugatorSideBar;
