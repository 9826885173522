import {Button, Container, FlexboxGrid, Form, InputGroup, Message, Panel, useToaster} from "rsuite";
import React, {useEffect, useRef} from "react";
import {useState} from "react";
import {useUserAuth} from "./UserAuthContext";
import {useNavigate} from "react-router-dom";
import RevoverPasswordValidation from "../../Validation/RevoverPasswordValidation";
import FormControl from "rsuite/FormControl";

const RecoverPasswordPage = () => {
    console.log('RENDER RecoverPasswordPage');
    const formRef = useRef();
    const emailRef = useRef();
    const [formValue, setFormValue] = useState({}); // for field values
    const [formError, setFormError] = useState({}); // for field error messages
    //const [email, setEmail] = useState('');


    const {recoverPassword} = useUserAuth();
    const navigate = useNavigate();
    const toaster = useToaster();

    // set Focus to email at the moment component gets mounted
    useEffect(() => {
        console.log(emailRef.current);
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formRef.current.check()) {
            console.error('RECOVERY PASSWORD -> something missing');
            return;
        }

        try {
            await recoverPassword(formValue.email);
            toaster.push(
                <Message className='rs-theme-dark' showIcon type="success" header='Success'>
                    ...we will send instruction to the email.
                </Message>
            );
            navigate("/");
        } catch (err) {
            toaster.push(
                <Message className='rs-theme-dark' showIcon type="error" header='Ups... something went wrong!'>
                    {err.message}
                </Message>
            );
        }
    };

    return (
        <Container style={{marginTop: '6rem'}}>
            <FlexboxGrid justify="center">
                <FlexboxGrid.Item colspan={10}>
                    <Panel header={<h3>Recover Password</h3>} bordered>
                        <Form
                            layout='horizontal'
                            model={RevoverPasswordValidation}
                            ref={formRef}
                            onChange={setFormValue}
                            onCheck={setFormError}
                            formError={formError}
                            formDefaultValue={formValue}
                        >
                            <Form.Group>
                                <Form.ControlLabel style={{width: '4.5rem'}}>Email:</Form.ControlLabel>
                                <InputGroup inside size='md' style={{width: '15.63rem'}}>
                                    <FormControl type='email' name='email'/>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group>
                                <Button type='submit' appearance="primary" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Form.Group>

                        </Form>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
};

export default RecoverPasswordPage;
