
export const FileUploadStruct = {
    id: '',
    changereason: '',
    description: '',
    user: '',
    filename: '',
    refcollection: '',
    refmachine: '',
    refcategory: '',
    timestamp: 0,
    version: 0,
    version1: '',
    storagepath: ''
};
