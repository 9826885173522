import {configureStore} from "@reduxjs/toolkit";
import corrObjSlice from '../Reducers/CorrugatorSlice'
import uploadSlice from "../Components/UploadSlice";
import knowledgeSlice from "../Components/Knowledge/KnowledgeSlice";
import userAuthSlice from "../Pages/Authentication/UserAuthSlice";
import timesheetSlice from "../Components/Timesheets/TimesheetSlice";

export const stateStore = configureStore({
    reducer: {
        userAuthReducer: userAuthSlice,
        corrObjReducer: corrObjSlice,
        uploadReducer: uploadSlice,
        knowledgeReducer: knowledgeSlice,
        timesheetReducer: timesheetSlice,
    },
});


