import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getActiveMachine, getCorrId, UPDATE_MACHINE_GENERAL} from "../../../../Reducers/CorrugatorSlice";
import {SelectorMachineGeneral} from "../../Selector/SelectorMachineGeneral";
import {Button, Input} from "rsuite";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {cloneDeep} from "lodash";
import {useImmer} from "use-immer";

const InfoContent = () => {
    const dispatch = useDispatch();
    const corrId = useSelector(getCorrId);
    const machineFilter = useSelector(getActiveMachine);
    const genObjTemp = cloneDeep(SelectorMachineGeneral(machineFilter));

    // just in case the propertie doesn't exist
    if (genObjTemp.info === undefined) {
        genObjTemp['info'] = '';
    }

    const [genObj, updateGenObj] = useImmer(genObjTemp);

    const handleSave = async () => {
        const tmp = [machineFilter, '.', 'general'].join('');
        await CorrugatorDataServices.updateCorrugator(corrId, {[tmp]: genObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_MACHINE_GENERAL({machineIdStr: machineFilter, obj: genObj}));
        }).catch(error => {
            throw new Error(`GENERAL ${machineFilter} OPTIONS-INFO WRITE FUCKED UP -->`, error);
        });
    };

    function updateInfo(info) {
        updateGenObj(draft => {
            draft.info = info;
        });
    }

    return (
        <div>
            <Input
                id='info' //id must match the name of the property field
                as="textarea"
                value={genObj.info}
                rows={12}
                placeholder="Important Info to this machine..."
                onChange={value => {
                    updateInfo(value);
                }}
            />
            <Button
                style={{marginTop: 8}}
                onClick={handleSave}
            >
                Save
            </Button>
        </div>
    )
};

export default InfoContent;