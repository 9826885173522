import React, {useEffect, useState} from "react";
import {Button, ButtonToolbar, InputPicker, Modal, Toggle, Tooltip, Whisper} from "rsuite";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {dataRollSet, dataSteamShower, dataSteamSupply} from "../../../../Resources/InputFormData";
import {cloneDeep, isEqual} from "lodash";
import {SelectorFacerOption} from "../../Selector/SelectorFacerOption";
import MotorEditCard from "../MotorEditCard";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrId, TOGGLE_DLG, UPDATE_FACER_OPTIONS} from "../../../../Reducers/CorrugatorSlice";

const FacerEditOptionsDlg = ({facerId, open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const options = SelectorFacerOption(facerId);
    let tempState = cloneDeep(options);
    const [optObj, setOptObj] = useState(options);
    // console.log(`Edit-FACER${facerId}-DLG options`, options);
    // console.log(`Edit-FACER${facerId}-DLG optObj`, optObj);

    useEffect(() => {
        tempState = cloneDeep(options);
        setOptObj(options);
    }, [facerId])

    const handleSaveDlg = async () => {
        const tmp = ['facer', facerId.toString(), '.', 'options'].join('');
        await CorrugatorDataServices.updateCorrugator(corrId, {[tmp]: optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_FACER_OPTIONS({id: facerId, obj: optObj}));
            handleCloseDlg();
        }).catch(error => {
            throw new Error(`FACER${facerId} OPTIONS WRITE FUCKED UP -->`, error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            className={'rs-theme-dark'}
            open={open}
            size={'md'}
            style={{marginTop: '4%'}}
            overflow={true}
            backdrop={'static'}
            autoFocus={true}
            enforceFocus={true}
            onClose={handleCloseDlg}
        >
            <Modal.Header>
                <Modal.Title>Facer {facerId} - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={cardStyle}>
                        <MotorEditCard optObj={optObj} setOptObj={setOptObj}/>
                        <hr style={hrStyle}/>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Tension Roll</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={optObj.tensionroll}
                                onChange={checked => setOptObj({...optObj, tensionroll: checked})}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Brake Roll Medium</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={optObj.brakerollmedium}
                                onChange={checked => setOptObj({...optObj, brakerollmedium: checked})}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Brake Roll Liner</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={optObj.brakerollliner}
                                onChange={checked => setOptObj({...optObj, brakerollliner: checked})}
                            />
                        </div>
                    </div>
                    <div style={cardStyle}>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Corrugator Roll 1</div>
                            <InputPicker
                                data={dataRollSet}
                                id={'rollset1'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.rollset1}
                                onSelect={(value) => setOptObj({...optObj, rollset1: value})}
                                style={{width: 130}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Corrugator Roll 2</div>
                            <InputPicker
                                data={dataRollSet}
                                id={'rollset2'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.rollset2}
                                onSelect={(value) => setOptObj({...optObj, rollset2: value})}
                                style={{width: 130}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Corrugator Roll 3</div>
                            <InputPicker
                                data={dataRollSet}
                                id={'rollset3'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.rollset3}
                                onSelect={(value) => setOptObj({...optObj, rollset3: value})}
                                style={{width: 130}}
                            />
                        </div>
                        <hr style={hrStyle}/>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Steam Control:</div>
                            <InputPicker
                                data={dataSteamSupply}
                                id={'steamcontrol'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.steamcontrol}
                                onSelect={(value) => setOptObj({...optObj, steamcontrol: value})}
                                style={{width: 130}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Steam Shower</div>
                            <InputPicker
                                data={dataSteamShower}
                                id={'steamshower'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.steamshower}
                                onSelect={(value) => setOptObj({...optObj, steamshower: value})}
                                style={{width: 130}}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Soft touch</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={optObj.softtouch}
                                onChange={checked => setOptObj({...optObj, softtouch: checked})}
                            />
                        </div>
                        <div style={rowStyle}>
                            <div style={labelStyle}>Temperature control</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={optObj.tempcontrol}
                                onChange={checked => setOptObj({...optObj, tempcontrol: checked})}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Whisper trigger='hover' placement='top' speaker={<Tooltip>I hope you <b>know</b> what you are doing!</Tooltip>}>
                        <Button disabled={isEqual(tempState, optObj)} onClick={handleSaveDlg}>Save</Button>
                    </Whisper>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

FacerEditOptionsDlg.propTypes = {
    facerId: PropTypes.number,
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default FacerEditOptionsDlg;
