import {createContext, useContext, useEffect, useState} from "react";
import {
    createUserWithEmailAndPassword, signInWithEmailAndPassword,
    onAuthStateChanged, signOut,
    sendPasswordResetEmail, updateProfile
} from "firebase/auth";
import {auth} from "../../firebase_config";
import {useDispatch} from "react-redux";
import {LOGIN} from "./UserAuthSlice";

const userAuthContext = createContext();

export function UserAuthContextProvider({children}) {
    // console.log('CALL of UserAuthContextProvider');
    const [user, setUser] = useState({});
    const dispatch = useDispatch();

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function changeDisplayName(name) {
        return updateProfile(auth.currentUser, {displayName: name})
    }

    // TODO: Recover operation
    function recoverPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    function logOut() {
        return signOut(auth);
    }

    // runs only once at the moment the component gets mounted
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            // console.log("Auth", currentuser);
            setUser(currentuser);
            dispatch(LOGIN({
                email: currentuser.email,
                uid: currentuser.uid,
                displayName: currentuser.displayName,
            }));
        });
        // if unmounted then cleanup -> unsubscripe
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <userAuthContext.Provider value={{user, logIn, signUp, logOut, recoverPassword, changeDisplayName}}>
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}
