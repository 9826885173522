import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import WebOptionContent from "./WebtrolOptionContent";

const WebtrolContent = () => {
    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<WebOptionContent/>}/>
        </Panel>
    );
};

export default WebtrolContent;
