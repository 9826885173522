import {Button, ButtonToolbar, Checkbox, DateRangePicker, SelectPicker} from "rsuite";
import {endOfMonth, getTime, startOfMonth} from "date-fns";
import {CorrugatorDocuments} from "../../TestData/CorrugatorDocuments";
import {clone, cloneDeep} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {
    ASYNC_FETCH_TIMESHEET_LIST,
    getTimesheetFetchListStatus,
    SET_TIMESHEET_FETCH_LIST_STATUS,
    TIMESHEET_TOGGLE_DLG
} from "./TimesheetSlice";
import {useState} from "react";
import {TimesheetStruct} from "../../InitialDataStructures/Timesheets/Timesheet";
import {v4} from "uuid";
import {useUserAuth} from "../../Pages/Authentication/UserAuthContext";
import {GetMachineNameForTimeSheet, str_All, str_idle} from "../../Helpers/GeneralHelpers";


const TimesheetHeader = ({selectedUser}) => {
    const dispatch = useDispatch();
    const plants = clone(CorrugatorDocuments);
    const machineNames = GetMachineNameForTimeSheet();
    const timeSheetStatus = useSelector(getTimesheetFetchListStatus);

    const {user} = useUserAuth();

    plants.unshift(str_All);
    machineNames.unshift(str_All);
    const today = new Date();

    const [dateRange, setDateRange] = useState([startOfMonth(today), endOfMonth(today)])
    const [facility, setFacility] = useState(null);
    const [machine, setMachine] = useState(null);
    const [remote, setRemote] = useState(false);

    const canGet = [dateRange, facility, machine].every(Boolean);

    const handleRemoteCheckBox = (checked) => {
        // console.log('DATERANGE', canGet);
        // console.log('REMOTE-SELECT ->', checked)
        setRemote(checked);
    }

    const handleGetItOnClick = async () => {
        if (timeSheetStatus === str_idle) {
            try {
                // console.log(`GET-IT user: ${user} , plant: ${facility}, machine: ${machine} remote: ${remote}`);
                await dispatch(ASYNC_FETCH_TIMESHEET_LIST({
                    user: selectedUser,
                    starttime: getTime(dateRange[0]),
                    endtime: getTime(dateRange[1]),
                    facility: facility,
                    machine: machine,
                    remote: remote
                })).unwrap();
            } catch (error) {
                throw new Error('TimesheetHeader -> handleGetItOnClick', error);
            } finally {
                dispatch(SET_TIMESHEET_FETCH_LIST_STATUS(str_idle))
            }
        }
    }

    const handleNewTimeSheetOnClick = () => {
        // get a new time sheet
        const new_docData = cloneDeep(TimesheetStruct);
        // TODO: I cant use Object.create here because it is not serializable -> Prototype is the problem
        //const new_docData = Object.create(TimesheetStruct);
        // console.log('TimeSheetHeader NEW -> ', new_docData); // watch for Prototype in console
        new_docData.user = user.displayName;
        // create a time sheet object
        const new_TimeSheetObj = {id: v4(), docData: new_docData}
        // console.log('TimeSheetHeader NEW -> ', new_TimeSheetObj);
        dispatch(TIMESHEET_TOGGLE_DLG({open: true, timeSheet: new_TimeSheetObj, mode: 'NEW'}));
    }

    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'row', padding: 10}}>
                <h5 style={{margin: 10}}>Time sheets for {selectedUser}</h5>
                <ButtonToolbar style={{paddingLeft: 20}}>
                    <Button onClick={handleNewTimeSheetOnClick}>New Time sheet</Button>
                </ButtonToolbar>
            </div>
            <div style={{display: "flex", gap: 8, paddingLeft: 10, paddingTop: 15, alignItems: 'center'}}>
                <h5>Search for: </h5>
                <DateRangePicker
                    format="yyyy-MM-dd HH:mm"
                    isoWeek={true}
                    showWeekNumbers={true}
                    hideMinutes={minute => minute % 30 !== 0}
                    //defaultCalendarValue={[firstOfMonth, lastOfMonth]}
                    //defaultValue={dateRange}
                    onChange={setDateRange}
                    value={dateRange}
                    style={{width: 325}}
                />
                <SelectPicker
                    // label='Plant'
                    placeholder="Plant"
                    data={plants.map(item => ({label: item, value: item}))}
                    defaultValue={str_All}
                    cleanable
                    style={{width: 180}}
                    onChange={setFacility}
                    value={facility}
                />
                <SelectPicker
                    // label='Machine'
                    placeholder="Machine"
                    data={machineNames.map(item => ({label: item.toUpperCase(), value: item}))}
                    defaultValue={str_All}
                    style={{width: 150}}
                    onChange={setMachine}
                    value={machine}
                />
                <Checkbox
                    checked={remote}
                    onChange={(value, checked) => handleRemoteCheckBox(checked)}>
                    Online Support
                </Checkbox>
                <ButtonToolbar style={{paddingLeft: 30}}>
                    <Button
                        onClick={handleGetItOnClick}
                        disabled={(timeSheetStatus !== str_idle) || !canGet}
                    >
                        Get the list
                    </Button>
                </ButtonToolbar>
            </div>
        </div>
    )
};

export default TimesheetHeader;