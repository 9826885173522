import {address} from "./Address";
import {hotels} from "./Hotels";
import {contacts} from "./Contacts";
import {facer} from "../Machine/Facer/Facer";
import {asm} from "../Machine/ASM/ASM";
import {hq} from "../Machine/HQ/HQ";
import {WetEndStruct} from "../Machine/WetEndStruct";
import {DryEndStruct} from "../Machine/DryEndStruct";
import {sr} from "../Machine/SR/SR";
import {kq} from "../Machine/KQ/KQ";
import {DF} from "../Machine/DF/DF";
import {sp} from "../Machine/SP/SP";
import {rs} from "../Machine/RS/RS";
import {webtrol} from "../Machine/Webtrol/Webtrol";
import {gu} from "../Machine/GU/GU";
import {bdc} from "../Machine/BDC/BDC";
import {aoc} from "../Machine/AOC/AOC";
import {blc} from "../Machine/BLC/BLC";
import {hb} from "../Machine/HB/HB";
import {kqf} from "../Machine/KQF/KQF";
import {com} from "../Machine/COM/COM";
import {corrbase} from "./CorrugatorBase";
import {ccd} from "../Machine/CCD/CCD";

export const CorrugatorStruct = {
    corrbase: corrbase,
    address: address,
    contacts: contacts,
    hotels: hotels,
    wetend: WetEndStruct,
    dryend: DryEndStruct,
    facer1 : facer,
    facer2 : facer,
    facer3 : facer,
    sp1 : sp,
    sp2 : sp,
    sp3 : sp,
    sp4 : sp,
    sp5 : sp,
    sp6 : sp,
    sp7 : sp,
    rs1 : rs,
    rs2 : rs,
    rs3 : rs,
    rs4 : rs,
    rs5 : rs,
    rs6 : rs,
    rs7 : rs,
    blc: blc,
    webtrol: webtrol,
    gu: gu,
    df: DF,
    aoc: aoc,
    kq: kq,
    hb1: hb,
    hb2: hb,
    sr: sr,
    bdc: bdc,
    kqf: kqf,
    hq: hq,
    asm: asm,
    com: com,
    ccd: ccd,
}
