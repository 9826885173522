export const MachineTagList = [
    'MF',
    'BBR',
    'BR',
    'HQ',
    'SR',
    'AS'
].map(
    item => ({
        label: item,
        value: item,
    })
);