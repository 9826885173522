import {Tooltip, Whisper} from "rsuite";
import {SelectorMachineGeneral} from "./Selector/SelectorMachineGeneral";
import {getActiveMachine} from "../../Reducers/CorrugatorSlice";
import {useSelector} from "react-redux";

const labelStyle = {
    marginInline: '1rem',
    fontsize: 8
};

const spanStyle = {
    color: 'var(--rs-btn-primary-text)'
};

//const MachineHeader = ({machineName}) => {
const MachineHeader = () => {
    const machineName = useSelector(getActiveMachine);
    const genObj = SelectorMachineGeneral(machineName);
    // console.log('GeneralContent GenObj', genObj);
    return (
        <div>
            <div style={{marginBottom: '1rem'}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{fontSize: 24, color: 'var(--rs-btn-link-text)'}}>{machineName.toUpperCase()}</div>
                        <div style={{fontSize: 24, color: 'var(--rs-btn-primary-text)'}}>{genObj.machinename}</div>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", marginInlineStart: '2.5rem'}}>

                        <div style={{...labelStyle, display: 'flex', alignItems: "center"}}><span style={spanStyle}>{genObj.manufacturer}</span></div>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={labelStyle}>Width</div>
                            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{(genObj.width / 25.4).toFixed()} "</Tooltip>}>
                                <div><span style={spanStyle}>{genObj.width}</span> mm</div>
                            </Whisper>
                        </div>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={labelStyle}>Max-Speed</div>
                            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{(genObj.speed * 3.281).toFixed()} ft/min</Tooltip>}>
                                <div><span style={spanStyle}>{genObj.speed}</span> m/min</div>
                            </Whisper>
                        </div>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={labelStyle}>IP-Address</div>
                            <div><span style={spanStyle}>{genObj.ipaddressplc}</span></div>
                        </div>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={labelStyle}>PLC-Type</div>
                            <div><span style={spanStyle}>{genObj.plctype}</span></div>
                        </div>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={labelStyle}>Built</div>
                            <div><span style={spanStyle}>{genObj.built}</span></div>
                        </div>

                    </div>
                </div>
                <hr style={{marginTop: '1rem', marginBottom: '1rem'}}/>
            </div>
        </div>
    );
};

export default MachineHeader;
