import {collection, deleteDoc, doc, getDocs, query, setDoc, where} from "firebase/firestore";
import {db} from "../firebase_config";

const knowledgeDbName = 'knowledges';
const knowledgeCollectionRef = collection(db, knowledgeDbName);

class KnowledgeDataService {
    getFilteredKnowledgeList = ({machinetags, mechanic}) => {
        console.log(`getFilteredKnowledgeList -> Tags: ${machinetags} - Mechanic: ${mechanic}`);

        let query_Conditions = [];

        // When mMchanicFilter === false -> no Query conditions === get all Docs
        if (mechanic !== undefined && mechanic !== false) {
            // console.log('getFilteredKnowledgeList -> Explicit Mechanic selected');
            query_Conditions.push(where('mechanic', '==', mechanic));
        }

        // When machinetagsFilter === 0 -> no Query conditions === get all Docs
        if (machinetags !== undefined && machinetags.length !== 0) {
            // console.log('getFilteredKnowledgeList -> Explicit machinetags selected');
            query_Conditions.push(where('machinetags', '==', machinetags));
        }

        // query_Conditions.push(where('starttime', '>=', starttime));
        // query_Conditions.push(where('starttime', '<=', endtime));

        // console.log('getFilteredKnowledgeList Query collection-> ', query_Conditions);

        const docQuery = query(knowledgeCollectionRef,
            ...query_Conditions
        );
        return getDocs(docQuery);
    }

    addKnowledgeDocument = (knowledgeObject) => {
        console.log('SERVICE NEW_KNOWLEDGE_DOC --> id', knowledgeObject.id);
        console.log('SERVICE NEW_KNOWLEDGE_DOC --> docData', knowledgeObject.docData);
        try {
            return setDoc(doc(db, knowledgeDbName, knowledgeObject.id), knowledgeObject.docData);
        } catch (error) {
            console.log('SERVICE NEW_KNOWLEDGE_DOC FAILED', error);
        }
    };

    updateKnowledgeDocument = (knowledgeObject) => {
        // console.log('SERVICE UPDATE_KNOWLEDGE_DOC --> id', knowledgeObject.id);
        // console.log('SERVICE UPDATE_KNOWLEDGE_DOC --> docData', knowledgeObject.docData);
        try {
            return setDoc(doc(db, knowledgeDbName, knowledgeObject.id), knowledgeObject.docData);
        } catch (error) {
            console.log('SERVICE ERROR', error);
        }
    };

    deleteKnowledgeDocument = (knowledge_id) => {
        // console.log('SERVICE DELETE_KNOWLEDGE_DOC --> id', knowledge_id);
        const existingKnowledgeDoc = doc(db, knowledgeDbName, knowledge_id);
        if (existingKnowledgeDoc) {
            return deleteDoc(existingKnowledgeDoc);
        }
    };

    saveDocToStorage = (docFile) => {
        try {
            return null;
        } catch (error) {
            console.log('SERVICE ERROR saveDocToStorage', error);
        }
    }
}

export default new KnowledgeDataService();