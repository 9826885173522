import {Schema} from "rsuite";

const {StringType} = Schema.Types;


export const NewCorrugatorIdValidation = Schema.Model({
    location: StringType()
        .isRequired('Location is required.')
        .minLength(3, "Can't be less than 3 characters"),
    codename: StringType()
        .isRequired("Code name is required")
        .minLength(3, "Can't be less than 3 characters")
});


export const CorrugatorValidation = Schema.Model({});