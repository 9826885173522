import {Schema} from "rsuite";

//const {StringType, NumberType} = Schema.Types;
const {StringType} = Schema.Types;
const HotelValidation = Schema.Model({
    name: StringType()
        .isRequired('Is required.')
        .minLength(4, "Can't be less than 4 characters")
        .maxLength(50, 'Cannot be greater than 50 characters'),
    street: StringType()
        .isRequired('This Street is required.')
        .minLength(4, "Can't be less than 4 characters")
        .maxLength(50, 'Cannot be greater than 50 characters'),
    city: StringType()
        .isRequired("City is required")
        .minLength(3, "Can't be less than 3 characters")
        .maxLength(30, 'Cannot be greater than 30 characters'),
});

export default HotelValidation;
