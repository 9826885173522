import {getCorrObj} from "../../../Reducers/CorrugatorSlice";
import {useSelector} from "react-redux";

export const SelectorHB = (hbId) => {
    const corrObj = useSelector(getCorrObj);
    console.log('Selector HB', hbId, corrObj.hb1);

    switch (hbId) {
        case 1:
            return corrObj.hb1;
        case 2:
            return corrObj.hb2;
        default:
            throw new Error('Unhandled SelectorHyperblade type');
    }
};
