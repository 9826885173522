import {Navigate} from 'react-router-dom';
import {useUserAuth} from './Authentication/UserAuthContext';

const ProtectedRoute = ({children}) => {
    const {user} = useUserAuth();

    if (!user) {
        return <Navigate to='/'/>; //navigate to Login
    }
    return children;
};

export default ProtectedRoute;
