import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import KQOptionContent from "./KQOptionContent";

const KQContent = () => {

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<KQOptionContent/>}/>
        </Panel>
    );
};
export default KQContent;
