export const FacerOptions = {
    brakerollliner: false,
    brakerollmedium: false,
    mainmotordrive: '',
    mainmotorpower: 0,
    mainmotortype: '',
    motorcooling: false,
    rollset1: '',
    rollset2: '',
    rollset3: '',
    softtouch: false,
    steamcontrol: '',
    steamshower: 0,          // 0 = no  1= Bottom or  2 =Top Bottom  -> never Top
    tempcontrol: false,
    tensionroll: false,
}
