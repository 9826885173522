import React, {useState} from "react";
import {CorrugatorTab} from "../../Resources/StringConstants";
import FileTable from "../FileTable";
import {Container, Content, Header} from "rsuite";
import CorrugatorHeaderNavBar from "./CorrugatorHeaderNavBar";
import CorrContactContent from "./CorrContent/Contact/CorrContactContent";
import CorrHotelContent from "./CorrContent/Hotel/CorrHotelContent";
import CorrGeneralContent from "./CorrContent/General/CorrGeneralContent";
import CorrIPAdressContent from "./CorrContent/IPAddress/CorrIPAdressContent";
import CorrVisuContent from "./CorrContent/Visu/CorrVisuContent";
import {useDispatch, useSelector} from "react-redux";
import {getVariousDocs, SET_ACTIVE_CATEGORY} from "../../Reducers/CorrugatorSlice";

const CorrugatorPanel = () => {
    const dispatch = useDispatch();
    const variousList = useSelector(getVariousDocs);
    const [active, setActive] = useState('general');

    const contentSwitch = () => {
        switch (active) {
            case CorrugatorTab.contact:
                return <CorrContactContent/>;
            case CorrugatorTab.hotel:
                return <CorrHotelContent/>;
            case CorrugatorTab.general:
                return <CorrGeneralContent/>;
            case CorrugatorTab.ipaddress:
                return <CorrIPAdressContent/>;
            case CorrugatorTab.visu:
                return <CorrVisuContent/>
            case CorrugatorTab.various:
                console.log("File List Corr various", variousList);
                return <FileTable fileList={variousList}/>;
            default:
                return <h3>Holy Shit...what you did??</h3>
        }
    };

    const handleOnSelectNavBar = (eventkey) => {
        setActive(eventkey);
        dispatch(SET_ACTIVE_CATEGORY(eventkey));
    };

    const containerStyle = {
        marginBottom: '3.13rem',
        marginRight: '3.13rem',
        marginLeft: '3.13rem'
    };

    const contentStyle = {
        marginTop: '1.25rem',
        marginLeft: '1.25rem',
        marginRight: '1.25rem'
    };

    return(
        <Container style={containerStyle}>
            <Header>
                <CorrugatorHeaderNavBar
                    onSelect={handleOnSelectNavBar}
                    active={active}
                    variousCount={variousList.length}
                />
            </Header>
            <Content style={contentStyle}>
                {contentSwitch()}
            </Content>
        </Container>
    );
};

export default CorrugatorPanel;
