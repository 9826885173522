import React, {useState} from "react";
import {Button, ButtonToolbar, Input, InputNumber, InputPicker, Modal, Toggle} from "rsuite";
import {cardStyle, labelStyle} from "../../../../Styles/ComponentStyles";
import {dataCorrLine, dataLevel, dataWidth} from "../../../../Resources/InputFormData";
import {cloneDeep, isEqual} from "lodash";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {TOGGLE_CORR_GENERAL_DLG, UPDATE_CORR_BASE} from "../../../../Reducers/CorrugatorSlice";

const CorrEditGeneral = ({open, dispatch}) => {
    const corrObj = useSelector((state) => state.corrObjReducer.corrObj);
    const corrId = useSelector((state) => state.corrObjReducer.corrId);
    const tempState = cloneDeep(corrObj.corrbase);
    const [editCorrbase, setEditCorrbase] = useState(cloneDeep(corrObj.corrbase));
    console.log('CORR-EDIT-GENERAL --> corrbase', corrObj.corrbase);
    console.log('CORR-EDIT-GENERAL --> tempState', tempState);

    const handleSaveDlg = () => {
        CorrugatorDataServices.updateCorrugator(corrId, {'corrbase': editCorrbase}).then((response) => {
            console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_CORR_BASE(editCorrbase));
            handleCloseDlg();
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_CORR_GENERAL_DLG(false));
    };

    const handleInputChanged = (value, e) => {
        console.log('Field ID:', e.target.id);
        // id is here the obj property name!!!!!
        setEditCorrbase({...editCorrbase, [e.target.id]: value});
    };

    const rowStyle = {
        marginTop: '0.38rem',
        marginBottom: '0.38rem',
        display: 'flex',
        alignItems: 'center'
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>General Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    {/*Line Type*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Line Type</div>
                        <InputPicker
                            data={dataCorrLine}
                            id={'level'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={editCorrbase.type}
                            onSelect={(value) => setEditCorrbase({...editCorrbase, type: value})}
                            style={{width: '10.3rem'}}
                        />
                    </div>
                    {/*Wet End*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Wet-End</div>
                        <InputPicker
                            data={dataLevel}
                            id={'wetendtype'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={editCorrbase.wetendtype}
                            onSelect={(value) => setEditCorrbase({...editCorrbase, wetendtype: value})}
                            style={{width: '10.3rem'}}
                        />
                    </div>
                    {/*Dry End*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Dry-End</div>
                        <InputPicker
                            data={dataLevel}
                            id={'dryendtype'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={editCorrbase.dryendtype}
                            onSelect={(value) => setEditCorrbase({...editCorrbase, dryendtype: value})}
                            style={{width: '10.3rem'}}
                        />
                    </div>
                    {/*Built*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Year of Built</div>
                        <InputNumber
                            id='built'
                            value={editCorrbase.built}
                            onChange={(value) => setEditCorrbase({...editCorrbase, built: Number(value)})}
                            min={1990}
                            max={2050}
                            style={{width: '10.3rem'}}
                        />
                    </div>
                    {/*Startup*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Year of Startup</div>
                        <InputNumber
                            id='startup'
                            value={editCorrbase.startup}
                            onChange={(value) => setEditCorrbase({...editCorrbase, startup: Number(value)})}
                            min={1990}
                            max={2050}
                            style={{width: '10.3rem'}}
                        />
                    </div>
                    {/*Width*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Width</div>
                        <InputPicker
                            id='width'
                            placeholder='Select Width'
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={editCorrbase.width}
                            onSelect={(value) => setEditCorrbase({...editCorrbase, width: value})}
                            data={dataWidth}
                            style={{width: '10.3rem'}}
                        />
                    </div>
                    {/*Speed*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Max-Speed</div>
                        <InputNumber
                            id='speed'
                            placeholder='Speed'
                            value={editCorrbase.speed}
                            onChange={(value) => setEditCorrbase({...editCorrbase, speed: Number(value)})}
                            max={500}
                            postfix={'m/min'}
                            style={{width: '10.3rem'}}
                        />
                    </div>
                    {/*Drive Side*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Drive Side</div>
                        <Toggle
                            size="md"
                            checkedChildren="Left"
                            unCheckedChildren="Right"
                            checked={editCorrbase.drivesideleft}
                            onChange={checked => setEditCorrbase({...editCorrbase, drivesideleft: checked})}
                        />
                    </div>
                    {/*CCC Protocol*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>CCC Protocol</div>
                        <Input
                            id='cccprotocolversion'
                            placeholder='Protocol Version...'
                            value={editCorrbase.cccprotocolversion}
                            onChange={handleInputChanged}
                            style={{width: '10.3rem'}}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, editCorrbase)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>

        </Modal>
    );
};

export default CorrEditGeneral;
