import PropTypes from "prop-types";
import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import SPOptionContent from "./SPOptionContent";
import {SelectorSP} from "../../Selector/SelectorSP";

const SPContent = ({spId}) => {
    const spObj = SelectorSP(spId);
    // console.log('RENDER Splicer Content -> SPObj: ', spId, spObj);

    const handleUpload = (para) => {
        //remove white space
        para.refmachine = spObj.id.replace(/\s+/g, '').toLowerCase();
        //onUploadClick(para);
    };

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel
                optionComponent={<SPOptionContent spId={spId}/>}
                onUploadClick={(para) => handleUpload(para)}
            />
        </Panel>
    );
};

SPContent.propTypes = {
    spId: PropTypes.number,
};

export default SPContent;
