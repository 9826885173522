import React, {useEffect, useState} from "react";
import {Button, ButtonToolbar, Input, InputNumber, InputPicker, MaskedInput, Modal} from "rsuite";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {dataScreenType, dataVisuAccess, dataVisuType, maskOptions} from "../../../../Resources/InputFormData";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";
import {SelectorMachineGeneral} from "../../Selector/SelectorMachineGeneral";
import {useSelector} from "react-redux";
import {getCorrId, TOGGLE_DLG, UPDATE_VISU_LOCAL, UPDATE_VISU_REMOTE} from "../../../../Reducers/CorrugatorSlice";

const VisuEditDlg = ({machineFilter, open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const {visu1, visu2} = SelectorMachineGeneral(machineFilter);
    // console.log('VISU-EDIT-DLG --> visu1', visu1);
    // console.log('VISU-EDIT-DLG --> visu2', visu2);
    let backupVisu1 = cloneDeep(visu1);
    let backupVisu2 = cloneDeep(visu2);
    const [visuLocalObj, setVisuLocalObj] = useState(visu1);
    const [visuRemoteObj, setVisuRemoteObj] = useState(visu2);

    useEffect(() => {
        setVisuLocalObj(visu1);
        setVisuRemoteObj(visu2);
        backupVisu1 = cloneDeep(visu1);
        backupVisu2 = cloneDeep(visu2);
    }, [machineFilter]);

    const handleSaveDlg = async () => {
        let tmp = [machineFilter, '.', 'general', '.', 'visu1'].join('');
        // console.log('VISU-EDIT-DLG --> tmp', tmp);
        await CorrugatorDataServices.updateCorrugator(corrId, {[tmp]: visuLocalObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_VISU_LOCAL({machineFilter, visuLocalObj}));
        }).catch((error) => {
            throw new Error('Failed to update VISU_LOCAL', error);
        });

        tmp = [machineFilter, '.', 'general', '.', 'visu2'].join('');

        await CorrugatorDataServices.updateCorrugator(corrId, {[tmp]: visuRemoteObj}).then((response) => {
            console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_VISU_REMOTE({machineFilter, visuRemoteObj}));
        }).catch((error) => {
            throw new Error('Failed to Update VISU_REMOTE', error);
        }).finally(() => {
            handleCloseDlg();
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    // console.log('VISU-EDIT-DLG --> visuLocalObj', visuLocalObj);
    // console.log('VISU-EDIT-DLG --> visuRemoteObj', visuRemoteObj);

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='md'
        >
            <Modal.Header>
                <Modal.Title>Visualization</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    <div>Local</div>
                    {/*IP Address*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>IP-Address</div>
                        <MaskedInput
                            id='ipaddressLocal'
                            value={visuLocalObj.ipaddress}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            style={{width: '10rem'}}
                            autoComplete={false}
                            // placeholderChar={'_'}
                            onChange={(value) => setVisuLocalObj({...visuLocalObj, ipaddress: value})}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>
                    <hr style={hrStyle}/>

                    {/*Type of Screen*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Type of Screen</div>
                        <InputPicker
                            data={dataScreenType}
                            id={'screenLocal'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={visuLocalObj.screentype}
                            onSelect={(value) => setVisuLocalObj({...visuLocalObj, screentype: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Type of Visu*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Type of Visu</div>
                        <InputPicker
                            data={dataVisuType}
                            id={'visutypeLocal'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={visuLocalObj.visutype}
                            onSelect={(value) => setVisuLocalObj({...visuLocalObj, visutype: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Access*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Access</div>
                        <InputPicker
                            data={dataVisuAccess}
                            id={'accessLocal'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={visuLocalObj.access}
                            onSelect={(value) => setVisuLocalObj({...visuLocalObj, access: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Username*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Username</div>
                        <Input
                            id='usernameLocal'
                            value={visuLocalObj.username}
                            onChange={value => setVisuLocalObj({...visuLocalObj, username: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Password*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Password</div>
                        <Input
                            id='passwordLocal'
                            value={visuLocalObj.password}
                            onChange={value => setVisuLocalObj({...visuLocalObj, password: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Port*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Port</div>
                        <InputNumber
                            id='portLocal'
                            value={visuLocalObj.port}
                            onChange={(value) => setVisuLocalObj({...visuLocalObj, port: Number(value)})}
                            min={0}
                            max={65535}
                            style={{width: '10rem'}}
                        />
                    </div>
                </div>

                <div style={cardStyle}>
                    <div>Remote</div>
                    {/*IP Address*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>IP-Address</div>
                        <MaskedInput
                            id='ipaddressRemote'
                            value={visuRemoteObj.ipaddress}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            style={{width: '10rem'}}
                            autoComplete={false}
                            autoCorrect={false}
                            // placeholderChar={'_'}
                            onChange={(value) => setVisuRemoteObj({...visuRemoteObj, ipaddress: value})}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>

                    <hr style={hrStyle}/>

                    {/*Type of Screen*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Type of Screen</div>
                        <InputPicker
                            data={dataScreenType}
                            id={'screenRemote'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={visuRemoteObj.screentype}
                            onSelect={(value) => setVisuRemoteObj({...visuRemoteObj, screentype: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Type of Visu*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Type of Visu</div>
                        <InputPicker
                            data={dataVisuType}
                            id={'visutypeRemote'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={visuRemoteObj.visutype}
                            onSelect={(value) => setVisuRemoteObj({...visuRemoteObj, visutype: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Access*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Access</div>
                        <InputPicker
                            data={dataVisuAccess}
                            id={'accessRemote'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={visuRemoteObj.access}
                            onSelect={(value) => setVisuRemoteObj({...visuRemoteObj, access: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Username*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Username</div>
                        <Input
                            id='usernameRemote'
                            value={visuRemoteObj.username}
                            onChange={value => setVisuRemoteObj({...visuRemoteObj, username: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Password*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Password</div>
                        <Input
                            id='passwordRemote'
                            value={visuRemoteObj.password}
                            onChange={value => setVisuRemoteObj({...visuRemoteObj, password: value})}
                            style={{width: '10rem'}}
                        />
                    </div>

                    {/*Port*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Port</div>
                        <InputNumber
                            id='portLocal'
                            value={visuRemoteObj.port}
                            onChange={(value) => setVisuRemoteObj({...visuRemoteObj, port: Number(value)})}
                            min={0}
                            max={65535}
                            style={{width: '10rem'}}
                        />
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={backupVisu1 === visuLocalObj && backupVisu2 === visuRemoteObj} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>

        </Modal>
    );
};

VisuEditDlg.propTypes = {
    machineFilter: PropTypes.string,
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default VisuEditDlg;
