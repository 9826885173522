import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import CCDOptionContent from "./CCDOptionContent";

const CCDContent = () => {
    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<CCDOptionContent/>}/>
        </Panel>
    );
};

export default CCDContent;