import React, {useState} from "react";
import {Button, ButtonToolbar, Modal} from "rsuite";
import {cardStyle, hrStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {cloneDeep, isEqual} from "lodash";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {TOGGLE_CORR_VISU_DLG, UPDATE_CORR_BASE} from "../../../../Reducers/CorrugatorSlice";

const CorrEditVisu = ({open, dispatch}) => {
    const corrObj = useSelector((state) => state.corrObjReducer.corrObj);
    const corrId = useSelector((state) => state.corrObjReducer.corrId);
    const tempState = cloneDeep(corrObj.corrbase);
    const [editCorrbase, setEditCorrbase] = useState(cloneDeep(corrObj.corrbase));
    console.log('CORR-EDIT-VISU --> corrbase', corrObj.corrbase);
    console.log('CORR-EDIT-VISU --> tempState', tempState);

    const handleSaveDlg = async () => {
        await CorrugatorDataServices.updateCorrugator(corrId, {'corrbase': editCorrbase}).then((response) => {
            console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_CORR_BASE(editCorrbase));
            handleCloseDlg();
        }).catch((error) => {
            throw new Error('Failed to update Corr-Base', error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_CORR_VISU_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>Visualizations</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    {/*Number of Levels*/}
                    <div style={rowStyle}>
                    </div>
                    <hr style={hrStyle}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, editCorrbase)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default CorrEditVisu;
