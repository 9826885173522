import React from "react";
import {Button, Tooltip, Whisper} from "rsuite";
import {cardStyle, fieldStyle, hrStyle, labelStyle, rowCardStyle} from "../../../../Styles/ComponentStyles";
import KQFEditOptionsDlg from "./KQFEditOptionsDlg";
import MotorCard from "../MotorCard";
import {ContentRow} from "../../../ContentRow";
import {dataKnifebar, findLabelForKeyInArray} from "../../../../Resources/InputFormData";
import {useDispatch, useSelector} from "react-redux";
import {getCorrKQF, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const KQFOptionContent = () => {
    const dispatch = useDispatch();
    const kqfObj = useSelector(getCorrKQF);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const options = kqfObj.options;

    // console.log('KQF-OPTION-CONETXT options-->', options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <MotorCard optObj={options}/>
                    <hr style={hrStyle}/>
                    <ContentRow label={'Type of Knifebar'}
                                value={findLabelForKeyInArray(dataKnifebar, options.knifebar)}/>
                </div>

                <div style={cardStyle}>
                    <ContentRow label={'Type of Evacuation'} value={options.evacuation}/>
                    <ContentRow label={'Evacuation Belt'} value={options.evacuationbelt === true ? 'Yes' : 'No'}/>
                    <hr style={hrStyle}/>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Cut Speed 1x</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(options.speedcut1x * 3.281).toFixed()} ft/min</Tooltip>}>
                            <div style={fieldStyle}>{options.speedcut1x} m/min</div>
                        </Whisper>
                    </div>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Cut Speed Nx</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(options.speedcutnx * 3.281).toFixed()} ft/min</Tooltip>}>
                            <div style={fieldStyle}>{options.speedcutnx} m/min</div>
                        </Whisper>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <KQFEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default KQFOptionContent;
