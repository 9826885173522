import KnowledgeHeader from "./KnowledgeHeader";
import KnowledgeFileTable from "./KnowledgeFileTable";

const KnowledgeContent = () => {
    return (
        <div>
            <KnowledgeHeader/>
            <KnowledgeFileTable/>
        </div>
    );
};

export default KnowledgeContent;