export const corrbase = {
    built: 1990,
    drivesideleft: false,
    ipaddress: '',
    hostipaddress: '',
    conveyoripaddress: '',
    steamipaddress: '',
    firewallipaddress: '',
    printeripaddress: '',
    targetspeed: false,
    speed: 0,
    startup: 1990,
    width: 0,
    type: '',
    cccprotocolversion: '',
    dryendtype: '',
    wetendtype: ''
}
