import React from "react";
import {Button} from "rsuite";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import DFEditOptionsDlg from "./DFEditOptionsDlg";
import {dataDFLoad, dataSteamSupply, findLabelForKeyInArray} from "../../../../Resources/InputFormData";
import MotorCard from "../MotorCard";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {getCorrDF, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const DFOptionContent = () => {
    const dispatch = useDispatch();
    const dfObj = useSelector(getCorrDF);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const options = dfObj.options;

    // console.log('DF-OPTION-CONETXT options ->', options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <MotorCard optObj={options}/>
                </div>

                <div style={cardStyle}>
                    <ContentRow label={'Peanut Preheater'} value={options.addpreheater === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Brake roll'} value={options.brakeroll === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'No of Hotplates'} value={options.numberplates}/>
                </div>

                <div style={cardStyle}>
                    <ContentRow label={'Temperature control'} value={options.tempcontrol === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Steam Control'}
                                value={findLabelForKeyInArray(dataSteamSupply, options.steamcontrol)}/>
                    <ContentRow label={'Load System'} value={findLabelForKeyInArray(dataDFLoad, options.trolsystem)}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <DFEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default DFOptionContent;
