import React, {useRef, useState, useEffect} from "react";
import {
    Button, ButtonToolbar, Checkbox, Col, Form, InputNumber, Message,
    Modal, Row, SelectPicker, Tooltip, Whisper
} from "rsuite";
import {CorrugatorStruct} from "../../../InitialDataStructures/Corrugator/CorrugatorStruct";
import BusySpinner from "../../BusySpinner";
import {MachineName} from "../../../Resources/StringConstants";
import {headerCardStyle, hrStyle} from "../../../Styles/ComponentStyles";
import {cloneDeep} from "lodash";
import {dataStates} from "../../../Resources/InputFormData";
import {useSelector} from "react-redux";
import {
    getCorrId, getCorrObj,
    RESET_CORR_DOC,
    SET_CORR_ID, SET_LOADING,
    TOGGLE_CORR_BASE_DLG,
    UPDATE_CORR_ADDRESS,
    UPDATE_CORR_OBJ,
    UPDATE_DRY_END,
    UPDATE_WET_END
} from "../../../Reducers/CorrugatorSlice";
import CorrugatorDataServices from "../../../Services/CorrugatorDataServices";
import {NewCorrugatorIdValidation, CorrugatorValidation} from "../../../Validation/NewCorrugatorIdValidation";


const CorrEditDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const corrObj = useSelector(getCorrObj);
    const newCorr = useSelector((state) => state.corrObjReducer.newCorrugator);
    const isLoading = useSelector((state) => state.corrObjReducer.isLoading);
    const [corrData, setCorrData] = useState(corrObj);

    const [formError, setFormError] = useState({}); // for field error messages
    const [formValues, setFormValues] = useState({});
    const [dlgTitle, setDlgTitle] = useState('')
    const [message, setMessage] = useState({error: false, msg: ''});
    const formRef = useRef();

    console.log('CORR-EDIT-DLG -->corrObj', corrObj, newCorr);

    const formNewValue = {
        location: '',
        codename: '',
        street: '',
        city: '',
        zipcode: '',
        state: '',
        geo: {latitude: 0, longitude: 0}
    };

    // is the collection already existing, we can't change location and code name anymore
    const formEditValue = {
        location: '',
        codename: '',
        street: corrObj.address.street,
        city: corrObj.address.city,
        zipcode: corrObj.address.zipcode,
        state: corrObj.address.state,
        geo: {latitude: corrObj.address.geo.latitude, longitude: corrObj.address.geo.longitude}
    };

    useEffect(() => {
        if (newCorr) {
            setFormValues(formNewValue);
            setCorrData(cloneDeep(CorrugatorStruct));
            setDlgTitle('New Plant')
        } else {
            setFormValues(formEditValue);
            setCorrData(corrObj);
            setDlgTitle(corrId);
        }
    }, [open, newCorr]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formRef.current.check()) {
            console.log('New Corrugator Error -> something missing!');
            return;
        }
        dispatch(SET_LOADING(true));

        console.log('SUBMIT-FORMVALUES', formValues);

        if (newCorr) {
            // create DB Name
            formValues.location = formValues.location
                .trim()
                .toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
            const newId = (formValues.location + '-' + formValues.codename.trim().toUpperCase());

            if (newId === undefined || newId === '')
                return;
            corrData.address = formValues;

            console.log('NEW CORRUGATOR --> corrObj', corrData);

            console.log('INSERT CORRUGATOR --> CorrId:', newId);
            console.log('INSERT CORRUGATOR --> WetEnd:', corrData.wetend);
            console.log('INSERT CORRUGATOR --> DryEnd:', corrData.dryend);
            console.log('INSERT CORRUGATOR --> Address:', corrData.address);
            console.log('INSERT CORRUGATOR --> FormValues:', formValues);

            try {
                await CorrugatorDataServices.insertCorrugator(newId, corrData).then(response => {
                    console.log('THEN RETURN VALUE -->', response);
                    // set the new Corrugator Values to corrObj -> insert CorrId to menue
                    dispatch(RESET_CORR_DOC());
                    dispatch(SET_CORR_ID(newId));
                    dispatch(UPDATE_CORR_OBJ(corrData));
                });
            } catch (err) {
                console.log(err);
                setMessage(err);
            } finally {
                dispatch(SET_LOADING(false));
            }
        } else {
            console.log('UPDATE CORRUGATOR --> CorrId:', corrId);
            console.log('UPDATE CORRUGATOR --> WetEnd:', corrData.wetend);
            console.log('UPDATE CORRUGATOR --> DryEnd:', corrData.dryend);
            console.log('UPDATE CORRUGATOR --> Address:', corrData.address);
            console.log('UPDATE CORRUGATOR --> FormValues:', formValues);

            try {
                await CorrugatorDataServices.updateCorrugator(corrId, {'wetend': corrData.wetend}).then(response => {
                    console.log('THEN RETURN VALUE -->', response);
                    CorrugatorDataServices.updateCorrugator(corrId, {'dryend': corrData.dryend}).then(response => {
                        console.log('THEN RETURN VALUE -->', response);
                        CorrugatorDataServices.updateCorrugator(corrId, {'address': formValues}).then(response => {
                            console.log('THEN RETURN VALUE -->', response);
                            // set the new values......... to corrObj
                            dispatch(UPDATE_CORR_ADDRESS(formValues));
                            dispatch(UPDATE_WET_END(corrData.wetend));
                            dispatch(UPDATE_DRY_END(corrData.dryend));
                        });
                    });
                });
            } catch (err) {
                console.log(err)
                setMessage(err);
            } finally {
                dispatch(SET_LOADING(false));
            }
        }
        handleCloseDlg();
    };

    const handleWetEndCheckBox = (value, checked) => {
        setCorrData({...corrData, wetend: {...corrData.wetend, [value]: checked}});
    };

    const handleDryEndCheckBox = (value, checked) => {
        setCorrData({...corrData, dryend: {...corrData.dryend, [value]: checked}});
    };

    console.log('CORR-EDIT-DLG -->state', corrData);
    console.log('CORR-EDIT-DLG -->formValues', formValues);

    const handleCloseDlg = () => {
        dispatch(TOGGLE_CORR_BASE_DLG(false));
    };

    function handleLatitude(value, event) {
        // console.log('VALUE', value);
        // console.log('EVENT', event.target.id);
        setFormValues(() => ({...formValues, geo: {...formValues.geo, latitude: value}}))
    }

    function handleLongitude(value, event) {
        // console.log('VALUE', value);
        // console.log('EVENT', event.target.id);
        setFormValues(() => ({...formValues, geo: {...formValues.geo, longitude: value}}))
    }

    if (message.error) {
        return (
            <Message showIcon closable type="error" header="Error">
                {message.msg}
                <p>Jesus... what you did!!!</p>
            </Message>
        );
    } else if (isLoading) {
        return (
            <BusySpinner title={'BusySpinner'} topic="Jesus... give me a break!"/>
        );
    } else {
        return (
            <Modal
                open={open}
                onClose={handleCloseDlg}
                className='rs-theme-dark'
                style={{marginTop: '2rem'}}
                backdrop={'static'}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>{dlgTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        layout='horizontal'
                        model={newCorr ? NewCorrugatorIdValidation : CorrugatorValidation}
                        ref={formRef}
                        onChange={setFormValues}
                        onCheck={setFormError}
                        formError={formError}
                        formValue={{...formValues}}
                        style={{margin: 15}}
                    >
                        {newCorr &&
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group style={{display: "block"}}>
                                            <Form.ControlLabel style={{width: '5rem'}}>Location</Form.ControlLabel>
                                            <Form.Control name="location" type='text' style={{width: '12rem'}}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group style={{display: "block"}}>
                                            <Form.ControlLabel style={{width: '5rem'}}>Code name</Form.ControlLabel>
                                            <Form.Control name="codename" type='text' style={{width: '12rem'}}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr style={hrStyle}/>
                            </div>
                        }
                        <Row>
                            <Col xs={12}>
                                <Form.Group style={{marginBottom: '0.63rem', display: "block"}}>
                                    <Form.ControlLabel style={{width: '5rem'}}>Street</Form.ControlLabel>
                                    <Form.Control name='street' type='text' style={{width: '12rem'}}/>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group style={{marginBottom: '0.63rem', display: "block"}}>
                                    <Form.ControlLabel style={{width: '5rem'}}>City</Form.ControlLabel>
                                    <Form.Control name='city' type='text' style={{width: '12rem'}}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.ControlLabel style={{width: '5rem'}}>Zip Code</Form.ControlLabel>
                                    <Form.Control name="zipcode" type='text' style={{width: '12rem'}}/>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group style={{marginBottom: '0.63rem', display: 'block'}} controlId="state-9">
                                    <Form.ControlLabel style={{width: '5rem'}}>State</Form.ControlLabel>
                                    <Form.Control name='state' style={{width: '12rem'}} data={dataStates}
                                                  accepter={SelectPicker}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Group controlId="latitude" style={{display: "flex"}}>
                                    <Form.ControlLabel style={{width: '5rem'}}>Latitude</Form.ControlLabel>
                                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Latitude</Tooltip>}>
                                        {formValues.geo &&
                                            <Form.Control name='latitude' style={{width: '12rem'}}
                                                          value={formValues.geo.latitude} onChange={handleLatitude}/>
                                        }
                                    </Whisper>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group controlId='longitude' style={{display: "flex"}}>
                                    <Form.ControlLabel style={{width: '5rem'}}>Longitude</Form.ControlLabel>
                                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Longitude</Tooltip>}>
                                        {formValues.geo &&
                                            <Form.Control name='longitude' style={{width: '12rem'}}
                                                          value={formValues.geo.longitude} onChange={handleLongitude}/>
                                        }
                                    </Whisper>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr style={hrStyle}/>
                        <Row style={{marginBottom: '1.2rem', marginTop: '1rem'}}>
                            <Col xs={24}>
                                <label style={headerCardStyle}>Wet End:</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} style={{display: "inline-flex", alignItems: 'center'}}>
                                <div style={{display: "flex", alignItems: 'center'}}>
                                    <label>Facer</label>
                                    <InputNumber
                                        style={{width: '4.38rem', marginLeft: '0.313rem', marginRight: '0.63rem'}}
                                        name='facer'
                                        value={corrData.wetend.facer}
                                        onChange={(value) => setCorrData({
                                            ...corrData,
                                            wetend: {...corrData.wetend, facer: Number(value)}
                                        })}
                                        min={0}
                                        max={3}
                                        defaultValue={0}
                                    />
                                </div>
                                <Checkbox checked={corrData.wetend.blc} value={MachineName.blc}
                                          onChange={handleWetEndCheckBox}>BLC</Checkbox>
                                <Checkbox checked={corrData.wetend.webtrol} value={MachineName.webtrol}
                                          onChange={handleWetEndCheckBox}>Webtrol</Checkbox>
                                <Checkbox checked={corrData.wetend.bbr} value={MachineName.bbr}
                                          onChange={handleWetEndCheckBox}>BBR</Checkbox>
                                <Checkbox checked={corrData.wetend.zds} value={MachineName.zds}
                                          onChange={handleWetEndCheckBox}>ZDS</Checkbox>
                                <Checkbox checked={corrData.wetend.sss} value={MachineName.sss}
                                          onChange={handleWetEndCheckBox}>SSS</Checkbox>
                                <Checkbox checked={corrData.wetend.gu} value={MachineName.gu}
                                          onChange={handleWetEndCheckBox}>GU</Checkbox>
                                <Checkbox checked={corrData.wetend.df} value={MachineName.df}
                                          onChange={handleWetEndCheckBox}>DF</Checkbox>
                                <Checkbox checked={corrData.wetend.wec} value={MachineName.wec}
                                          onChange={handleWetEndCheckBox}>WEC</Checkbox>
                            </Col>
                        </Row>
                        <hr style={hrStyle}/>
                        <Row style={{marginBottom: '1.2rem', marginTop: '1rem'}}>
                            <Col xs={24}>
                                <label style={headerCardStyle}>Dry-End:</label>
                            </Col>
                        </Row>
                        <Row style={{display: 'inline-flex'}}>
                            <Col xs={24}>
                                <Checkbox checked={corrData.dryend.com} value={MachineName.com}
                                          onChange={handleDryEndCheckBox}>COM</Checkbox>
                                <Checkbox checked={corrData.dryend.aoc} value={MachineName.aoc}
                                          onChange={handleDryEndCheckBox}>AOC</Checkbox>
                                <Checkbox checked={corrData.dryend.kq} value={MachineName.kq}
                                          onChange={handleDryEndCheckBox}>KQ-M</Checkbox>
                                <Checkbox checked={corrData.dryend.hb1} value={MachineName.hb1}
                                          onChange={handleDryEndCheckBox}>HB 1</Checkbox>
                                <Checkbox checked={corrData.dryend.hb2} value={MachineName.hb2}
                                          onChange={handleDryEndCheckBox}>HB 2</Checkbox>
                                <Checkbox checked={corrData.dryend.sr} value={MachineName.sr}
                                          onChange={handleDryEndCheckBox}>SR</Checkbox>
                                <Checkbox checked={corrData.dryend.bdc} value={MachineName.bdc}
                                          onChange={handleDryEndCheckBox}>BDC</Checkbox>
                                {/*<Checkbox checked={corrData.dryend.webcleaner} value={MachineName.webcleaner} onChange={handleDryEndCheckBox}>Webcleaner</Checkbox>*/}
                                <Checkbox checked={corrData.dryend.kqf} value={MachineName.kqf}
                                          onChange={handleDryEndCheckBox}>KQ-F</Checkbox>
                                <Checkbox checked={corrData.dryend.hq} value={MachineName.hq}
                                          onChange={handleDryEndCheckBox}>HQ</Checkbox>
                                <Checkbox checked={corrData.dryend.asm} value={MachineName.asm}
                                          onChange={handleDryEndCheckBox}>AS</Checkbox>
                            </Col>
                        </Row>
                        <hr style={hrStyle}/>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button onClick={handleSubmit} appearance="primary">Save</Button>
                        <Button onClick={handleCloseDlg} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        );
    }
};


export default CorrEditDlg;
