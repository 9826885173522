export const CorrugatorDocuments = [
    'Wichita-STEEN',
    'Wichita-ERIC',
    'Beloit-ADALIZ',
    'Lewisburg-FABIAN',
    'Carlisle-JESSE',
    'Fort Worth-GIA',
    'Rockwall-GAUTHIERN',
    'Statesville-ANAIS',
    'Statesville-RONALD',
    'Wapakoneta-AMINATA',
    'Humboldt-SPENCER',
    'Macungie-JUSTIN',
    'Macungie-MATISSE',
    'Lathrop-ROXANNE',
    'Conyers-DAWSON',
    'Conyers-MATHEO',
    'Valparaiso-ALYCIA',
    'Valparaiso-VALENTINA',
    'Conyers-LAURANNE',
    'Staten Island-CALI',
    'Cedar Hill-TINO',
    'Carlisle-JANISH',
    'Henderson-NAYA'
].sort()

export const CorrDocu_Test = [
    {name: 'Wichita', subMenus: [{name: 'ERIC', id: 'Wichita-ERIC'}, {name: 'STEEN', id: 'Wichita-STEEN'}]},
    {name: 'Beloit', subMenus: [{name: 'ADALIZ', id: 'Beloit-ADALIZ'}]},
    {name: 'Lewisburg', subMenus: [{name: 'FABIAN', id: 'Lewisburg-FABIAN'}]},
    {name: 'Carlisle', subMenus: [{name: 'JESSE', id: 'Carlisle-JESSE'}]},
    {name: 'Fort Worth', subMenus: [{name: 'GIA', id: 'Fort Worth-GIA'}]},
    {name: 'Rockwall', subMenus: [{name: 'GAUTHIERN', id: 'Rockwall-GAUTHIERN'}]},
    {
        name: 'Statesville',
        subMenus: [{name: 'ANAIS', id: 'Statesville-ANAIS'}, {name: 'RONALD', id: 'Statesville-RONALD'}]
    },
    {name: 'Humboldt', subMenus: [{name: 'SPENCER', id: 'Humboldt-SPENCER'}]},
    {name: 'Macungie', subMenus: [{name: 'JUSTIN', id: 'Macungie-JUSTIN'}, {name: 'MATISSE', id: 'Macungie-MATISSE'}]},
    {name: 'Lathrop', subMenus: [{name: 'ROXANNE', id: 'Lathrop-ROXANNE'}]},
    {
        name: 'Conyers',
        subMenus: [{name: 'DAWSON', id: 'Conyers-DAWSON'}, {name: 'MATHEO', id: 'Conyers-MATHEO'}, {
            name: 'LAURANNE',
            id: 'Conyers-LAURANNE'
        }]
    },
    {
        name: 'Valparaiso',
        subMenus: [{name: 'ALYCIA', id: 'Valparaiso-ALYCIA'}, {name: 'VALENTINA', id: 'Valparaiso-VALENTINA'}]
    },
    {name: 'Staten Island', subMenus: [{name: 'CALI', id: 'Staten Island-CALI'}]},
    {name: 'Cedar Hill', subMenus: [{name: 'TINO', id: 'Cedar Hill-TINO'}]}
].sort()

// sort by name
CorrDocu_Test.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
});


// return (
//     <div>
//         {people.map((person, index) => {
//             return (
//                 <div key={index}>
//                     <h2>Name: {person.name}</h2>
//
//                     {person.pets.map((pet, index) => {
//                         return (
//                             <div key={index}>
//                                 <h2>Pet: {pet}</h2>
//                             </div>
//                         );
//                     })}
//
//                     <hr />
//                 </div>
//             );
//         })}
//     </div>
// );