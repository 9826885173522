
export const MachineName = {
    ccd: 'ccd',
    aoc: 'aoc',
    asm: 'asm',
    bdc: 'bdc',
    bbr: 'bbr',
    blc: 'blc',
    df: 'df',
    facer: 'facer',
    facer1: 'facer1',
    facer2: 'facer2',
    facer3: 'facer3',
    gu: 'gu',
    hb: 'hb',
    hb1: 'hb1',
    hb2: 'hb2',
    hq: 'hq',
    kq: 'kq',
    kqf: 'kqf',
    rs: 'rs',
    rs1: 'rs1',
    rs2: 'rs2',
    rs3: 'rs3',
    rs4: 'rs4',
    rs5: 'rs5',
    rs6: 'rs6',
    rs7: 'rs7',
    sp: 'sp',
    sp1: 'sp1',
    sp2: 'sp2',
    sp3: 'sp3',
    sp4: 'sp4',
    sp5: 'sp5',
    sp6: 'sp6',
    sp7: 'sp7',
    sr: 'sr',
    webtrol: 'webtrol',
    webcleaner: 'webcleaner',
    sss: 'sss',
    zds: 'zds',
    wec: 'wec',
    com: 'com',
    corrugator: 'corrugator',
};

export const MachineTab = {
    general: 'general',
    options: 'options',
    visu: 'visu',
    drawings: 'drawings',
    drives: 'drives',
    plc: 'plc',
    various: 'various',
    info: 'info'
};


export const CorrugatorTab = {
    contact: 'contact',
    hotel: 'hotel',
    general: 'general',
    ipaddress: 'ipaddress',
    visu: 'visu',
    various: 'various',
};

export const MachineTagName = {
    facer: 'facer',
    sp: 'sp',
    rs: 'rs',
    blc: 'blc',
    webtrol: 'webtrol',
    df: 'df',
    gu: 'gu',
    ph: 'ph',
    kq: 'kq',
    hb: 'hb',
    aoc: 'aoc',
    sr: 'sr',
    bdc: 'bdc',
    webdiverter: 'webdiverter',
    hq: 'hq',
    asm: 'asm',
    desk: 'desk',
    drives: 'drives',
};

export const FileSplitString = '___0___';
