import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {KnowledgeFileStruct} from "../../InitialDataStructures/Knowledge/KnowledgeFileStruct";
import {str_failed, str_loading, str_succeeded} from "../../Helpers/GeneralHelpers";
import KnowledgeDataServices from "../../Services/KnowledgeDataService";

const initialState = {
    openKnowledgeDlg: false,
    statusFetchList: 'idle',
    statusAddDoc: 'idle',
    statusUpdateDoc: 'idle',
    statusDeleteDoc: 'idle',
    statusSaveToStorage: 'idle',
    errorFetchList: null,
    errorSaveDoc: null,
    errorUpdateDoc: null,
    errorDeleteDoc: null,
    errorSaveToStorage: null,
    knowledgeList: [],
    knowledgeUploadObj: KnowledgeFileStruct,
};

export const ASYNC_GET_FILTERED_KNOWLEDGE_LIST = createAsyncThunk('knowledge/ASYNC_GET_FILTERED_KNOWLEDGE_LIST', async ({machinetags, mechanic}) => {
    try {
        console.log(`ASYNC_GET_FILTERED_KNOWLEDGE_LIST -> Tags: ${machinetags} - Mechanic: ${mechanic}`);
        const docColl = await KnowledgeDataServices.getFilteredKnowledgeList({machinetags, mechanic});
        const docs = docColl.docs;
        console.log('ASYNC_GET_FILTERED_KNOWLEDGE fetched List of', docs);
        const allKnowledgeDic = [];
        docs.forEach((doc) => {
            // console.log(`ASYNC_GET_FILTERED_KNOWLEDGE_LIST: ${doc.id} => ${doc.data()}`);
            allKnowledgeDic.push({id: doc.id, docData: doc.data()});
        });
        console.log('ASYNC_GET_FILTERED_KNOWLEDGE fetched List of', allKnowledgeDic);
        return allKnowledgeDic;
    } catch (error) {
        //throw new Error('ASYNC_GET_FILTERED_KNOWLEDGE_LIST --> :', error.msg);
        return error.message;
    }
});

export const ASYNC_ADD_KNOWLEDGE_DOC = createAsyncThunk('knowledge/ASYNC_ADD_KNOWLEDGE_DOC', async (knowledgeObj) => {
    console.log("SLICE ASYNC_ADD_KNOWLEDGE_DOC ID", knowledgeObj.id);
    console.log("SLICE ASYNC_ADD_KNOWLEDGE_DOC docData", knowledgeObj.docData);
    try {
        return await KnowledgeDataServices.addKnowledgeDocument(knowledgeObj);
    } catch (error) {
        return error.message;
    }
});

export const ASYNC_UPDATE_KNOWLEDGE_DOC = createAsyncThunk('knowledge/ASYNC_UPDATE_KNOWLEDGE_DOC', async (knowledgeObj) => {
    console.log("SLICE ASYNC_UPDATE_KNOWLEDGE_DOC ID", knowledgeObj.id);
    console.log("SLICE ASYNC_UPDATE_KNOWLEDGE_DOC docData", knowledgeObj.docData);
    try {
        return await KnowledgeDataServices.addKnowledgeDocument(knowledgeObj);
    } catch (error) {
        return error.message;
    }
});

export const ASYNC_DELETE_KNOWLEDGE_DOC = createAsyncThunk('knowledge/ASYNC_DELETE_KNOWLEDGE_DOC', async (knowledgeDoc_id) => {
    // console.log("SLICE ASYNC_DELETE_KNOWLEDGE_DOC ID", knowledgeDoc_id);
    try {
        return await KnowledgeDataServices.deleteKnowledgeDocument(knowledgeDoc_id);
    } catch (error) {
        return error.message;
    }
});

export const ASYNC_SAVE_DOC_TO_STORAGE = createAsyncThunk('knowledge/ASYNC_SAVE_DOC_TO_STORAGE', async (docFile) => {
    try {
        return await KnowledgeDataServices.saveDocToStorage(docFile);
    } catch (error) {
        return error.message;
    }
});


const knowledgeSlice = createSlice({
    name: 'knowledge',
    initialState,
    reducers: {
        KNOWLWEDGE_TOGGLE_DLG: (state, {payload}) => {
            state.openKnowledgeDlg = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(ASYNC_GET_FILTERED_KNOWLEDGE_LIST.pending, (state) => {
                    state.statusFetchList = str_loading;
                }
            )
            .addCase(ASYNC_GET_FILTERED_KNOWLEDGE_LIST.fulfilled, (state, action) => {
                    state.statusFetchList = str_succeeded;
                    console.log('ASYNC_GET_FILTERED_KNOWLEDGE_LIST -> Return', action.payload);
                    if (action.payload !== undefined) {
                        state.knowledgeList = action.payload;
                    }
                }
            )
            .addCase(ASYNC_GET_FILTERED_KNOWLEDGE_LIST.rejected, (state, action) => {
                    state.statusFetchList = str_failed;
                    state.errorFetchList = action.error.message;
                }
            )
            .addCase(ASYNC_ADD_KNOWLEDGE_DOC.pending, (state) => {
                    state.statusAddDoc = str_loading;
                }
            )
            .addCase(ASYNC_ADD_KNOWLEDGE_DOC.fulfilled, (state, action) => {
                    if (action.meta?.arg?.id) {
                        state.statusAddDoc = str_succeeded;
                        // console.log('ASYNC_GET_FILTERED_KNOWLEDGE_LIST -> Return', action.payload);
                        const docObj = action.meta.arg;
                        console.log('ASYNC_ADD_KNOWLEDGE_DOC FULFILLED ->', docObj);
                        state.knowledgeList.push(docObj);
                    }
                }
            )
            .addCase(ASYNC_ADD_KNOWLEDGE_DOC.rejected, (state, action) => {
                    state.statusAddDoc = str_failed;
                    state.errorAddDoc = action.error.message;
                }
            )
            .addCase(ASYNC_UPDATE_KNOWLEDGE_DOC.pending, (state) => {
                    state.statusUpdateDoc = str_loading;
                }
            )
            .addCase(ASYNC_UPDATE_KNOWLEDGE_DOC.fulfilled, (state, action) => {
                    if (action.meta?.arg?.id) {
                        state.statusUpdateDoc = str_succeeded;
                        // console.log('ASYNC_GET_FILTERED_KNOWLEDGE_LIST -> Return', action.payload);
                        const docObj = action.meta.arg;
                        console.log('ASYNC_UPDATE_KNOWLEDGE_DOC FULFILLED ->', docObj);
                        const {id} = docObj;
                        const knowledgeList = state.knowledgeList.filter(doc => doc.id === id);
                        state.knowledgeList = [...knowledgeList, docObj];
                        state.knowledgeList.push(docObj);
                    }
                }
            )
            .addCase(ASYNC_UPDATE_KNOWLEDGE_DOC.rejected, (state, action) => {
                    state.statusUpdateDoc = str_failed;
                    state.errorUpdateDoc = action.error.message;
                }
            )
            .addCase(ASYNC_DELETE_KNOWLEDGE_DOC.pending, (state) => {
                    state.statusDeleteDoc = str_loading;
                }
            )
            .addCase(ASYNC_DELETE_KNOWLEDGE_DOC.fulfilled, (state, action) => {
                    state.statusDeleteDoc = str_succeeded;
                    // console.log('ASYNC_GET_FILTERED_KNOWLEDGE_LIST -> Return', action.payload);
                    const knowledgeDoc_id = action.meta.arg;
                    console.log('ASYNC_DELETE_TIMESHEET id', knowledgeDoc_id);
                    const objIndex = state.knowledgeList.findIndex(doc => doc.id === knowledgeDoc_id);
                    if (objIndex > -1) {
                        state.knowledgeList.splice(objIndex, 1);
                    }
                }
            )
            .addCase(ASYNC_DELETE_KNOWLEDGE_DOC.rejected, (state, action) => {
                    state.statusDeleteDoc = str_failed;
                    state.errorDeleteDoc = action.error.message;
                }
            )
            .addCase(ASYNC_SAVE_DOC_TO_STORAGE.pending, (state) => {
                    state.statusSaveToStorage = str_loading;
                }
            )
            .addCase(ASYNC_SAVE_DOC_TO_STORAGE.fulfilled, (state, action) => {
                    state.statusSaveToStorage = str_succeeded;
                    // console.log('ASYNC_SAVE_DOC_TO_STORAGE -> Return', action.payload);
                    if (action.payload !== undefined) {
                        state.knowledgeList = action.payload;
                    }
                }
            )
            .addCase(ASYNC_SAVE_DOC_TO_STORAGE.rejected, (state, action) => {
                    state.statusSaveToStorage = str_failed;
                    state.errorSaveToStorage = action.error.message;
                }
            )
    }
});

export const {
    KNOWLWEDGE_TOGGLE_DLG,
    SET_MACHINE_GROUP,
} = knowledgeSlice.actions;

export const getKnowledgeFetchListStatus = (state) => state.knowledgeReducer.statusFetchList;
export const getKnowledgeFetchListError = (state) => state.knowledgeReducer.errorFetchList;
export const getKnowledgeAddStatus = (state) => state.knowledgeReducer.statusAddDoc;
export const getKnowledgeAddError = (state) => state.knowledgeReducer.errorAddDoc;
export const getKnowledgeUpdateStatus = (state) => state.knowledgeReducer.statusUpdateDoc;
export const getKnowledgeUpdateError = (state) => state.knowledgeReducer.errorUpdateDoc;
export const getKnowledgeDeleteStatus = (state) => state.knowledgeReducer.statusDeleteDoc;
export const getKnowledgeDeleteError = (state) => state.knowledgeReducer.errorDeleteDoc;
export const getKnowledgeUploadObj = (state) => state.knowledgeReducer.knowledgeUploadObj;
export const getKnowledgeList = (state) => state.knowledgeReducer.knowledgeList;

export default knowledgeSlice.reducer;