import {hrStyle, labelStyle, rowStyle} from "../Styles/ComponentStyles";
import {filesize} from 'filesize';
import {Button, ButtonToolbar, Progress} from "rsuite";
import React from "react";

const UploadDiv = ({selectedFile, upload}) => {
    const status = upload.progress === 100 ? 'success' : null;
    const color = upload.progress === 100 ? '#52c41a' : '#3385ff';

    return (
        <div>
            {/*<hr style={hrStyle}/>*/}
            {/*<div style={rowStyle}>*/}
            {/*    <div style={labelStyle}>Filename</div>*/}
            {/*    <div style={{...labelStyle, width: 360}}>{selectedFile.name}</div>*/}
            {/*</div>*/}
            {/*<div style={rowStyle}>*/}
            {/*    <div style={labelStyle}>Size</div>*/}
            {/*    {(selectedFile && selectedFile.size) &&*/}
            {/*        <div style={labelStyle}>{filesize(selectedFile.size)}</div>*/}
            {/*    }*/}
            {/*</div>*/}
            <div>{upload.state}</div>
            <Progress.Line percent={upload.progress} strokeColor={color} status={status}/>
            <hr style={hrStyle}/>
            <ButtonToolbar>
                <Button onClick={() => upload.pause()}>Pause</Button>
                <Button onClick={() => upload.resume()}>Resume</Button>
                <Button onClick={() => upload.cancel()}>Cancel</Button>
                <Button onClick={() => upload.reset()}>Reset</Button>
            </ButtonToolbar>
        </div>
    )
};

export default UploadDiv;