import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import DFOptionContent from "./DFOptionContent";

const DFContent = () => {

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<DFOptionContent/>}/>
        </Panel>
    );
};

export default DFContent;
