import {Button, ButtonToolbar, DatePicker, Input, Modal, SelectPicker, Toggle} from "rsuite";
import React, {useEffect} from "react";
import {cardStyle, labelStyle, rowStyle} from "../../Styles/ComponentStyles";
import {
    ASYNC_ADD_TIMESHEET_OBJ,
    ASYNC_UPDATE_TIMESHEET_OBJ,
    getActualTimeSheet,
    getModeTimeSheetDlg,
    getTimesheetAddStatus,
    getTimesheetUpdateStatus,
    TIMESHEET_TOGGLE_DLG
} from "./TimesheetSlice";
import {CorrugatorDocuments} from "../../TestData/CorrugatorDocuments";
import {getTime} from "date-fns";
import {useDispatch, useSelector} from "react-redux";
import {useUserAuth} from "../../Pages/Authentication/UserAuthContext";
import {useImmer} from "use-immer";
import {cloneDeep} from "lodash";
import {GetMachineNameForTimeSheet, str_All, str_EDIT, str_failed, str_loading, str_NEW, str_succeeded} from "../../Helpers/GeneralHelpers";
import BusySpinner from "../BusySpinner";

const TimesheetEditDlg = ({open}) => {
    const dispatch = useDispatch();
    const actualTimeSheetObj = useSelector(getActualTimeSheet);
    const mode = useSelector(getModeTimeSheetDlg); // EDIT or NEW
    const statusAdd = useSelector(getTimesheetAddStatus);
    const statusUpdate = useSelector(getTimesheetUpdateStatus);
    // console.log('TIME_SHEET_DLG -> actualTimeSheetObj', actualTimeSheetObj);
    const [timeSheetObj, setTimeSheetObj] = useImmer(cloneDeep(actualTimeSheetObj));
    // only create time sheets for logedin users!!!!!
    const {user} = useUserAuth();

    const plants = cloneDeep(CorrugatorDocuments);
    const machineNames = GetMachineNameForTimeSheet();

    const canSave = [timeSheetObj.docData.facility, timeSheetObj.docData.machine].every(Boolean);

    useEffect(() => {
        setTimeSheetObj(cloneDeep(actualTimeSheetObj))
    }, [actualTimeSheetObj, open, setTimeSheetObj])

    // console.log('TIME-SHEET -> ', timeSheetObj);

    const handleSaveDlg = async () => {
        // console.log('TimesheetEditDlg -> handleSaveDlg', timeSheetObj);
        // console.log('TimesheetEditDlg -> mode', mode);

        try {
            if (mode === str_EDIT) {
                await dispatch(ASYNC_UPDATE_TIMESHEET_OBJ(timeSheetObj)).unwrap();
            } else if (mode === str_NEW) {
                await dispatch(ASYNC_ADD_TIMESHEET_OBJ(timeSheetObj)).unwrap();
            }
        } catch (error) {
            throw new Error("EDIT/ADD TIMESHEET WRITE", error);
        } finally {
            handleCloseDlg();
        }
    };

    const handleCloseDlg = () => {
        // close the Dialog and send a kind of dummy timesheet -> TimesheetSlice
        dispatch(TIMESHEET_TOGGLE_DLG({open: false, timeSheet: timeSheetObj, mode: mode}));
    };

    if (statusAdd === str_loading || statusUpdate === str_loading || statusUpdate === str_succeeded) {
        return (
            <BusySpinner title={'Saving'} topic={'Timesheet'}/>
        )
    } else if (statusAdd === str_failed || statusUpdate === str_failed) {
        return (
            <h3>Fucked up!</h3>
        )
    } else {
        return (
            <Modal
                open={open}
                onClose={handleCloseDlg}
                className='rs-theme-dark'
                style={{marginTop: '2rem'}}
                backdrop={'static'}
                keyboard={false}
                size='sm'
            >
                <Modal.Header>
                    <Modal.Title>{mode} - Timesheet for {user.displayName}</Modal.Title>
                </Modal.Header>

                {(statusAdd === str_loading) &&
                    <BusySpinner topic={'Hallo'}/>
                } else {
                <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={cardStyle}>
                        {/*Facility*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Corrugator</div>
                            <SelectPicker
                                data={plants.map(item => ({label: item, value: item}))}
                                defaultValue={str_All}
                                style={{width: 224}}
                                onSelect={value => setTimeSheetObj(draft => {
                                    draft.docData.facility = value
                                })}
                                value={timeSheetObj.docData.facility}
                            />
                        </div>
                        {/*Machine*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Machine</div>
                            <SelectPicker
                                data={machineNames.map(item => ({label: item.toUpperCase(), value: item}))}
                                style={{width: 224}}
                                onSelect={value => setTimeSheetObj(draft => {
                                    draft.docData.machine = value;
                                })}
                                value={timeSheetObj.docData.machine}
                            />
                        </div>
                        {/*Start date*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Start date</div>
                            <DatePicker
                                format="yyyy-MM-dd HH:mm"
                                defaultValue={new Date()}
                                hideMinutes={minute => minute % 30 !== 0}
                                style={{width: 224}}
                                onSelect={value => setTimeSheetObj(draft => {
                                    draft.docData.starttime = getTime(value)
                                })}
                                value={new Date(timeSheetObj.docData.starttime)}
                            />
                        </div>
                        {/*End date*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>End date</div>
                            <DatePicker
                                format="yyyy-MM-dd HH:mm"
                                defaultValue={new Date()}
                                hideMinutes={minute => minute % 30 !== 0}
                                style={{width: 224}}
                                onSelect={value => setTimeSheetObj(draft => {
                                    draft.docData.endtime = getTime(value)
                                })}
                                value={new Date(timeSheetObj.docData.endtime)}
                            />
                        </div>
                        {/*Online Support*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Online support</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={timeSheetObj.docData.remote}
                                onChange={(checked) => setTimeSheetObj(draft => {
                                    draft.docData.remote = checked
                                })}
                            />
                        </div>
                        {/*Task finished*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Task finished</div>
                            <Toggle
                                size="md"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={timeSheetObj.docData.done}
                                onChange={(checked) => setTimeSheetObj(draft => {
                                    draft.docData.done = checked
                                })}
                            />
                        </div>
                        {/*Task*/}
                        <div style={rowStyle}>
                            <div style={labelStyle}>Task</div>
                            <Input
                                id='task'
                                as="textarea"
                                rows={4}
                                placeholder="Describe the task"
                                value={timeSheetObj.docData.task}
                                onChange={value =>
                                    setTimeSheetObj(draft => {
                                        draft.docData.task = value;
                                    })}
                            />
                        </div>
                    </div>
                </Modal.Body>
            }

                <Modal.Footer>
                    <ButtonToolbar>
                        <Button disabled={!canSave} onClick={handleSaveDlg}>Save</Button>
                        <Button onClick={handleCloseDlg}>Cancel</Button>
                    </ButtonToolbar>
                </Modal.Footer>

            </Modal>
        )
    }
};

export default TimesheetEditDlg;