export const findLabelForKeyInArray = (array, key) => {
//    console.log('FIND-LABEL-FOR-KEY-IN-ARRAY --> array:', array);
//    console.log('FIND-LABEL-FOR-KEY-IN-ARRAY --> key:', key);
    if (key === undefined) {
        return '';
    } else {
        return array.find(x => x.value === key).label;
    }
};

export const dataVolt = [
    {'label': 'N/A', 'value': 0,},
    {'label': '380 V', 'value': 380,},
    {'label': '400 V', 'value': 400,},
    {'label': '460 V', 'value': 460,},
    {'label': '480 V', 'value': 480,}
];

export const dataManufacurer = [
    {'label': 'N/A', 'value': '',},
    {'label': 'BHS', 'value': 'BHS'},
    {'label': 'ETU', 'value': 'ETU'},
    {'label': 'Fosber', 'value': 'Fosber'},
    {'label': 'Mitsubishi', 'value': 'Mitsubishi'},
    {'label': 'Marquip', 'value': 'Marquip'},
    {'label': 'United', 'value': 'United'},
    {'label': 'Mix', 'value': 'Mix'},
];

export const dataSRProfiles = [
    {'label': 'N/A', 'value': '',},
    {'label': '3P', 'value': '3P'},
    {'label': 'PP', 'value': 'PP'},
    {'label': 'ASYNC', 'value': 'ASYNC'},
    {'label': '5P', 'value': '5P'},
    {'label': 'Beer', 'value': 'Beer'},
];

export const dataSRProfileOffset = [
    {'label': 'N/A', 'value': 0,},
    {'label': '1x', 'value': 1},
    {'label': '2x', 'value': 2},
];

export const dataSRDrives = [
    {'label': 'N/A', 'value': '',},
    {'label': 'BUM', 'value': 'BUM'},
    {'label': 'ELAU MAX4', 'value': 'ELAU MAX4'},
    {'label': 'ELAU C600', 'value': 'ELAU C600'},
    {'label': 'Schneider', 'value': 'Schneider'},
];

export const dataWidth = [
    {'label': 'N/A', 'value': 0,},
    {'label': '1800 mm', 'value': 1800},
    {'label': '2000 mm', 'value': 2000},
    {'label': '2200 mm', 'value': 2200},
    {'label': '2500 mm', 'value': 2500},
    {'label': '2800 mm', 'value': 2800},
    {'label': '3300 mm', 'value': 3300},
];

export const dataRollSet = [
    {'label': 'N/A', 'value': '',},
    {'label': 'A', 'value': 'A'},
    {'label': 'B', 'value': 'B'},
    {'label': 'C', 'value': 'C'},
    {'label': 'D', 'value': 'D'},
    {'label': 'E', 'value': 'E'},
]

export const dataCorrLine = [
    {'label': 'N/A', 'value': '',},
    {'label': 'Eco-Line', 'value': 'Eco-Line'},
    {'label': 'Steady-Line', 'value': 'Steady-Line'},
    {'label': 'Quality-Line', 'value': 'Quality-Line'},
    {'label': 'Value-Line', 'value': 'Value-Line'},
    {'label': 'Classic-Line', 'value': 'Classic-Line'},
    {'label': 'Speed-Line', 'value': 'Speed-Line'},
    {'label': 'Width-Line', 'value': 'Width-Line'},
];

export const dataSplicerType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'SP-X', 'value': 'SP-X'},
    {'label': 'SP-X2', 'value': 'SP-X2'},
    {'label': 'SP-M', 'value': 'SP-M'},
    {'label': 'SP-M2', 'value': 'SP-M2'},
    {'label': 'SP-XL', 'value': 'SP-XL'},
    {'label': 'SP-S', 'value': 'SP-S'},
    {'label': 'SP-L', 'value': 'SP-L'},
    {'label': 'ARW-M', 'value': 'ARW-M'},
];

export const dataRSType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'RS-M', 'value': 'RS-M'},
    {'label': 'RS-M/ARA', 'value': 'RS-M/ARA'},
    {'label': 'RS-A', 'value': 'RS-A'},
    {'label': 'ARA', 'value': 'ARA'},
];

export const dataFacerType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'MF', 'value': 'MF'},
    {'label': 'MF II', 'value': 'MF II'},
    {'label': 'MF II Plus', 'value': 'MF II Plus'},
    {'label': 'MF-B', 'value': 'MF-B'},
    {'label': 'MF-A', 'value': 'MF-A'},
    {'label': 'QF-P', 'value': 'QF-P'},
    {'label': 'AF-P', 'value': 'AF-P'},
    {'label': 'EF-P', 'value': 'EF-P'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataBBRType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'BBR', 'value': 'BBR'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataBLCType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'BLC', 'value': 'BLC'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataWebtrolType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'Webtrol', 'value': 'Webtrol'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataGUType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'GU-M', 'value': 'GU-M'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataDFType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'DWR', 'value': 'DWR'},
    {'label': 'DF-P7', 'value': 'DF-P7'},
    {'label': 'DF-P8', 'value': 'DF-P8'},
    {'label': 'DF-S', 'value': 'DF-S'},
    {'label': 'DF-C II', 'value': 'DF-C II'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataAOCType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'AOC', 'value': 'AOC'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataCOMType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'COM', 'value': 'COM'},
];

export const dataCCDType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'VisiWin-Net', 'value': 'VisiWin-Net'},
    {'label': 'WINCC', 'value': 'WINCC'},
];

export const dataKQType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'KQ-M', 'value': 'KQ-M'},
    {'label': 'RS-M', 'value': 'RS-M'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataHBType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'HB', 'value': 'HB'},
];

export const dataKQFType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'KQ-F', 'value': 'KQ-F'},
];

export const dataSRType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'SR-M', 'value': 'SR-M'},
    {'label': 'SR-S', 'value': 'SR-S'},
    {'label': 'SR-V', 'value': 'SR-V'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataBDCType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'BDC', 'value': 'BDC'},
];

export const dataHQType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'NQ-M', 'value': 'NQ-M'},
    {'label': 'HQ-M', 'value': 'HQ-M'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataASMType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'AS-M', 'value': 'AS-M'},
    {'label': 'AAR', 'value': 'AAR'},

    {'label': 'China Shit', 'value': 'China'},
];

export const dataPlCType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'S5', 'value': 'S5'},
    {'label': 'S7-300', 'value': 'S7-300'},
    {'label': 'S7-400', 'value': 'S7-400'},
    {'label': 'TIA-13', 'value': 'TIA-13'},
    {'label': 'TIA-14', 'value': 'TIA-14'},
    {'label': 'TIA-15', 'value': 'TIA-15'},
    {'label': 'TIA-16', 'value': 'TIA-16'},
    {'label': 'TIA-17', 'value': 'TIA-17'},
    {'label': 'Simotion', 'value': 'Simotion'},
    {'label': 'Lenze', 'value': 'Lenze'},
    {'label': 'Allen Bradley', 'value': 'Allen Bradley'},
];

export const dataScreenType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'MP370', 'value': 'MP370'},
    {'label': 'MP377', 'value': 'MP377'},
    {'label': 'PC477E', 'value': 'PC477E'},
    {'label': 'IPC277E', 'value': 'IPC277E'},
    {'label': 'IPC477E', 'value': 'IPC477E'},
    {'label': 'IFP2200', 'value': 'IFP2200'},
];

export const dataVisuType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'WINCC', 'value': 'WINCC'},
    {'label': 'VisiWinNET', 'value': 'VisiWinNET'},
    {'label': 'WEC', 'value': 'WEC'},
    {'label': 'ProTool', 'value': 'ProTool'},
    {'label': 'WPF', 'value': 'WPF'},
    {'label': 'Putty', 'value': 'Putty'},
];

export const dataVisuAccess = [
    {'label': 'N/A', 'value': '',},
    {'label': 'I-Browser', 'value': 'I-Browser'},
    {'label': 'VNC', 'value': 'VNC'},
    {'label': 'Ultra-VNC', 'value': 'Ultra-VNC'},
    {'label': 'Tight-VNC', 'value': 'Tight-VNC'},
    {'label': 'PC-Anywhere', 'value': 'PC-Anywhere'},
    {'label': 'Remote Desktop', 'value': 'Remote Desktop'},
];

export const dataSteamSupply = [
    {'label': 'N/A', 'value': '',},
    {'label': 'BHS', 'value': 'BHS'},
    {'label': 'B&S', 'value': 'B&S'},
    {'label': 'Stickl', 'value': 'Stickl'},
    {'label': 'Donahue', 'value': 'Donahue'},
    {'label': 'Custom', 'value': 'Custom'},
];

export const dataSteamShower = [
    {'label': 'N/A', 'value': 0,},
    {'label': 'Bottom', 'value': 1},
    {'label': 'Top/Bottom', 'value': 2},
];

export const dataMotorDrives = [
    {'label': 'N/A', 'value': '',},
    {'label': 'Sin-S120', 'value': 'Sin-S120'},
    {'label': 'Lenze 9300', 'value': 'Lenze 9300'},
    {'label': 'Lenze 4908', 'value': 'Lenze 4908'},
    {'label': 'Lenze 9400', 'value': 'Lenze 9400'},
    {'label': 'ABB ACS400', 'value': 'ABB ACS400'},
    {'label': 'ABB ACS850', 'value': 'ABB ACS850'},
    {'label': 'ABB ACS880', 'value': 'ABB ACS880'},
    {'label': 'ABB DCS400', 'value': 'ABB DCS400'},
    {'label': 'ABB DCS800', 'value': 'ABB DCS800'},
];

export const dataMotorType = [
    {'label': 'N/A', 'value': '',},
    {'label': 'Elbtal', 'value': 'Elbtal'},
    {'label': 'Lenze', 'value': 'Lenze'},
    {'label': 'Baumueller', 'value': 'Baumueller'},
    {'label': 'Reliance', 'value': 'Reliance'},
    {'label': 'ABB', 'value': 'ABB'},
    {'label': 'China Shit', 'value': 'China'},
];

export const dataLevel = [
    {'label': 'N/A', 'value': '',},
    {'label': 'SINGLE', 'value': 'SINGLE',},
    {'label': 'DUPLEX', 'value': 'DUPLEX'},
    {'label': 'TRIPLEX', 'value': 'TRIPLEX'},
];

export const dataDischargeSide = [
    {'label': 'N/A', 'value': 0,},
    {'label': 'OS', 'value': 1,},
    {'label': 'DS', 'value': 2,},
    {'label': 'OS/DS', 'value': 3,},
];

export const dataBrushType = [
    {'label': 'N/A', 'value': 0,},
    {'label': 'Steel', 'value': 1,},
    {'label': 'Carbon', 'value': 2,},
    {'label': 'Mix', 'value': 3,},
];

export const dataKnifebar = [
    {'label': 'N/A', 'value': '',},
    {'label': 'Carbon', 'value': 'Carbon',},
    {'label': 'Steel', 'value': 'Steel',},
];

export const dataEvacuation = [
    {'label': 'N/A', 'value': '',},
    {'label': 'Air Flap', 'value': 'Air Flap',},
    {'label': 'Big Air Flap', 'value': 'Big Air Flap',},
    {'label': 'Cam', 'value': 'Cam',},
    {'label': 'Flap 2Motor', 'value': 'Flap 2Motor',},
];

export const dataDFLoad = [
    {'label': 'N/A', 'value': '',},
    {'label': 'Platetrol', 'value': 'Platetrol',},
    {'label': 'Chaintrol', 'value': 'Chaintrol',},
    {'label': 'Bartrol', 'value': "Bartrol",},
];

export const maskOptions = [
    // 0
    {
        name: 'Date',
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
        placeholder: '25/09/1970'
    },
    // 1
    {
        name: 'Date with time',
        mask: [
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            ':',
            /\d/,
            /\d/,
            ' ',
            /a|p/,
            /m/
        ],
        placeholder: '25/09/1970 12:00 pm'
    },
    // 2
    {
        name: 'CN phone number',
        mask: ['1', /[3456789]/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
        placeholder: '138 1234 1234'
    },
    // 3
    {
        name: 'US phone number',
        mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        placeholder: '(555) 495-3947'
    },
    // 4
    {
        name: 'Credit Card',
        mask: [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/
        ],
        placeholder: '4242 4242 4242 4242'
    },
    // 5
    {
        name: 'IP Address',
        mask: [/[1-2]/, /\d/, /\d/, '.', /[0-1-2]/, /\d/, /\d/, '.', /[0-1-2]/, /\d/, /\d/, '.', /[0-1-2]/, /\d/, /\d/],
        placeholder: 'xxx.xxx.xxx.xxx'
    }

];

export const dataStates = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'].map(
    item => ({label: item, value: item})
);
