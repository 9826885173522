import {Button, Modal} from "rsuite";
import React from "react";
import {version} from 'react';
import packageJson from '../../package.json';
import PropTypes from "prop-types";
import {TOGGLE_ABOUT_DLG} from "../Reducers/CorrugatorSlice";

const AboutDlg = ({openDlg, dispatch}) => {
    const handleCloseDlg = () => {
        dispatch(TOGGLE_ABOUT_DLG(false));
    };

    return (
        <Modal
            className='rs-theme-dark'
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            backdrop={false}
            open={openDlg}
            onClose={handleCloseDlg}
        >
            <Modal.Header>
                <Modal.Title>About</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>Pratt Corr</h3>
                <div>Version {packageJson.version}</div>
                <div>React V{version}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCloseDlg} appearance="primary">
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

AboutDlg.propTypes = {
    openDlg: PropTypes.bool,
    dispatch: PropTypes.func,
};

export default AboutDlg;