import TimesheetHeader from "./TimesheetHeader";
import React from "react";
import TimesheetTable from "./TimesheetTable";

const TimesheetContent = ({selectedUser}) => {
    return (
        <>
            <TimesheetHeader selectedUser={selectedUser}/>
            <TimesheetTable/>
        </>
    )
};

export default TimesheetContent;