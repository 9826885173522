import {useSelector} from "react-redux";
import {getCorrObj} from "../../../Reducers/CorrugatorSlice";

export const SelectorRSOption = (rsId) => {
    const corrObj = useSelector(getCorrObj);
    console.log('RS-SELECTOR-OPTIONS', rsId, corrObj.rs1.options);

    switch (rsId) {
        case 1:
            return corrObj.rs1.options;
        case 2:
            return corrObj.rs2.options;
        case 3:
            return corrObj.rs3.options;
        case 4:
            return corrObj.rs4.options;
        case 5:
            return corrObj.rs5.options;
        case 6:
            return corrObj.rs6.options;
        case 7:
            return corrObj.rs7.options;
        default:
            throw new Error('Unhandled SelectorRollstand Options type');
    }
};

