import React, {useState} from "react";
import {Button, ButtonToolbar, MaskedInput, Modal} from "rsuite";
import {cardStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {maskOptions} from "../../../../Resources/InputFormData";
import {cloneDeep, isEqual} from "lodash";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {TOGGLE_CORR_IP_DLG, UPDATE_CORR_BASE} from "../../../../Reducers/CorrugatorSlice";
import {useSelector} from "react-redux";

const CorrEditIPAdress = ({open, dispatch}) => {
    const corrObj = useSelector((state) => state.corrObjReducer.corrObj);
    const corrId = useSelector((state) => state.corrObjReducer.corrId);
    const tempState = cloneDeep(corrObj.corrbase);
    const [editCorrbase, setEditCorrbase] = useState(cloneDeep(corrObj.corrbase));
    console.log('CORR-EDIT-IPADDRESS --> corrbase', corrObj.corrbase);
    console.log('CORR-EDIT-IPADDRESS --> tempState', tempState);

    const handleSaveDlg = () => {
        CorrugatorDataServices.updateCorrugator(corrId, {'corrbase': editCorrbase}).then((response) => {
            console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_CORR_BASE(editCorrbase));
            handleCloseDlg();
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_CORR_IP_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>IP Addresses</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    {/*IP Address*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Remote</div>
                        <MaskedInput
                            id='ipaddress'
                            value={editCorrbase.ipaddress}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            // placeholderChar={'_'}
                            onChange={(value) => setEditCorrbase({...editCorrbase, ipaddress: value})}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>

                    {/*Firewall*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Firewall</div>
                        <MaskedInput
                            id='firewallipaddress'
                            value={editCorrbase.firewallipaddress}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            // placeholderChar={'_'}
                            onChange={(value) => setEditCorrbase({...editCorrbase, firewallipaddress: value})}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>

                    {/*Host*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Host</div>
                        <MaskedInput
                            id='hostipaddress'
                            value={editCorrbase.hostipaddress}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            // placeholderChar={'_'}
                            onChange={(value) => setEditCorrbase({...editCorrbase, hostipaddress: value})}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>

                    {/*Conveyor*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Conveyor</div>
                        <MaskedInput
                            id='conveyoripaddress'
                            value={editCorrbase.conveyoripaddress}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            // placeholderChar={'_'}
                            onChange={(value) => setEditCorrbase({...editCorrbase, conveyoripaddress: value})}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>

                    {/*Steam*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Steam</div>
                        <MaskedInput
                            id='hostipaddress'
                            value={editCorrbase.steamipaddress}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            // placeholderChar={'_'}
                            onChange={(value) => setEditCorrbase({...editCorrbase, steamipaddress: value})}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>

                    {/*Printer*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Printer</div>
                        <MaskedInput
                            id='printeripaddress'
                            value={editCorrbase.printeripaddress}
                            mask={maskOptions[5].mask}
                            guide={true}
                            showMask={true}
                            keepCharPositions={true}
                            placeholder={maskOptions[5].placeholder}
                            // placeholderChar={'_'}
                            onChange={(value) => setEditCorrbase({...editCorrbase, printeripaddress: value})}
                            pipe={value => {
                                const subips = value.split('.')
                                const invalidSubips = subips.filter(ip => {
                                    ip = parseInt(ip)
                                    return ip < 0 || ip > 255
                                })
                                return invalidSubips.length > 0 ? false : value
                            }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, editCorrbase)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>

        </Modal>
    );
};

export default CorrEditIPAdress;
