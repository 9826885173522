import {useCallback, useEffect, useReducer} from "react";
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage';
import {prattStorage} from "../firebase_config";


export var UploadState;
(function (UploadState) {
    UploadState["IDLE"] = "IDLE";
    UploadState["PAUSED"] = "PAUSED";
    UploadState["UPLOADING"] = "UPLOADING";
    UploadState["CANCELED"] = "CANCELED";
    UploadState["COMPLETED"] = "COMPLETED";
    UploadState["FAILED"] = "FAILED";
})(UploadState || (UploadState = {}));

let ActionType;
(function (ActionType) {
    ActionType[ActionType["SET_UPLOAD_ERROR"] = 0] = "SET_UPLOAD_ERROR";
    ActionType[ActionType["SET_UPLOAD_PROGRESS"] = 1] = "SET_UPLOAD_PROGRESS";
    ActionType[ActionType["SET_UPLOAD_COMPLETED"] = 2] = "SET_UPLOAD_COMPLETED";
    ActionType[ActionType["START_UPLOAD"] = 3] = "START_UPLOAD";
    ActionType[ActionType["PAUSE_UPLOAD"] = 4] = "PAUSE_UPLOAD";
    ActionType[ActionType["RESUME_UPLOAD"] = 5] = "RESUME_UPLOAD";
    ActionType[ActionType["CANCEL_UPLOAD"] = 6] = "CANCEL_UPLOAD";
    ActionType[ActionType["INITIALIZE"] = 7] = "INITIALIZE";
    ActionType[ActionType["RESET"] = 8] = "RESET";
})(ActionType || (ActionType = {}));

const initialState = {
    progress: 0,
    state: UploadState.IDLE,
    fileUrl: undefined,
    error: undefined,
    _file: undefined,
    _uploadTask: undefined,
    storageRef: undefined,
};

function reducer(state, action) {
    switch (action.type) {
        case ActionType.INITIALIZE:
            return {
                ...state,
                _file: {
                    file: action.payload.file,
                    metadata: action.payload.metadata,
                },
            };
        case ActionType.START_UPLOAD:
            return {
                ...state,
                progress: 0,
                state: UploadState.UPLOADING,
                _uploadTask: action.payload.uploadTask,
                storageRef: action.payload.storageRef,
            };

        case ActionType.PAUSE_UPLOAD:
            return {
                ...state,
                state: UploadState.PAUSED,
            };
        case ActionType.RESUME_UPLOAD:
            return {
                ...state,
                state: UploadState.UPLOADING,
            };
        case ActionType.CANCEL_UPLOAD:
            return {
                ...state,
                state: UploadState.CANCELED,
            };
        case ActionType.SET_UPLOAD_PROGRESS:
            return {
                ...state,
                progress: action.payload.progress,
            };
        case ActionType.SET_UPLOAD_ERROR:
            return {
                ...state,
                state: UploadState.FAILED,
                error: action.payload.error,
            };
        case ActionType.SET_UPLOAD_COMPLETED:
            return {
                ...state,
                progress: 100,
                state: UploadState.COMPLETED,
                fileUrl: action.payload.fileUrl,
            };
        case ActionType.RESET:
            return initialState;
        default:
            throw new Error('Unhandled action type');
    }
}

export function useUploader(path) {
    const [{ progress, state, error, fileUrl, storageRef, _file, _uploadTask }, dispatch,] = useReducer(reducer, initialState);

    useEffect(() => {
        console.log('Upload: start', path)
        if (_file && path) {
            //const storage = prattStorage;
            const storageRef = ref(prattStorage, path);
            // console.log('USE EFFECT <->');
            // console.log('PATH ->', path);
            // console.log('STORAGE ->',storage);
            // console.log('_FILE ->:', _file);
            // console.log('FILE._file ->:',_file.file);
            // console.log('META DATA ->:',_file.metadata);

            const uploadTask = uploadBytesResumable(storageRef, _file.file, _file.metadata);
            dispatch({
                type: ActionType.START_UPLOAD,
                payload: { uploadTask, storageRef },
            });

            const unsubscribe = uploadTask.on('state_changed', (snapshot) => {
                const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                if (snapshot.state === 'running') {
                    dispatch({
                        type: ActionType.SET_UPLOAD_PROGRESS,
                        payload: { progress },
                    });
                }
            }, (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        dispatch({
                            type: ActionType.SET_UPLOAD_ERROR,
                            payload: {
                                error: new Error('User does not have permission to access the prattStorage object'),
                            },
                        });
                        break;
                    case 'storage/canceled':
                        break;
                    case 'storage/unknown':
                    default:
                        dispatch({
                            type: ActionType.SET_UPLOAD_ERROR,
                            payload: {
                                error: new Error('Upload failed due to an unknown error'),
                            },
                        });
                        break;
                }
                unsubscribe();
            }, () => {
                getDownloadURL(uploadTask.snapshot.ref).then(_completeDownload);
                unsubscribe();
            });
            return () => {
                unsubscribe();
            };
        }
    }, [_file, path]);

    const reset = useCallback(() => {
        dispatch({ type: ActionType.RESET });
    }, []);

    const start = useCallback((file, metadata) => {
        reset();
        dispatch({
            type: ActionType.INITIALIZE,
            payload: { file, metadata },
        });
    }, [reset]);

    const pause = useCallback(() => {
        if (_uploadTask) {
            _uploadTask.pause();
            dispatch({ type: ActionType.PAUSE_UPLOAD });
        }
    }, [_uploadTask]);

    const resume = useCallback(() => {
        if (_uploadTask) {
            _uploadTask.resume();
            dispatch({ type: ActionType.RESUME_UPLOAD });
        }
    }, [_uploadTask]);

    const cancel = useCallback(() => {
        if (_uploadTask) {
            _uploadTask.cancel();
            dispatch({ type: ActionType.CANCEL_UPLOAD });
        }
    }, [_uploadTask]);

    const _completeDownload = (fileUrl) => {
        dispatch({
            type: ActionType.SET_UPLOAD_COMPLETED,
            payload: { fileUrl },
        });
    };

    return {
        progress,
        state,
        fileUrl,
        error,
        storageRef,
        start,
        pause,
        resume,
        cancel,
        reset,
    };
}
