import React, {useState} from "react";
import {Button, ButtonToolbar, InputNumber, InputPicker, Modal, Toggle} from "rsuite";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {dataDFLoad, dataSteamSupply} from "../../../../Resources/InputFormData";
import MotorEditCard from "../MotorEditCard";
import {cloneDeep, isEqual} from "lodash";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrDF, getCorrId, TOGGLE_DLG, UPDATE_DF_OPTIONS} from "../../../../Reducers/CorrugatorSlice";

const DFEditOptionsDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const dfObj = useSelector(getCorrDF);
    const options = dfObj.options;
    const tempState = cloneDeep(options);
    const [optObj, setOptObj] = useState(options);
    // console.log('Edit-DF-DLG', options);

    const handleSaveDlg = async () => {
        await CorrugatorDataServices.updateCorrugator(corrId, {'df.options': optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_DF_OPTIONS(optObj));
            handleCloseDlg();
        }).catch(error => {
            console.error("DF OPTIONS WRITE FUCKED UP -->", error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='lg'
        >
            <Modal.Header>
                <Modal.Title>DF - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    <MotorEditCard optObj={optObj} setOptObj={setOptObj}/>
                    <hr style={hrStyle}/>
                    {/*Number of Plates*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>No of Plates</div>
                        <InputNumber
                            id='numberplates'
                            value={optObj.numberplates}
                            onChange={(value) => setOptObj({...optObj, numberplates: Number(value)})}
                            min={0}
                            max={30}
                            style={{width: 80}}
                        />
                    </div>
                </div>
                <div style={cardStyle}>
                    {/*Steam Control*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Steam Control</div>
                        <InputPicker
                            data={dataSteamSupply}
                            id={'steamcontrol'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={optObj.steamcontrol}
                            onSelect={(value) => setOptObj({...optObj, steamcontrol: value})}
                            style={{width: 100}}
                        />
                    </div>
                    {/*Trol system*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Load System</div>
                        <InputPicker
                            data={dataDFLoad}
                            id={'trolsystem'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={optObj.trolsystem}
                            onSelect={(value) => setOptObj({...optObj, trolsystem: value})}
                            style={{width: 100}}
                        />
                    </div>
                    <hr style={hrStyle}/>
                    {/*additional PH*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Peanut Roll</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.addpreheater}
                            onChange={checked => setOptObj({...optObj, addpreheater: checked})}
                        />
                    </div>
                    {/*Brake roll*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Brake roll</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.brakeroll}
                            onChange={checked => setOptObj({...optObj, brakeroll: checked})}
                        />
                    </div>
                    {/*Temperature Control*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Temp. Control</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.tempcontrol}
                            onChange={checked => setOptObj({...optObj, tempcontrol: checked})}
                        />
                    </div>
                    {/*Remote Cut*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Remote Cut KQ</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.remotecutkq}
                            onChange={checked => setOptObj({...optObj, remotecutkq: checked})}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, optObj)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>

        </Modal>
    );
};

DFEditOptionsDlg.propTypes = {
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default DFEditOptionsDlg;
