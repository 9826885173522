import React from "react";
import PropTypes from "prop-types";
import {Badge, Nav} from "rsuite";
import {CorrugatorTab} from "../../Resources/StringConstants";
import {tabStyle} from "../../Styles/ComponentStyles";

const CorrugatorNavBar = ({onSelect, active, variousCount, ...props}) => {
    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} justified>
            <Nav.Item eventKey={CorrugatorTab.general}>
                <div style={{...tabStyle, color: '#e5e5ea'}}>General Info</div>
            </Nav.Item>
            <Nav.Item eventKey={CorrugatorTab.ipaddress}>
                <div style={{...tabStyle, color: '#e5e5ea'}}>IP Adresses</div>
            </Nav.Item>
            <Nav.Item eventKey={CorrugatorTab.visu}>
                <div style={{...tabStyle, color: '#e5e5ea'}}>Visu</div>
            </Nav.Item>
            <Nav.Item eventKey={CorrugatorTab.various}>
                {variousCount >= 1 ?
                    (<Badge content={variousCount}>
                        <div style={{...tabStyle, color: '#e5e5ea'}}>Various Docus</div>
                    </Badge>) :
                    (<div style={{...tabStyle, color: '#e5e5ea'}}>Various Docus</div>)
                }
            </Nav.Item>
            <Nav.Item eventKey={CorrugatorTab.contact}>
                <div style={{...tabStyle, color: '#e5e5ea'}}>Contacts</div>
            </Nav.Item>
            <Nav.Item eventKey={CorrugatorTab.hotel}>
                <div style={{...tabStyle, color: '#e5e5ea'}}>Hotels</div>
            </Nav.Item>
        </Nav>
    );
};

CorrugatorNavBar.propTypes = {
    active: PropTypes.string,
    variousCount: PropTypes.number,
    onSelect: PropTypes.func,
};

const CorrugatorHeaderNavBar = ({onSelect, active, variousCount}) => {
    return (
        <CorrugatorNavBar appearance="tabs" onSelect={onSelect} active={active} variousCount={variousCount}/>
    );
};

CorrugatorHeaderNavBar.propTypes = {
    active: PropTypes.string,
    variousCount: PropTypes.number,
    onSelect: PropTypes.func,
};

export default CorrugatorHeaderNavBar;
