import {db} from '../firebase_config';
import {
    collection,
    doc,
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc,
    query,
    where,
    setDoc, arrayUnion, arrayRemove
} from 'firebase/firestore';

const corrDbName = 'facilities';
const docsDbName = 'documents';
const knowDbName = 'knowledge';
const corrCollectionRef = collection(db, corrDbName);
const docsCollectionRef = collection(db, docsDbName);

class CorrugatorDataService {
    addCorrugator = (corrObj) => {
        return addDoc(corrCollectionRef, corrObj);
    }

    insertCorrugator = (id, newCorrugatorData) => {
        const corrRef = doc(corrCollectionRef, id)
        return setDoc(corrRef, newCorrugatorData, {merge: true});
    }

    updateCorrugator = (id, corrObj) => {
        // check is corrugator doc existing
        const existingCorrugator = doc(db, corrDbName, id);
        return updateDoc(existingCorrugator, corrObj);
    }

    deleteCorrugator = (id) => {
        // check is corrugator doc existing
        const existingCorrugator = doc(db, corrDbName, id);
        return deleteDoc(existingCorrugator);
    }

    addCorrugatorHotel = (id, hotelObj) => {
        // check is corrugator doc existing
        const existingCorrugator = doc(db, corrDbName, id);
        // arrayUnion() adds elements to an array but only elements not already present
        return updateDoc(existingCorrugator, {hotels: arrayUnion(hotelObj)})
    }

    removeCorrugatorHotel = (id, hotelObj) => {
        // check is corrugator doc existing
        const existingCorrugator = doc(db, corrDbName, id);
        // arrayRemove() removes all instances of each given element
        return updateDoc(existingCorrugator, {hotels: arrayRemove(hotelObj)})
    }

    addCorrugatorContact = (id, contactObj) => {
        // check is corrugator doc existing
        const existingCorrugator = doc(db, corrDbName, id);
        // arrayUnion() adds elements to an array but only elements not already present
        return updateDoc(existingCorrugator, {contacts: arrayUnion(contactObj)})
    }

    removeCorrugatorContact = (id, contactObj) => {
        // check is corrugator doc existing
        const existingCorrugator = doc(db, corrDbName, id);
        // arrayRemove() removes all instances of each given element
        return updateDoc(existingCorrugator, {contacts: arrayRemove(contactObj)})
    }

    getCorrugator = (id) => {
        const existingCorrugator = doc(db, corrDbName, id);
        return getDoc(existingCorrugator);
    }

//*****************************************************************************
//********************* DOCUMENTS *********************************************
//*****************************************************************************

    getAllDocsForCorrId = (id) => {
        console.log('Service id:', id);
        const docQuery = query(docsCollectionRef,
            where('refcollection', "==", id))
        // returns an array
        return getDocs(docQuery);
    };

    createFileDocument = (docObj) => {
        console.log('createFileDocument --> docObj', docObj);
        return setDoc(doc(db, docsDbName, docObj.id), docObj);
    };

    updateFileDocument = (docObj) => {
        const existingFileDoc = doc(db, docsDbName, docObj.id);
        if (existingFileDoc) {
            return updateDoc(existingFileDoc, docObj);
        }
    };

    deleteFileDocument = (id) => {
        const existingFileDoc = doc(db, docsDbName, id);
        if (existingFileDoc) {
            return deleteDoc(existingFileDoc);
        }
    };

}

export default new CorrugatorDataService();
