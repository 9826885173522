import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import SROptionContent from "./SROptionContent";

const SRContent = () => {
    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<SROptionContent/>}/>
        </Panel>
    );
};

export default SRContent;
