import {useParams} from "react-router-dom";
import {KnowledgeMenu} from "../TestData/KnowledgeMenu";
import {useDispatch} from "react-redux";
import {SET_MACHINE_GROUP} from "../Components/Knowledge/KnowledgeSlice";
import KnowledgeContent from "../Components/Knowledge/KnowledgeContent";

const KnowledgePage = () => {
    const dispatch = useDispatch();
    const key = useParams().key;
    //dispatch(SET_MACHINE_GROUP(key));
    //dispatch(SET_MACHINE_DATA(MF_KnowledgeData));

    console.log('KnowledgePage -> Title', key);

    const title = '' //KnowledgeMenu.find(menue => menue.key === key).title;

    return (
        <>
            <h3 style={{margin: 20}}>Knowledge {title}</h3>
            <KnowledgeContent/>
        </>
    )
};

export default KnowledgePage;