import React from "react";
import {Button} from "rsuite";
import PropTypes from "prop-types";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import {SelectorRSOption} from "../../Selector/SelectorRSOption";
import RSEditOptionsDlg from "./RSEditOptionsDlg";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const RSOptionContent = ({rsId}) => {
    const dispatch = useDispatch();
    const options = SelectorRSOption(rsId);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    // console.log('RS-OPTION-CONETXT options-->', options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Auto Alignment'} value={options.autoalignment === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Split Roll'} value={options.splitroll === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Brake Pad'} value={options.brakepads}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <RSEditOptionsDlg rsId={rsId} open={openDlg} dispatch={dispatch}/>
        </>
    );
};

RSOptionContent.propTypes = {
    rsId: PropTypes.number,
};

export default RSOptionContent;
