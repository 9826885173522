import React from "react";
import {cardStyle, fieldStyle, labelStyle, rowCardStyle} from "../../../../Styles/ComponentStyles";
import {Button, Tooltip, Whisper} from "rsuite";
//import CorrEditGeneral from "./CorrEditGeneral";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_CORR_GENERAL_DLG} from "../../../../Reducers/CorrugatorSlice";
import CorrEditGeneral from "./CorrEditGeneral";

const CorrGeneralContent = () => {
    const dispatch = useDispatch();
    const corrObj = useSelector((state) => state.corrObjReducer.corrObj);
    const openDlg = useSelector((state) => state.corrObjReducer.openCorrGeneralDlg);

    const corrbase = corrObj.corrbase;
    console.log('CORR-GENERAL-CONTENT corrbase-->', corrbase);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_CORR_GENERAL_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Line Type'} value={corrbase.type}/>
                    <ContentRow label={'Wet End'} value={corrbase.wetendtype}/>
                    <ContentRow label={'Dry End'} value={corrbase.dryendtype}/>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Width</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(corrbase.width / 25.4).toFixed()} "</Tooltip>}>
                            <div style={fieldStyle}>{corrbase.width} mm</div>
                        </Whisper>
                    </div>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Max-Speed</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(corrbase.speed * 3.281).toFixed()} ft/min</Tooltip>}>
                            <div style={fieldStyle}>{corrObj.speed} m/min</div>
                        </Whisper>
                    </div>
                    <ContentRow label={'Drive Side'} value={corrbase.drivesideleft === true ? 'Left' : 'Right'}/>
                </div>
                <div style={cardStyle}>
                    <ContentRow label={'Year of Built'} value={corrbase.built}/>
                    <ContentRow label={'Year of Startup'} value={corrbase.startup}/>
                    <ContentRow label={'CCC Host Vers.'} value={corrbase.cccprotocolversion}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <CorrEditGeneral open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default CorrGeneralContent;
