export const SROptions = {
    autocalibration: false,
    autolubrication: false,
    auxiliary: false,
    infeedpull: false,
    knifes: 0,
    outfeedpull: false,
    scorers: 0,
    trimknife: false,
    airblast: false,
    boardsupport: false,
    splittrim: false,
    tooldrive: ''
}
