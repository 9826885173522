import React, {useEffect, useState} from "react";
import {Button, ButtonToolbar, Modal, Toggle} from "rsuite";
import {cardStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {cloneDeep, isEqual} from "lodash";
import {SelectorHBOption} from "../../Selector/SelectHBOption";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrId, TOGGLE_DLG, UPDATE_HB_OPTIONS} from "../../../../Reducers/CorrugatorSlice";

const HBEditOptionsDlg = ({hbId, open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const options = SelectorHBOption(hbId);
    let tempState = cloneDeep(options);
    const [optObj, setOptObj] = useState(options);
    // console.log(`Edit-RS${hbId}-DLG options`, options);
    // console.log(`Edit-RS${hbId}-DLG optObj`, optObj);

    useEffect(() => {
        tempState = cloneDeep(options);
        setOptObj(options);
    }, [hbId])

    const handleSaveDlg = async () => {
        const tmp = ['hb', hbId.toString(), '.', 'options'].join('');
        await CorrugatorDataServices.updateCorrugator(corrId, {[tmp]: optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_HB_OPTIONS({id: hbId, obj: optObj}));
            handleCloseDlg();
        }).catch(error => {
            throw new Error(`HB${hbId} OPTIONS WRITE FUCKED UP -->`, error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>HB - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    {/*Nothing here*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Nothing here</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            // checked={optObj.standalone}
                            // onChange={checked => setOptObj({...optObj, standalone: checked})}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, optObj)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

HBEditOptionsDlg.propTypes = {
    hbId: PropTypes.number,
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default HBEditOptionsDlg;
