import {Button, ButtonToolbar, Input, TagPicker} from "rsuite";
import {Category_KnowledgeData} from "../../Resources/KnowledgeTagData";
import MachineTagSelector from "./MachineTagSelector";
import {useDispatch} from "react-redux";
import {ASYNC_GET_FILTERED_KNOWLEDGE_LIST, KNOWLWEDGE_TOGGLE_DLG} from "./KnowledgeSlice";


const KnowledgeHeader = () => {
    const dispatch = useDispatch();
    //const data = MachineTagSelector();


    function handleMFTagOnChange(value: string[], event) {
        console.log('VALUE --> value', value);
        console.log('EVENT --> event', event);
    }

    function handleCategoryTagOnChange(value: string[], event) {
        console.log('VALUE --> value', value);
        console.log('EVENT --> event', event);
    }

    function handleSearchOnClick() {
        try {
            dispatch(ASYNC_GET_FILTERED_KNOWLEDGE_LIST({mechanic: true, machinetags: undefined})).unwrap();
        } catch(error) {

        }
    }

    const handleUploadOnClick = () => {
        dispatch(KNOWLWEDGE_TOGGLE_DLG(true));
    }

    const handleNewDocuOnClick = () => {
        dispatch(KNOWLWEDGE_TOGGLE_DLG(true));
    }

    return (
        <>
            <ButtonToolbar>
                <Button onClick={handleNewDocuOnClick}>New Document</Button>
            </ButtonToolbar>
            <div style={{marginBottom: '1rem'}}>
                <h2>Not ready yet for Procuction mode</h2>
                <div style={{display: "flex", alignItems: "center"}}>
                    <TagPicker data={[]} style={{width: 300, marginLeft: 20,}} onChange={handleMFTagOnChange}/>
                    <TagPicker data={Category_KnowledgeData} style={{width: 300, marginLeft: 30}}
                               onChange={handleCategoryTagOnChange}/>
                    <Input style={{width: 300, marginLeft: 30}}/>
                    <Button onClick={handleSearchOnClick} style={{marginLeft: 20}}>Search</Button>
                    <Button onClick={handleUploadOnClick} style={{marginLeft: 20}}>Upload</Button>
                </div>
            </div>

        </>
    )
};

export default KnowledgeHeader;