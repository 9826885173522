import {Tooltip, Whisper} from "rsuite";
import {useSelector} from "react-redux";
import {getCorrId, getCorrObj} from "../../Reducers/CorrugatorSlice";

const labelStyle = {
    // color: 'var(--rs-btn-primary-text)'
    marginInline: '1.3rem',
    fontsize: 8
};

const spanStyle = {
    color: 'var(--rs-btn-primary-text)'
};

const CorrugatorHeader = () => {
    const corrId = useSelector(getCorrId);
    const corrObj = useSelector(getCorrObj);
    const corrbase = corrObj.corrbase;
    const address = corrObj.address;

    console.log('RENDER CORRUGATOR-HEADER --> basobj, address', corrbase, address);

    const handleMaps = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${address.geo.latitude},${address.geo.longitude}`, "_blank")
    };

    return (
        <div style={{marginBottom: '1rem'}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{display: "flex", flexDirection: "column", justifyItems: "center"}}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {address.geo.latitude === 0 ?
                        <div style={{fontSize: 24, color: 'var(--rs-btn-primary-text)'}}>{corrId}</div> :
                        <a style={{fontSize: 24}} href="#" onClick={handleMaps}>{corrId}</a>
                    }
                    <div style={{color: '#A4A9B3', display: 'flex', flexDirection: "column", alignItems: "center"}}>
                        <div>{address.street}</div>
                        <div style={{display: "flex", flexDirection: "row", gap: 5}}>
                            <div>{address.city}</div>
                            <div>{address.state}</div>
                            <div>{address.zipcode}</div>
                        </div>
                    </div>
                </div>

                <div style={{display: "flex", justifyContent: "center", marginInlineStart: 40}}>

                    <div style={{...labelStyle, display: "flex", alignItems: "center"}}><span
                        style={spanStyle}>{corrbase.type}</span></div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={labelStyle}>Width</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(corrbase.width / 25.4).toFixed()} "</Tooltip>}>
                            <div><span style={spanStyle}>{corrbase.width}</span> mm</div>
                        </Whisper>
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={labelStyle}>IP-Address</div>
                        <div style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}><span
                            style={spanStyle}>{corrbase.ipaddress}</span></div>
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={labelStyle}>Max-Speed</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(corrbase.speed * 3.281).toFixed()} ft/min</Tooltip>}>
                            <div><span style={spanStyle}>{corrbase.speed}</span> m/min</div>
                        </Whisper>
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={labelStyle}>Wet-End</div>
                        <div><span style={spanStyle}>{corrbase.wetendtype}</span></div>
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={labelStyle}>Dry-End</div>
                        <div><span style={spanStyle}>{corrbase.dryendtype}</span></div>
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={labelStyle}>Drive Side</div>
                        {corrbase.drivesideleft ?
                            <div><span style={spanStyle}>Left</span></div> :
                            <div><span style={spanStyle}>Right</span></div>
                        }
                    </div>

                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={labelStyle}>Startup</div>
                        <div><span style={spanStyle}>{corrbase.startup}</span></div>
                    </div>

                </div>
            </div>
            <hr style={{marginTop: '1rem', marginBottom: '1rem'}}/>
        </div>
    );
}

export default CorrugatorHeader;
