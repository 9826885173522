import React, {useEffect} from "react";
import {Header, Content, Container, Message} from "rsuite";
import {useParams} from "react-router-dom";
import CorrugatorSideBar from "../Components/Corrugator/CorrugatorSideBar";
import CorrugatorContent from "../Components/Corrugator/CorrugatorContent";
import CorrugatorHeader from "../Components/Corrugator/CorrugatorHeader";
import {
    AOCContent, ASMContent, BDCContent, BLCContent, DFContent, FacerContent, GUContent, HBContent,
    HQContent, KQContent, KQFContent, RSContent, SPContent, SRContent, WebtrolContent
} from '../Components/Machines/Contents'
import {MachineName} from "../Resources/StringConstants";
import BusySpinner from "../Components/BusySpinner";
import CorrEditDlg from "../Components/Corrugator/CorrContent/CorrEditDlg";
import {useDispatch, useSelector} from "react-redux";
import {
    FETCH_CORR_OBJ,
    FETCH_DOC_COLL_OBJ, getCorrId, SET_NEW_CORRUGATOR,
    SET_CORR_ID,
    TOGGLE_CORR_BASE_DLG,
    SET_ACTIVE_MACHINE, SET_LOADING
} from "../Reducers/CorrugatorSlice";
import COMContent from "../Components/Machines/Contents/COM/COMContent";
import CCDContent from "../Components/Machines/Contents/CCD/CCDContent";

const CorrugatorPage = () => {
    const tmpCorrId = useParams().corrId;
    const dispatch = useDispatch();
    const corrId = useSelector(getCorrId);
    const isLoading = useSelector((state) => state.corrObjReducer.isLoading);
//    const hasError = useSelector((state) => state.corrObjReducer.hasError);
    const message = useSelector((state) => state.corrObjReducer.message);
    const openDlg = useSelector((state) => state.corrObjReducer.openCorrBaseDlg);
    // console.log('RENDER CORRUGATOR-PAGE --> Id:', corrId);
    const activeMachine = useSelector((state) => state.corrObjReducer.activeMachine);

    // TODO: upload path
    let uploadFilepath = '';

    useEffect(() => {
        console.log('The id here is : ', corrId);
        if (tmpCorrId !== undefined && tmpCorrId !== '') {
            dispatch(SET_CORR_ID(tmpCorrId));
            dispatch(SET_LOADING(true));
            fetchEverything().then(() => {
                dispatch(SET_LOADING(false));
            });
        }
        dispatch(SET_ACTIVE_MACHINE(MachineName.corrugator));
    }, [tmpCorrId, corrId, dispatch]);

    const fetchEverything = async () => {
        await dispatch(FETCH_CORR_OBJ(tmpCorrId));
        await dispatch(FETCH_DOC_COLL_OBJ(tmpCorrId));
    };

    const handleUpload = (para) => {
        if (para.refmachine !== undefined && para.refcategory !== undefined) {
            uploadFilepath = corrId + '/' + para.refmachine + '/' + para.refcategory + '/';
        } else {
            uploadFilepath = corrId + '/various/';
        }
        // console.log('Upload Filepath: ', uploadFilepath);
    };

    const handleOnSelectCustomer = (eventKey) => {
        // console.log('EVENTKEY FROM SIDEBAR --> eventkey', eventKey);
        if (eventKey === undefined) {
            return;
        }
        //setActive(eventKey);
        dispatch(SET_ACTIVE_MACHINE(eventKey));
        if (eventKey === 'newCorr') {
            // console.log('ONCLICK FROM SIDEBAR --> NEW');
            dispatch(SET_NEW_CORRUGATOR(true));
            dispatch(TOGGLE_CORR_BASE_DLG(true));
        } else if (eventKey === 'editCorr') {
            // console.log('ONCLICK FROM SIDEBAR --> EDIT');
            dispatch(SET_NEW_CORRUGATOR(false));
            dispatch(TOGGLE_CORR_BASE_DLG(true));
        }
    };

    const corrugatorContentSwitch = () => {
        switch (activeMachine) {
            case undefined:
                return <CorrugatorContent onUploadClick={handleUpload}/>
            case 'newCorr':
                return <CorrugatorContent onUploadClick={handleUpload}/>
            case 'editCorr':
                return <CorrugatorContent onUploadClick={handleUpload}/>
            case '3':
                return <h3>Contacts</h3>
            case '4':
                return <h3>Open Issues</h3>
            case MachineName.corrugator:
                return <CorrugatorContent onUploadClick={handleUpload}/>
            case '6':
                return <h3>Wet End Menu</h3>
            case MachineName.rs:
                return <h3>Rollstands</h3>
            case MachineName.rs1:
                return <RSContent rsId={1}/>
            case MachineName.rs2:
                return <RSContent rsId={2}/>
            case MachineName.rs3:
                return <RSContent rsId={3}/>
            case MachineName.rs4:
                return <RSContent rsId={4}/>
            case MachineName.rs5:
                return <RSContent rsId={5}/>
            case MachineName.rs6:
                return <RSContent rsId={6}/>
            case MachineName.rs7:
                return <RSContent rsId={7}/>
            case MachineName.sp:
                return <h3>Splicer</h3>
            case MachineName.sp1:
                return <SPContent spId={1}/>
            case MachineName.sp2:
                return <SPContent spId={2}/>
            case MachineName.sp3:
                return <SPContent spId={3}/>
            case MachineName.sp4:
                return <SPContent spId={4}/>
            case MachineName.sp5:
                return <SPContent spId={5}/>
            case MachineName.sp6:
                return <SPContent spId={6}/>
            case MachineName.sp7:
                return <SPContent spId={7}/>
            case MachineName.facer:
                return <h3>Facers</h3>
            case MachineName.facer1:
                return <FacerContent facerId={1}/>
            case MachineName.facer2:
                return <FacerContent facerId={2}/>
            case MachineName.facer3:
                return <FacerContent facerId={3}/>
            case MachineName.blc:
                return <BLCContent/>
            case MachineName.webtrol:
                return <WebtrolContent/>
            case MachineName.gu:
                return <GUContent/>
            case MachineName.df:
                return <DFContent/>
            case '7':
                return <h3>Dry End Menu</h3>
            case MachineName.com:
                return <COMContent/>
            case MachineName.ccd:
                return <CCDContent/>
            case MachineName.kq:
                return <KQContent/>
            case MachineName.hb1:
                return <HBContent hbId={1}/>
            case MachineName.hb2:
                return <HBContent hbId={2}/>
            case MachineName.sr:
                return <SRContent/>
            case MachineName.aoc:
                return <AOCContent/>
            case MachineName.bdc:
                return <BDCContent/>
            case MachineName.kqf:
                return <KQFContent/>
            case MachineName.hq:
                return <HQContent/>
            case MachineName.asm:
                return <ASMContent/>
            default:
                return <h3>Holy Shit...what you did??</h3>
        }
    };

    if (message.error) {
        return (
            <Message showIcon closable type="error" header="Error">{message.msg}</Message>
        );
    } else if (isLoading) {
        return (<BusySpinner title={'BusySpinner'} topic={corrId}/>);
    } else {
        return (
            <Container style={{marginTop: '3.13rem'}}>
                <CorrugatorSideBar active={activeMachine} onSelect={handleOnSelectCustomer}/>

                <Container style={{marginLeft: '1.25rem'}}>
                    <Header>
                        <CorrugatorHeader/>
                    </Header>
                    <Content>
                        {corrugatorContentSwitch()}
                    </Content>
                    <CorrEditDlg open={openDlg} dispatch={dispatch}/>
                </Container>
            </Container>
        );
    }
};

export default CorrugatorPage;
