import React from "react";
import PropTypes from "prop-types";
import {Button} from "rsuite";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import {SelectorSPOption} from "../../Selector/SelectorSPOption";
import SPEditOptionsDlg from "./SPEditOptionsDlg";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const SPOptionContent = ({spId}) => {
    const dispatch = useDispatch();
    const options = SelectorSPOption(spId);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    // console.log(`SP-OPTION-CONETXT ${spId} options-->`, options);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return(
        <>
        <div style={{display: 'flex', justifyContent: 'start'}}>
            <div style={cardStyle}>
                <ContentRow label={'Fintrol'} value={options.fintrol === true ? 'Yes' : 'No'}/>
                <ContentRow label={'SSS intern'} value={options.sss === true ? 'Yes' : 'No'}/>
            </div>
        </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <SPEditOptionsDlg spId={spId} open={openDlg} dispatch={dispatch}/>
        </>
    );
};

SPOptionContent.propTypes = {
    id: PropTypes.number,
};

export default SPOptionContent;
