import React from "react";
import {Button} from "rsuite";
import VisuEditDlg from "./VisuEditDlg";
import {cardStyle, headerCardStyle, hrStyle} from "../../../../Styles/ComponentStyles";
import {ContentRow} from "../../../ContentRow";
import {SelectorMachineGeneral} from "../../Selector/SelectorMachineGeneral";
import {useDispatch, useSelector} from "react-redux";
import {getActiveMachine, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const VisuContent = () => {
    const dispatch = useDispatch();
    const machineFilter = useSelector(getActiveMachine);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const {visu1, visu2} = SelectorMachineGeneral(machineFilter);
    // console.log('VISU-CONTENT MACHINE-FILTER', machineFilter);
    // console.log('VISU-CONTENT --> visu1', visu1);
    // console.log('VISU-CONTENT --> visu2', visu2);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                {(visu1) &&
                    <div style={cardStyle}>
                        <div style={headerCardStyle}>Local</div>
                        <hr style={hrStyle}/>
                        <ContentRow label={'IP Address'} value={visu1.ipaddress}/>
                        <ContentRow label={'Screen Type'} value={visu1.screentype}/>
                        <ContentRow label={'Visu Type'} value={visu1.visutype}/>
                        <ContentRow label={'How to access'} value={visu1.access}/>
                        <ContentRow label={'User name'} value={visu1.username}/>
                        <ContentRow label={'Password'} value={visu1.password}/>
                        <ContentRow label={'Port'} value={visu1.port}/>
                    </div>
                }
                {(visu2) &&
                    <div style={cardStyle}>
                        <div style={headerCardStyle}>Remote</div>
                        <hr style={hrStyle}/>
                        <ContentRow label={'IP Address'} value={visu2.ipaddress}/>
                        <ContentRow label={'Screen Type'} value={visu2.screentype}/>
                        <ContentRow label={'Visu Type'} value={visu2.visutype}/>
                        <ContentRow label={'How to access'} value={visu2.access}/>
                        <ContentRow label={'User name'} value={visu2.username}/>
                        <ContentRow label={'Password'} value={visu2.password}/>
                        <ContentRow label={'Port'} value={visu2.port}/>
                    </div>
                }
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <VisuEditDlg machineFilter={machineFilter} open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default VisuContent;
