import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import BDCOptionContent from "./BDCOptionContent";

const BDCContent = () => {

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<BDCOptionContent/>}/>
        </Panel>
    );
};

export default BDCContent;
