import {useSelector} from "react-redux";
import {getCorrCCD, getCorrId, TOGGLE_DLG, UPDATE_CCD_OPTIONS} from "../../../../Reducers/CorrugatorSlice";
import {cloneDeep, isEqual} from "lodash";
import React, {useState} from "react";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {Button, ButtonToolbar, Modal, Toggle} from "rsuite";
import {cardStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import PropTypes from "prop-types";

const CCDEditOptionsDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const ccdObj = useSelector(getCorrCCD);
    const options = ccdObj.options;
    const tempState = cloneDeep(options);
    const [optObj, setOptObj] = useState(options);
    // console.log('Edit-CCD-DLG', options);

    const handleSaveDlg = async () => {
        await CorrugatorDataServices.updateCorrugator(corrId, {'ccd.options': optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_CCD_OPTIONS(optObj));
            handleCloseDlg();
        }).catch(error => {
            throw new Error("CCD OPTIONS WRITE FUCKED UP -->", error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>CCD - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>

                <div style={cardStyle}>

                    {/*Ligh Barrier or Wheel*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Light Barrier</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.lightbarrier}
                            onChange={checked => setOptObj({...optObj, lightbarrier: checked})}
                        />
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, optObj)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>

        </Modal>
    );
};

CCDEditOptionsDlg.propTypes = {
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default CCDEditOptionsDlg;