import React, {useState} from "react";
import {Button, ButtonToolbar, Modal} from "rsuite";
import {cloneDeep, isEqual} from "lodash";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {
    getCorrId,
    getCorrWebtrol,
    TOGGLE_DLG,
    UPDATE_WEBTROL_OPTIONS
} from "../../../../Reducers/CorrugatorSlice";

const WebtrolEditOptionsDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const webtrolObj = useSelector(getCorrWebtrol);
    const options = webtrolObj.options;
    const tempState = cloneDeep(options);
    const [optObj] = useState(options);
    // console.log('Edit-WEBTROL-DLG', options);

    const handleSaveDlg = async () => {
        await CorrugatorDataServices.updateCorrugator(corrId, {'webtrol.options': optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_WEBTROL_OPTIONS(optObj));
            handleCloseDlg();
        }).catch(error => {
            throw new Error("WEBTROL OPTIONS WRITE FUCKED UP -->", error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>Webtrol - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, optObj)}  onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

WebtrolEditOptionsDlg.propTypes = {
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default WebtrolEditOptionsDlg;
