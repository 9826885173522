import React from 'react';
import {Button} from 'rsuite';
import {cardStyle} from '../../../../Styles/ComponentStyles';
import {ContentRow} from '../../../ContentRow';
import AOCEditOptionsDlg from './AOCEditOptionsDlg';
import {useDispatch, useSelector} from 'react-redux';
import {getCorrAOC, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const AOCOptionContent = () => {
    const dispatch = useDispatch();
    const aocObj = useSelector(getCorrAOC);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    const options = aocObj.options;

    // console.log('AOC-OPTION-CONTENT options-->', options);
    // console.log('AOC-OPTION-CONTENT ---> state', stateStore.getState());

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Standalone'} value={options.standalone === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'HQ-AOC'} value={options.hqaoc === true ? 'Yes' : 'No'}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <AOCEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default AOCOptionContent;
