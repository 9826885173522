import React from "react";
import {Modal} from "rsuite";
import CorrContactForm from "./CorrContactForm";
import {TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const CorrEditContact = ({recordForEdit, addOrEdit, open, dispatch}) => {
    console.log('EditCorr Contact DLG', recordForEdit);

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                {(recordForEdit === null) ? 'New Contact' : 'Edit Contact'}
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <CorrContactForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
            </Modal.Body>
        </Modal>
    );
};

export default CorrEditContact;
