export const rowStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.38rem',
    marginBottom: '0.38rem',
};

export const headerCardStyle = {
    fontSize: '1.1rem',
    color: 'var(--rs-text-tertiary)',
    fontWeight: 'bold'
};

export const cardStyle = {
    display: 'inline-block',
    margin: '0.5rem',
    padding: '0.5rem',
    borderStyle: 'solid',
    borderRadius: '0.5rem',
    borderWidth: 1,
};

export const rowCardStyle = {
    display: "flex",
    alignItems: 'center'
};

export const hrStyle = {
    marginTop: '0.6rem',
    marginBottom: '0.6rem'
};

export const tabStyle = {
    marginBottom: '0.3rem',
    marginTop: '0.3rem',
    marginRight: '0.5rem',
    color: '#e5e5ea',
}

export const labelStyle = {
    display: 'inline-table',
    width: '8.75rem',
    marginBottom: '0.25rem',
    marginTop: '0.25rem',
    color: 'var(--rs-text-tertiary)',
};

export const fieldStyle = {
    display: 'inline-table',
    width: '5.63rem',
    marginBottom: '0.25rem',
    marginTop: '0.25rem',
    color: 'var(--rs-btn-primary-text)',
};
