import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Button, Divider, IconButton, Rate, Tooltip, Whisper} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import {Edit} from "@rsuite/icons";
import {cardStyle, fieldStyle, headerCardStyle, labelStyle, rowCardStyle} from "../../../../Styles/ComponentStyles";
import CorrEditHotel from "./CorrEditHotel";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import cloneDeep from 'lodash/cloneDeep';
import {hotels} from "../../../../InitialDataStructures/Corrugator/Hotels";
import {useDispatch, useSelector} from "react-redux";
import {
    ADD_CORR_HOTEL,
    DELETE_CORR_HOTEL,
    UPDATE_CORR_HOTEL,
    getCorrHotels,
    getCorrId,
    TOGGLE_DLG
} from "../../../../Reducers/CorrugatorSlice";

const Hotel = forwardRef(({hotelObj}, ref) => {
    const oldHotelObj = cloneDeep(hotelObj);
    const dispatch = useDispatch();
    const corrId = useSelector(getCorrId);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);

    const [recordForEdit, setRecordForEdit] = useState(null);
    console.log('CALL OF CORRHOTELCONTENT');

    const handleMaps = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${hotelObj.geo.latitude},${hotelObj.geo.longitude}`, "_blank")
    };

    async function handleDeleteAction() {
        await CorrugatorDataServices.removeCorrugatorHotel(corrId, hotelObj).then((response) => {
            console.log('THEN RETURN VALUE -->', response);
            dispatch(DELETE_CORR_HOTEL(hotelObj));
        }).catch((error) => {
            throw new Error('Failed to delete Corr-Hotel', error);
        })
    }

    const addOrEdit = async (hotel, resetForm) => {
        // if recordForEdit null than we have a new Record otherwise Record exist already
        // if record exist already, delete from collection and write changed record
        if (recordForEdit) {
            await CorrugatorDataServices.removeCorrugatorHotel(corrId, oldHotelObj)
                .then(await CorrugatorDataServices.addCorrugatorHotel(corrId, hotel)).then((response) => {
                    console.log('THEN RETURN VALUE -->', response);
                    dispatch(UPDATE_CORR_HOTEL(hotel));
                }).catch((error) => {
                    throw new Error('Failed to update Corr-Hotel', error);
                })
        } else {
            hotel.id = Date.now().toString();
            await CorrugatorDataServices.addCorrugatorHotel(corrId, hotel).then((response) => {
                console.log('THEN RETURN VALUE -->', response);
                dispatch(ADD_CORR_HOTEL(hotel));
            }).catch((error) => {
                throw new Error('Failed to add Corr-Hotel', error);
            });
        }

        resetForm();
        setRecordForEdit(null);
        dispatch(TOGGLE_DLG(false));
    };

    const openInEditDlg = () => {
        setRecordForEdit(hotelObj);
        dispatch(TOGGLE_DLG(true));
    };

    // is used for calling child function from parent
    useImperativeHandle(ref, () => ({
        handleNewHotel() {
            // TODO: new Hotel
            dispatch(TOGGLE_DLG(true));
            setRecordForEdit(null);
        }
    }));

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    {hotelObj.geo.latitude !== 0 ?
                        <a style={{fontSize: '1.1rem', fontWeight: "bold"}} href="#"
                           onClick={handleMaps}>{hotelObj.name}</a>
                        :
                        <div style={headerCardStyle}>{hotelObj.name}</div>
                    }
                    <div style={{color: 'var(--rs-text-tertiary)'}}>{hotelObj.city} {hotelObj.state} {hotelObj.zipcode}</div>
                    <div style={{color: 'var(--rs-text-tertiary)'}}>{hotelObj.street}</div>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Distance</div>
                        <Whisper placement="top" trigger="hover" speaker={<Tooltip>Hotel to the company</Tooltip>}>
                            <div style={fieldStyle}>{hotelObj.distance} mil</div>
                        </Whisper>
                    </div>

                    <Rate defaultValue={3} disabled={true} size="xs" allowHalf max={5} value={hotelObj.rating}/>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IconButton appearance="subtle" onClick={openInEditDlg} icon={<Edit/>}/>
                        <Divider vertical/>
                        <IconButton appearance="subtle" onClick={handleDeleteAction} icon={<TrashIcon/>}/>
                    </div>
                </div>
            </div>
            <CorrEditHotel recordForEdit={recordForEdit} addOrEdit={addOrEdit} open={openDlg} dispatch={dispatch}/>
        </>
    );
});

const CorrHotelContent = () => {
    const hotelsObj = useSelector(getCorrHotels);
    const childRef = useRef(null);
    console.log('CorrugatorHotel -> HotelObj', {hotelsObj});

    // calling child function
    function handleNewHotel() {
        if (childRef.current)
            childRef.current.handleNewHotel();
    }

    return (
        <>
            {hotelsObj &&
                <>
                    {hotelsObj.map(function (hotel, index) {
                        return (
                            <div style={{display: "inline-block", margin: '0.5rem'}} key={index}>
                                <Hotel ref={childRef} hotelObj={hotel}/>
                            </div>
                        )
                    })};
                </>
            }
            {(!hotelsObj || hotelsObj.length === 0) &&
                <Hotel ref={childRef} hotelsObj={cloneDeep(hotels[0])}/>
            }
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleNewHotel} style={{width: '6rem'}}>New Hotel</Button>
            </div>
        </>
    );
};

export default CorrHotelContent;
