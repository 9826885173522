import {Button, ButtonToolbar, Input, InputNumber, Modal} from "rsuite";
import React from "react";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../Styles/ComponentStyles";
import {
    RESET_UPLOAD_OBJ, SET_UPLOAD_OBJ_CHANGEREASON, SET_UPLOAD_OBJ_DESCRIPTION,
    SET_UPLOAD_OBJ_VERSION, SET_UPLOAD_OBJ_VERSION1, TOGGLE_EDIT_DLG
} from "./UploadSlice";
import {useDispatch, useSelector} from "react-redux";
import {WRITE_UPDATE_DOC_OBJ} from "../Reducers/CorrugatorSlice";

const FileContentEditDlg = ({open}) => {
    const dispatch = useDispatch();
    const uploadObj = useSelector((state) => state.uploadReducer.myUploadObj);

    const handleCloseDlg = () => {
        dispatch(TOGGLE_EDIT_DLG(false));
    };

    const onSubmitClick = async () => {
        // console.log('SAVE UPLOAD OBJ --> uploadObj', uploadObj);
        await dispatch(WRITE_UPDATE_DOC_OBJ(uploadObj)).then((response) => {
            dispatch(RESET_UPLOAD_OBJ());
            handleCloseDlg();
        }).catch((error) => {
            throw new Error('Failed to write Upload obj', error);
        });
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>Edit File Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: "flex", justifyContent: 'center'}}>
                <div style={{...cardStyle, width: 600}}>
                    <div style={rowStyle}>
                        <div style={labelStyle}>Description</div>
                        <Input
                            id='description'
                            as="textarea"
                            rows={2}
                            placeholder='Description....'
                            value={uploadObj.description}
                            onChange={(value) => dispatch(SET_UPLOAD_OBJ_DESCRIPTION(value))}
                        />
                    </div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>Reason for Change</div>
                        <Input
                            id='changereason'
                            as="textarea"
                            rows={2}
                            placeholder='Reason for change....'
                            value={uploadObj.changereason}
                            onChange={(value) => dispatch(SET_UPLOAD_OBJ_CHANGEREASON(value))}
                        />
                    </div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>Version</div>
                        <InputNumber
                            id='version'
                            value={uploadObj.version}
                            onChange={(value) => dispatch(SET_UPLOAD_OBJ_VERSION(value))}
                        />
                    </div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>BHS-Version</div>
                        <Input
                            id='version1'
                            value={uploadObj.version1}
                            onChange={(value) => dispatch(SET_UPLOAD_OBJ_VERSION1(value))}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <hr style={hrStyle}/>
                <ButtonToolbar>
                    <Button onClick={onSubmitClick}>Submit</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    )
};

export default FileContentEditDlg;