import {useSelector} from "react-redux";
import {getCorrObj} from "../../../Reducers/CorrugatorSlice";

export const SelectorSP = (spId) => {
    const corrObj = useSelector(getCorrObj);
    console.log('Selector SP', spId, corrObj.sp1);

    switch (spId) {
        case 1:
            return corrObj.sp1;
        case 2:
            return corrObj.sp2;
        case 3:
            return corrObj.sp3;
        case 4:
            return corrObj.sp4;
        case 5:
            return corrObj.sp5;
        case 6:
            return corrObj.sp6;
         case 7:
            return corrObj.sp7;
        default:
            throw new Error('Unhandled SelectorSplicer TYPE');
    }
};
