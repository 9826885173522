import React from "react";
import {labelStyle, rowStyle} from "../../../Styles/ComponentStyles";
import {InputNumber, InputPicker, Toggle} from "rsuite";
import {dataMotorDrives, dataMotorType} from "../../../Resources/InputFormData";

const MotorEditCard = ({optObj, setOptObj}) => {
    console.log('MotorEditCard -->',optObj);

    return(
        <>

            {/*Main Motor Drive*/}
            <div style={rowStyle}>
                <div style={labelStyle}>Main Drive</div>
                <InputPicker
                    data={dataMotorDrives}
                    id={'mainmotordrive'}
                    placeholder=''
                    cleanable={false}
                    menuClassName='rs-theme-dark'
                    value={optObj.mainmotordrive}
                    onSelect={(value) => setOptObj({...optObj, mainmotordrive: value})}
                    style={{width: '8.13rem'}}
                />
            </div>

            {/*Main Motor Type*/}
            <div style={rowStyle}>
                <div style={labelStyle}>Main Motor</div>
                <InputPicker
                    data={dataMotorType}
                    id={'mainmotortype'}
                    placeholder=''
                    cleanable={false}
                    menuClassName='rs-theme-dark'
                    value={optObj.mainmotortype}
                    onSelect={(value) => setOptObj({...optObj, mainmotortype: value})}
                    style={{width: '8.13rem'}}
                />
            </div>

            {/*Main Motor Power*/}
            <div style={rowStyle}>
                <div style={labelStyle}>Motor Power</div>
                <InputNumber
                    id='motorpower'
                    value={optObj.mainmotorpower}
                    onChange={(value) => setOptObj({...optObj, mainmotorpower: Number(value)})}
                    min={0}
                    max={500}
                    style={{width: 130}}
                />
            </div>

            {/*Main Motor Cooling*/}
            <div style={rowStyle}>
                <div style={labelStyle}>Liquid Cooling</div>
                <Toggle
                    size="md"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={optObj.motorcooling}
                    onChange={checked => setOptObj({...optObj, motorcooling: checked})}
                />
            </div>

        </>
    );
};

export default MotorEditCard;
