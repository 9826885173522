import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import KQFOptionContent from "./KQFOptionContent";

const KQFContent = () => {

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<KQFOptionContent/>}/>
        </Panel>
    );
};

export default KQFContent;
