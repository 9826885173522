import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    user: null,
    userName: '',
};

const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        LOGIN: (state, action) => {
            state.user = action.payload;
            state.userName = action.payload.displayName;
        },
        LOGOUT: (state) => {
            state.user = null;
            state.userName = '';
        },
        SET_USER_NAME: (state, action) => {
            state.userName = action.payload;
        }
    }
});

export const {
    LOGIN,
    LOGOUT,
    SET_USER_NAME
} = userAuthSlice.actions;

export default userAuthSlice.reducer;

export const getUserName = (state) => state.userAuthReducer.userName;