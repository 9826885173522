import TimesheetContent from "../Components/Timesheets/TimesheetContent";
import {TimesheetMenu} from "../TestData/TimesheetMenu";
import {useParams} from "react-router-dom";


const TimesheetPage = () => {
    const key = useParams().key;
    const selectedUser = TimesheetMenu.find(menue => menue.key === key).title;

    return (
        <TimesheetContent selectedUser={selectedUser}/>
    )
};

export default TimesheetPage;