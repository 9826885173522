import {MachineName} from "../../../Resources/StringConstants";
import {useSelector} from "react-redux";
import {getCorrObj} from "../../../Reducers/CorrugatorSlice";

export const SelectorMachineGeneral = (machineFilter) => {
    const corrObj = useSelector(getCorrObj);
    console.log('SELECTOR-MACHINE-GENERAL --> corrObj:', corrObj);
    console.log('SELECTOR-MACHINE-GENERAL --> machineFilter:', machineFilter);

    switch (machineFilter.toLowerCase()) {
        case MachineName.rs1:
            return corrObj.rs1.general;
        case MachineName.rs2:
            return corrObj.rs2.general;
        case MachineName.rs3:
            return corrObj.rs3.general;
        case MachineName.rs4:
            return corrObj.rs4.general;
        case MachineName.rs5:
            return corrObj.rs5.general;
        case MachineName.rs6:
            return corrObj.rs6.general;
        case MachineName.rs7:
            return corrObj.rs7.general;
        case MachineName.sp1:
            return corrObj.sp1.general;
        case MachineName.sp2:
            return corrObj.sp2.general;
        case MachineName.sp3:
            return corrObj.sp3.general;
        case MachineName.sp4:
            return corrObj.sp4.general;
        case MachineName.sp5:
            return corrObj.sp5.general;
        case MachineName.sp6:
            return corrObj.sp6.general;
        case MachineName.sp7:
            return corrObj.sp7.general;
        case MachineName.facer1:
            return corrObj.facer1.general;
        case MachineName.facer2:
            return corrObj.facer2.general;
        case MachineName.facer3:
            return corrObj.facer3.general;
        case MachineName.bbr:
            return corrObj.bbr.general;
        case MachineName.blc:
            return corrObj.blc.general;
        case MachineName.webtrol:
            return corrObj.webtrol.general;
        case MachineName.gu:
            return corrObj.gu.general;
        case MachineName.df:
            return corrObj.df.general;
        case MachineName.com:
            return corrObj.com.general;
        case MachineName.ccd:
            return corrObj.ccd.general;
        case MachineName.aoc:
            return corrObj.aoc.general;
        case MachineName.kq:
            return corrObj.kq.general;
        case MachineName.hb1:
            return corrObj.hb1.general;
        case MachineName.hb2:
            return corrObj.hb2.general;
        case MachineName.sr:
            return corrObj.sr.general;
        case MachineName.bdc:
            return corrObj.bdc.general;
        case MachineName.kqf:
            return corrObj.kqf.general;
        case MachineName.hq:
            return corrObj.hq.general;
        case MachineName.asm:
            return corrObj.asm.general;
        case MachineName.corrugator:
            return;
        default:
            throw new Error(`Unhandled SelectorMachine TYPE: ${machineFilter}`);
    }
};
