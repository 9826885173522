import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import HBOptionContent from "./HBOptionContent";
import {SelectorHB} from "../../Selector/SelectorHB";
import PropTypes from "prop-types";

const HBContent = ({hbId}) => {
    const hbObj = SelectorHB(hbId);

    const handleUpload = (para) => {
        para.refmachine = hbObj.id.replace(/\s+/g, '').toLowerCase();
        //onUploadClick(para);
    };

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel
                optionComponent={<HBOptionContent hbId={hbId}/>}
                onUploadClick={(para) => handleUpload(para)}
            />
        </Panel>
    );
};

HBContent.propTypes = {
    hbId: PropTypes.number,
};

export default HBContent;
