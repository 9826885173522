import React, {useEffect, useRef, useState} from "react";
import {Button, ButtonToolbar, Form} from "rsuite";
import ContactValidation from "./ContactValidation";
import {hrStyle} from "../../../../Styles/ComponentStyles";

const initialFValues = {
    id: '',
    firstname: '',
    name: '',
    jobtitle: '',
    email: '',
    businessphone: '',
    privatephone: ''
};

const CorrContactForm = ({recordForEdit, addOrEdit}) => {
    const [formValues, setFormValues] = useState(initialFValues);       // for field values
    const [formError, setFormError] = useState({});         // for field error messages
    const formRef = useRef();

    useEffect(() => {
        if (recordForEdit != null) {
            setFormValues(recordForEdit);
        }
    }, [recordForEdit])

    const handleReset = () => {
        if (recordForEdit != null) {
            setFormValues(recordForEdit);
        } else {
            setFormValues(initialFValues);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (!formRef.current.check()) {
            return;
        }
        addOrEdit(formValues, handleReset);
    };

    return (
        <Form
            model={ContactValidation}
            ref={formRef}
            onChange={formValues => setFormValues(formValues)}
            onCheck={setFormError}
            formError={formError}
            formValue={formValues}
            onSubmit={handleSubmit}
            layout='horizontal'
        >
            {/*<div style={cardStyle}>*/}
            <Form.Group controlId="jobtitle-9">
                <Form.ControlLabel>Job title</Form.ControlLabel>
                <Form.Control name="jobtitle"/>
                <Form.HelpText>Required</Form.HelpText>
            </Form.Group>

            {/*Hotel name*/}
            <Form.Group controlId="firstname-9">
                <Form.ControlLabel>First name</Form.ControlLabel>
                <Form.Control name="firstname"/>
                <Form.HelpText>Required</Form.HelpText>
            </Form.Group>

            <Form.Group controlId="name-9">
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control name="name"/>
                <Form.HelpText>Required</Form.HelpText>
            </Form.Group>

            <Form.Group controlId="email-9">
                <Form.ControlLabel>Email</Form.ControlLabel>
                <Form.Control name="email"/>
            </Form.Group>

            <Form.Group controlId="businessphone-9">
                <Form.ControlLabel>Office Phone</Form.ControlLabel>
                <Form.Control name="businessphone"/>
            </Form.Group>

            <Form.Group controlId="privatephone-10">
                <Form.ControlLabel>Cell Phone</Form.ControlLabel>
                <Form.Control name="privatephone"/>
            </Form.Group>

            <hr style={hrStyle}/>
            <ButtonToolbar>
                <Button onClick={handleSubmit}>Save</Button>
                <Button onClick={handleReset}>Reset</Button>
            </ButtonToolbar>
        </Form>
    );
};

export default CorrContactForm;
