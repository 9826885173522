import React, {useEffect, useRef, useState} from "react";
import {Button, ButtonToolbar, Input, InputNumber, Modal} from "rsuite";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../Styles/ComponentStyles";
import {FileUploadStruct} from "../InitialDataStructures/Corrugator/FileUploadStruct";
import {v4} from "uuid";
import {UploadState, useUploader} from "../Hooks/useUploader";
import {FileSplitString} from "../Resources/StringConstants";
import {
    getActiveCategory,
    getActiveMachine,
    getCorrId,
    TOGGLE_DLG, WRITE_NEW_DOC_OBJ
} from "../Reducers/CorrugatorSlice";
import {useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import {
    FILL_UPLOAD_OBJ,
    getTargetPath,
    getUploadObj,
    RESET_UPLOAD_OBJ,
    SET_TARGET_PATH,
    SET_UPLOAD_OBJ_CHANGEREASON,
    SET_UPLOAD_OBJ_DESCRIPTION,
    SET_UPLOAD_OBJ_STORAGEPATH,
    SET_UPLOAD_OBJ_VERSION, SET_UPLOAD_OBJ_VERSION1
} from "./UploadSlice";
import {stateStore} from "../Reducers/Redux-Store";
import UploadDiv from "./UploadDiv";
import {getUserName} from "../Pages/Authentication/UserAuthSlice";
import {filesize} from "filesize";

const UploadDlg = ({open, dispatch}) => {
    const activeMachine = useSelector(getActiveMachine);
    const activeCategory = useSelector(getActiveCategory);
    const corrId = useSelector(getCorrId);
    const userName = useSelector(getUserName);
    const inputFile = useRef(null);
    const uploadObj = useSelector(getUploadObj);
    const [showProgressPanel, setShowProgressPanel] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const upload = useUploader(useSelector(getTargetPath));

    const canUpload = [uploadObj.description, selectedFile].every(Boolean);
    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    useEffect(() => {
        if (upload.state === UploadState.COMPLETED) {
            console.log('COMPLETED ->:', upload.fileUrl);
            dispatch(SET_UPLOAD_OBJ_STORAGEPATH(upload.fileUrl));
            writeFileObjToFirebase().then(() => {
                upload.reset();
                handleClose();
            });
        } else if (upload.state === UploadState.CANCELED) {
            upload.reset();
            setShowProgressPanel(false);
        } else if (upload.state === UploadState.FAILED) {
            console.error('UPLOAD FAILED', upload);
            setShowProgressPanel(false);
        }
    }, [upload.state]);

    const writeFileObjToFirebase = async () => {
        // fucking dispatch is too slow, so read the state again to make sure storagepath has a value
        const state = stateStore.getState();
        const tmp = state.uploadReducer.myUploadObj;
        if (tmp.storagepath && tmp.storagepath !== '') {
            await dispatch(WRITE_NEW_DOC_OBJ(tmp)).catch((error) => {
                throw new Error('Failed to write file', error);
            });
        }
    };

    const handleClose = () => {
        setShowProgressPanel(false);
        setSelectedFile(null);
        dispatch(RESET_UPLOAD_OBJ());
        handleCloseDlg();
    };

    // On each change let user have access to a selected file
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    function onUploadClick() {
        const tmp = cloneDeep(FileUploadStruct);
        const docId = v4();
        tmp.id = docId;
        tmp.refcollection = corrId;
        tmp.refmachine = activeMachine;
        tmp.refcategory = activeCategory;
        tmp.timestamp = Date.now();
        tmp.user = userName;
        tmp.filename = selectedFile.name + FileSplitString + docId;
        tmp.changereason = uploadObj.changereason;
        tmp.description = uploadObj.description;
        tmp.version = uploadObj.version;
        dispatch(FILL_UPLOAD_OBJ(tmp));
        dispatch(SET_TARGET_PATH([tmp.refcollection, '/', tmp.refmachine, '/', tmp.refcategory, '/', tmp.filename].join('')));

        // before we write to document collection we will upload....
        setShowProgressPanel(true);
        upload.start(selectedFile, null);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>Upload File</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: "flex", justifyContent: 'center'}}>
                <div style={{...cardStyle, width: 600}}>
                    <div style={rowStyle}>
                        <div style={labelStyle}>Description</div>
                        <Input
                            id='description'
                            as="textarea"
                            rows={2}
                            placeholder='Description....'
                            value={uploadObj.description}
                            onChange={(value) => dispatch(SET_UPLOAD_OBJ_DESCRIPTION(value))}
                        />
                    </div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>Reason for Change</div>
                        <Input
                            id='changereason'
                            as="textarea"
                            rows={2}
                            placeholder='Reason for change....'
                            value={uploadObj.changereason}
                            onChange={(value) => dispatch(SET_UPLOAD_OBJ_CHANGEREASON(value))}
                        />
                    </div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>Version</div>
                        <InputNumber
                            id='version'
                            value={uploadObj.version}
                            onChange={(value) => dispatch(SET_UPLOAD_OBJ_VERSION(value))}
                        />
                    </div>
                    <div style={rowStyle}>
                        <div style={labelStyle}>BHS-Version</div>
                        <Input
                            id='version1'
                            value={uploadObj.version1}
                            onChange={(value) => dispatch(SET_UPLOAD_OBJ_VERSION1(value))}
                        />
                    </div>
                    {selectedFile &&
                        <>
                            <hr style={hrStyle}/>
                            <div style={rowStyle}>
                                <div style={labelStyle}>Filename</div>
                                <div style={{...labelStyle, width: 360}}>{selectedFile.name}</div>
                            </div>
                            <div style={rowStyle}>
                                <div style={labelStyle}>Size</div>
                                <div style={labelStyle}>{filesize(selectedFile.size)}</div>
                            </div>
                        </>
                    }
                    {(selectedFile && showProgressPanel) &&
                        <UploadDiv selectedFile={selectedFile} upload={upload}/>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <hr style={hrStyle}/>
                <ButtonToolbar>
                    <Button disabled={upload.state === UploadState.UPLOADING} style={{float: "left"}} onClick={() => inputFile.current.click()}>Select File</Button>
                    <input type='file' id='file' ref={inputFile} style={{display: "none"}}
                           onChange={handleFileSelect}/>
                    <Button disabled={!canUpload} onClick={onUploadClick}>Upload</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadDlg;
