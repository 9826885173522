import {differenceInCalendarDays, differenceInHours, intervalToDuration, isAfter, isValid} from "date-fns";

export function timeRoundToNearest30(date = new Date()) {
    const minutes = 30;
    const ms = 1000 * 60 * minutes;

    return new Date(Math.round(date.getTime() / ms) * ms);
}

export function getTimeSheetHours(endtime, starttime) {
    const duration = intervalToDuration({
        start: new Date(starttime),
        end: new Date(endtime)
    });

    // console.log('getTimeSheetHours -> duration: ', duration);

    let hoursInYears = 0;
    let hoursInMonths = 0;
    let hoursInDays = 0;
    let hours = 0;
    // let hoursInMinutes = 0;

    if (duration.years > 0) {
        hoursInYears = duration.years * 365 * 8;
    }

    if (duration.months > 0) {
        hoursInMonths = duration.months * 30 * 8;
    }

    if (duration.days > 0) {
        hoursInDays = (duration.days + 1) * 8;
    }

    if (differenceInCalendarDays(endtime, starttime) === 0) {
        if (duration.minutes <= 30 && duration.minutes > 0) {
            hours = duration.hours + 0.5;
        } else if (duration.minutes > 30 && duration.minutes <= 59) {
            hours = duration.hours + 1;
        } else {
            hours = duration.hours;
        }
    }

    // console.log(`CalendarDays: ${differenceInCalendarDays(endtime, starttime)}`);
    // console.log(`DURATION: - Years: ${duration.years} Month: ${duration.months} Days: ${duration.days} Hours: ${duration.hours} Minutes: ${duration.minutes}`);
    // console.log(`STUNDEN: - Years: ${hoursInYears} Month: ${hoursInMonths} Days: ${hoursInDays} Hours: ${hours}`);

    return Number((hoursInYears + hoursInMonths + hoursInDays + hours).toFixed(1));
}

export function getTimeSheetDays(endtime, starttime) {
    const startDate = new Date(starttime);
    const endDate = new Date(endtime);

    if (isValid(startDate) && isValid(endDate)) {
        if (isAfter(endDate, startDate)) {
            // console.log('getTimeSheetDays -> EndTime', endDate);
            // console.log('getTimeSheetDays -> StartTime', startDate);

            const days = differenceInCalendarDays(endDate, startDate);
            // console.log('getTimeSheetDays -> Days:', days);
            if (days && days >= 1) {
                return days + 1;
            } else {
                // is the same day but over 8 hours return one day
                if (differenceInHours(endDate, startDate) >= 8) {
                    return 1;
                }
                return 0;
            }
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

getTimeSheetDays(1685868400, 1675260000);

export function roundMillisecondsToHour(milliseconds) {
    const p = 60 * 60 * 1000; // milliseconds in an hour
    return Math.round(milliseconds / p) * p;
}

export function HourTest(endDate, startDate)
{
    console.log('HourTest',getTimeSheetHours(endDate, startDate));
}

// HourTest(1677438000000, 1676484000000);

// export function Hallo() {
//     const start = (new Date(2023, 1, 14, 7, 32, 37, 899)).getTime();
//     const end = (new Date(2023, 1, 25, 17, 30, 0, 0)).getTime();
//     console.log(`MS Start: ${start}`);
//     console.log(`MS End: ${end}`);
//
//     const roundStart = roundMillisecondsToHour(start);
//     const roundEnd = roundMillisecondsToHour(end);
//     console.log(`Round in MS Start: ${roundStart}`);
//     console.log(`Round in MS End: ${roundEnd}`);
//
//     const start_date = new Date(roundStart);
//     const end_date = new Date(roundEnd);
//     console.log(`Round in Dateformat Start: ${start_date}`);
//     console.log(`Round in Dateformat End: ${end_date}`);
// }
//
// Hallo();