import React from "react";
import {Button} from "rsuite";
import {
    cardStyle, fieldStyle, headerCardStyle, hrStyle, labelStyle, rowCardStyle
} from "../../../../Styles/ComponentStyles";
import SREditOptionsDlg from "./SREditOptionsDlg";
import {dataSRDrives, dataSRProfiles, findLabelForKeyInArray} from "../../../../Resources/InputFormData";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {getCorrSR, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const SROptionContent = () => {
    const dispatch = useDispatch();
    const srObj = useSelector(getCorrSR);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    // console.log('SR-OPTION-CONETXT srObj ->', srObj);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Auto Calibration'}
                                value={srObj.options.autocalibration === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Auto Lubrication'}
                                value={srObj.options.autolubrication === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Auxiliary Station'} value={srObj.options.auxiliary === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Trim Knife'} value={srObj.options.trimknife === true ? 'Yes' : 'No'}/>
                    <hr style={hrStyle}/>
                    <ContentRow label={'Number of Knives'} value={srObj.options.knifes}/>
                    <ContentRow label={'Number of Scorers'} value={srObj.options.scorers}/>
                    <ContentRow label={'Drive Type'}
                                value={findLabelForKeyInArray(dataSRDrives, srObj.options.tooldrive)}/>
                </div>

                {/*profiles section 1*/}
                <div style={cardStyle}>
                    <div style={headerCardStyle}>Section 1</div>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Profile 1</div>
                        <div style={fieldStyle}>{findLabelForKeyInArray(dataSRProfiles, srObj.section1.profile1)}</div>
                        <div style={fieldStyle}>{srObj.section1.offsetprofile1}</div>
                    </div>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Profile 2</div>
                        <div style={fieldStyle}>{findLabelForKeyInArray(dataSRProfiles, srObj.section1.profile2)}</div>
                        <div style={fieldStyle}>{srObj.section1.offsetprofile2}</div>
                    </div>
                    <hr style={hrStyle}/>
                    <div style={headerCardStyle}>Section 2</div>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Profile 1</div>
                        <div style={fieldStyle}>{findLabelForKeyInArray(dataSRProfiles, srObj.section2.profile1)}</div>
                        <div style={fieldStyle}>{srObj.section2.offsetprofile1}</div>
                    </div>
                    <div style={rowCardStyle}>
                        <div style={labelStyle}>Profile 2</div>
                        <div style={fieldStyle}>{findLabelForKeyInArray(dataSRProfiles, srObj.section2.profile2)}</div>
                        <div style={fieldStyle}>{srObj.section2.offsetprofile2}</div>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <SREditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default SROptionContent;
