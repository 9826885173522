import React, {useRef, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {useUserAuth} from './UserAuthContext';
import {Button, ButtonToolbar, Form, Content, FlexboxGrid, Panel, Container, Message, InputGroup} from 'rsuite';
import LoginValidation from "../../Validation/LoginValidation";
import VisibleIcon from "@rsuite/icons/Visible";
import UnvisibleIcon from "@rsuite/icons/Unvisible";


const LoginPage = () => {
    // console.log('RENDER LoginPage');
    const formRef = useRef();
    const [formValue, setFormValue] = useState({}); // for field values
    const [formError, setFormError] = useState({}); // for field error messages
    const [error, setError] = useState(''); // for form error message
    const [visible, setVisible] = useState(false);
    const {logIn} = useUserAuth();
    const navigate = useNavigate();

    const canLogin = [formValue.email, formValue.password].every(Boolean);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formRef.current.check()) {
            // console.error('LOGIN PAGE -> something missing');
            return;
        }

        await logIn(formValue.email, formValue.password).then(() => {
                navigate("/home");
            }).catch(err => {
                setError(err.message);
            })
    };

    return (
        <Container>
            <Content style={{marginTop: '6.25rem'}}>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item>
                        {error &&
                            <Message showIcon type="error" header="Error">{error}</Message>
                        }
                        <Panel header={<h3>Login</h3>} bordered>
                            <Form
                                // layout='horizontal'
                                model={LoginValidation}
                                ref={formRef}
                                onChange={setFormValue}
                                onCheck={setFormError}
                                formError={formError}
                                formDefaultValue={formValue}
                            >
                                <Form.Group>
                                    <Form.ControlLabel>Email</Form.ControlLabel>
                                    <Form.Control type='email' name='email'/>
                                    <Form.HelpText tooltip>Email is required</Form.HelpText>
                                </Form.Group>

                                <Form.Group>
                                    <Form.ControlLabel>Password</Form.ControlLabel>
                                    <InputGroup inside>
                                        <Form.Control type={visible ? 'text' : 'password'} name="password" autoComplete="off"/>
                                        <InputGroup.Button onClick={() => {
                                            setVisible(!visible);
                                        }}>
                                            {visible ? <VisibleIcon/> : <UnvisibleIcon/>}
                                        </InputGroup.Button>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group>
                                    <ButtonToolbar>
                                        <Button type='submit' appearance="primary" onClick={handleSubmit} disabled={!canLogin}>
                                            Sign in
                                        </Button>
                                        <Button appearance="link" as={NavLink} to="/revoverpassword">
                                            Forgot password?
                                        </Button>
                                    </ButtonToolbar>
                                </Form.Group>

                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    );
};

export default LoginPage;
