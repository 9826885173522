import React from "react";
import {Button} from "rsuite";
import {cardStyle, headerCardStyle, hrStyle} from "../../../../Styles/ComponentStyles";
import MotorCard from "../MotorCard";
import {ContentRow} from "../../../ContentRow";
import {dataKnifebar, findLabelForKeyInArray} from "../../../../Resources/InputFormData";
import HQEditOptionsDlg from "./HQEditOptionsDlg";
import {useDispatch, useSelector} from "react-redux";
import {getCorrHQ, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const HQOptionContent = () => {
    const dispatch = useDispatch();
    const hqObj = useSelector(getCorrHQ);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    // console.log('HQ-OPTION-CONTENT hqObj-->', hqObj);
    // console.log('HQ-OPTION-CONTENT ---> state', stateStore.getState());

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Level'} value={hqObj.options.level}/>
                    <hr style={hrStyle}/>
                    <MotorCard optObj={hqObj.options}/>
                </div>
                <div style={cardStyle}>
                    <ContentRow label={'Double Motor Outfeed'}
                                value={hqObj.options.doubleoutfeed === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Load Control'} value={hqObj.options.loadcontrol === true ? 'Yes' : 'No'}/>
                    <ContentRow label={'Waste Flaps'} value={hqObj.options.wasteflap === true ? 'Yes' : 'No'}/>
                    <hr style={hrStyle}/>
                    <ContentRow label={'Type of Knifebar'}
                                value={findLabelForKeyInArray(dataKnifebar, hqObj.options.knifebar)}/>
                </div>
                <div style={cardStyle}>
                    <div style={headerCardStyle}>Cutt off PC</div>
                    <ContentRow label={'PC Type'} value={hqObj.options.cutoffpctype}/>
                    <ContentRow label={'Software Vers.'} value={hqObj.options.cutoffpcversion}/>
                    <hr style={hrStyle}/>
                    <ContentRow label={'HQ-1'} value={hqObj.options.cutoffpcipaddress[1]}/>
                    {(hqObj.options.level === 'TRIPLEX' || hqObj.options.level === 'DUPLEX') &&
                        <ContentRow label={'HQ-2'} value={hqObj.options.cutoffpcipaddress[2]}/>
                    }
                    {(hqObj.options.level === 'TRIPLEX') &&
                        <ContentRow label={'HQ-3'} value={hqObj.options.cutoffpcipaddress[3]}/>
                    }
                </div>
                {(hqObj.preprint1.exist || hqObj.preprint2.exist || hqObj.preprint3.exist) &&
                    <>
                        <div style={cardStyle}>
                            <div style={headerCardStyle}>Pre Print</div>
                            {hqObj.preprint1.exist &&
                                <div>
                                    <div style={{display: "flex"}}>
                                        <ContentRow label={'Top Heads'} value={hqObj.preprint1.bottomheads}/>
                                        <ContentRow label={'Bottom Heads'} value={hqObj.preprint1.topheads}/>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <ContentRow label={'Color'}
                                                    value={hqObj.preprint1.color === true ? 'Yes' : 'No'}/>
                                        <ContentRow label={'Camera'}
                                                    value={hqObj.preprint1.camera === true ? 'Yes' : 'No'}/>
                                    </div>
                                </div>
                            }
                            <hr style={hrStyle}/>
                            {hqObj.preprint2.exist &&
                                <div>
                                    <div style={{display: "flex"}}>
                                        <ContentRow label={'Top Heads'} value={hqObj.preprint2.bottomheads}/>
                                        <ContentRow label={'Bottom Heads'} value={hqObj.preprint2.topheads}/>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <ContentRow label={'Color'}
                                                    value={hqObj.preprint2.color === true ? 'Yes' : 'No'}/>
                                        <ContentRow label={'Camera'}
                                                    value={hqObj.preprint2.camera === true ? 'Yes' : 'No'}/>
                                    </div>
                                </div>
                            }
                            <hr style={hrStyle}/>
                            {/*{state.preprint3 &&*/}
                            {/*    <div>*/}
                            {/*        <div style={{display: "flex"}}>*/}
                            {/*            <ContentRow label={'Top Heads'} value={state.preprint3.bottomheads}/>*/}
                            {/*            <ContentRow label={'Bottom Heads'} value={state.preprint3.topheads}/>*/}
                            {/*        </div>*/}
                            {/*        <div style={{display: "flex"}}>*/}
                            {/*            <ContentRow label={'Color'} value={state.preprint3.color === true ? 'Yes' : 'No'}/>*/}
                            {/*            <ContentRow label={'Camera'} value={state.preprint3.camera  === true ? 'Yes' : 'No'}/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                    </>
                }
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <HQEditOptionsDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default HQOptionContent;
