import {useSelector} from "react-redux";
import {getCorrObj} from "../../../Reducers/CorrugatorSlice";

export const SelectorFacerOption = (facerId) => {
    const corrObj = useSelector(getCorrObj);
    console.log('Selector-Facer Option', facerId, corrObj.facer1.options);

    switch (facerId) {
        case 1:
            return corrObj.facer1.options;
        case 2:
            return corrObj.facer2.options;
        case 3:
            return corrObj.facer3.options;
        default:
            throw new Error('Unhandled SelectorFacer type');
    }
};
