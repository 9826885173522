import React, {useState} from "react";
import {Button, ButtonToolbar, Modal, Toggle} from "rsuite";
import {cardStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {isEqual, cloneDeep} from "lodash";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrAOC, getCorrId, TOGGLE_DLG, UPDATE_AOC_OPTIONS} from "../../../../Reducers/CorrugatorSlice";

const AOCEditOptionsDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const aocObj = useSelector(getCorrAOC);
    const options = aocObj.options;
    const tempState = cloneDeep(options);
    const [optObj, setOptObj] = useState(options);
    // console.log('Edit-AOC-DLG', options);

    const handleSaveDlg = async () => {
        await CorrugatorDataServices.updateCorrugator(corrId, {'aoc.options': optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_AOC_OPTIONS(optObj))
            handleCloseDlg();
        }).catch(error => {
            throw new Error("AOC OPTIONS WRITE FUCKED UP -->", error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>AOC - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>

                <div style={cardStyle}>
                    {/*Standalone*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Standalone</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.standalone}
                            onChange={(checked) => setOptObj({...options, standalone: checked})}
                        />
                    </div>
                    {/*HQ AOC*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>HQ AOC</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.hqaoc}
                            onChange={(checked) => setOptObj({...options, hqaoc: checked})}
                        />
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, optObj)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>

        </Modal>
    );
};

AOCEditOptionsDlg.propTypes = {
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default AOCEditOptionsDlg;
