export const DFOptions = {
    addpreheater: false,
    brakeroll: false,
    mainmotordrive: '',
    mainmotorpower: 0,
    mainmotortype: '',
    motorcooling: false,
    numberplates: 0,
    remotecutkq: false,
    tempcontrol: false,         // temperature control
    steamcontrol: '',
    trolsystem: '',             // chaintrol bartrol shoetrol
}
