import {useDispatch, useSelector} from "react-redux";
import {Button, ButtonToolbar, Checkbox, Input, Modal, TagPicker, Toggle} from "rsuite";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../../Styles/ComponentStyles";
import React, {useEffect, useRef, useState} from "react";
import {UploadState, useUploader} from "../../Hooks/useUploader";
import {ASYNC_ADD_KNOWLEDGE_DOC, KNOWLWEDGE_TOGGLE_DLG,} from "./KnowledgeSlice";
import UploadDiv from "../UploadDiv";
import {getUserName} from "../../Pages/Authentication/UserAuthSlice";
import {MachineTagList} from "../../InitialDataStructures/Knowledge/MachineTagList";
import {getTime} from "date-fns";
import {nanoid} from "@reduxjs/toolkit";
import {useImmer} from "use-immer";
import {filesize} from "filesize";

const KnowledgeUploadDlg = ({open}) => {
    const dispatch = useDispatch();
    const userName = useSelector(getUserName);
    const inputFile = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [machinetags, setMachineTags] = useState([]);
    const [mechanic, setMechanic] = useState(false);
    const [version, setVersion] = useState('');
    const [language, setLanguage] = useState(false);
    const [uploadError, setUploadError] = useState(undefined);
    const [storagePath, setStoragePath] = useState('KnowledgeDocs')
    const [showProgressPanel, setShowProgressPanel] = useState(false);
    const [actualKnowledgeObj, setActualKnowledgeObj] = useImmer({});

    // once we start the upload, we can ask for the status and error of the upload (upload.state / upload.error).
    // Is the upload completed, we will get back a fileurl from upload object, which needs to be stored into the
    // KnowledgeDocObject
    const upload = useUploader(storagePath);
    const canUpload = [title, description, machinetags, selectedFile].every(Boolean);

    useEffect(() => {
        if (upload.state === UploadState.COMPLETED) {
            console.info('UPLOAD COMPLETED', upload);
            setActualKnowledgeObj(actualKnowledgeObj.docData.storagepath = upload.fileUrl);
            try {
                dispatch(ASYNC_ADD_KNOWLEDGE_DOC(actualKnowledgeObj)).unwrap();
            } catch (error) {
                throw new Error('Failed to save the Knowledge Doc', error);
            } finally {
                handleCloseDlg();
            }

            //prepareCloseDlg();
        } else if (upload.state === UploadState.CANCELED) {
            console.error('UPLOAD CANCELED', upload);
            setShowProgressPanel(false);
            upload.reset();
        } else if (upload.state === UploadState.FAILED) {
            console.error('UPLOAD FAILED', upload);
            setShowProgressPanel(false);
            setUploadError(upload.error);
        }
    }, [actualKnowledgeObj, dispatch, setActualKnowledgeObj, upload])

    const handleCloseDlg = () => {
        upload.reset();
        setShowProgressPanel(false);
        setTitle('');
        setDescription('');
        setMachineTags([]);
        setVersion('');
        setSelectedFile(null);
        dispatch(KNOWLWEDGE_TOGGLE_DLG(false));
    };

    const onUploadClick = () => {
        const docData = {
            user: userName,
            title: title,
            description: description,
            machinetags: machinetags,
            mechanic: mechanic,
            language: language,
            version: version,
            timestamp: getTime(new Date()),
            filename: selectedFile.name,
            storagepath: undefined // this is actually the returned fileUrl
        };
        setActualKnowledgeObj({id: nanoid(), docData: docData});
        console.log('UPLOAD CLICK KNOWLEDGE_OBJ', actualKnowledgeObj);

        // set the storage path for Uploader
        setStoragePath(['KnowledgeDocs', '/', docData.filename].join(''));

        // START THE UPLOAD -> STATUS available in useEffect()
        console.log('KnowledgeUpload -> Upload.Status: ', upload)
        if (upload.state === UploadState.IDLE) {
            console.log('KnowledgeUpload -> has started');
            setShowProgressPanel(true);
            upload.start(selectedFile, null);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>Upload Knowledge Document</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: "flex", justifyContent: 'center'}}>
                <div style={{...cardStyle, width: 600}}>
                    {/*Title*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Title</div>
                        <Input
                            id='title'
                            placeholder='Title....'
                            value={title}
                            onChange={(value) => setTitle(value)}
                        />
                    </div>
                    {/*Description*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Description</div>
                        <Input
                            id='description'
                            as="textarea"
                            rows={2}
                            placeholder='Description....'
                            value={description}
                            onChange={(value) => setDescription(value)}
                        />
                    </div>
                    {/*MachineTags*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Machine Tags</div>
                        <TagPicker
                            id='machinetags'
                            placeholder='Select...'
                            searchable
                            data={MachineTagList}
                            value={machinetags}
                            onChange={(value) => setMachineTags(value)}
                            style={{width: 350}}
                        />
                    </div>
                    {/*Mechanic*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Mechanic</div>
                        <Checkbox
                            id='mechanic'
                            checked={mechanic}
                            onChange={(value, checked) => setMechanic(checked)}
                            style={{width: 350}}
                        />
                        <div style={labelStyle}>Language</div>
                        <Toggle
                            id='language'
                            checkedChildren="DE"
                            unCheckedChildren="EN"
                            checked={language}
                            onChange={(checked) => setLanguage(checked)}
                            style={{width: 350}}
                        />
                    </div>
                    {/*Version*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Version</div>
                        <Input
                            id='version'
                            placeholder='Version....'
                            value={version}
                            onChange={(value) => setVersion(value)}
                        />
                    </div>
                    {/*Filename and Size*/}
                    {selectedFile &&
                        <>
                            <hr style={hrStyle}/>
                            <div style={rowStyle}>
                                <div style={labelStyle}>Filename</div>
                                <div style={{...labelStyle, width: 360}}>{selectedFile.name}</div>
                            </div>
                            <div style={rowStyle}>
                                <div style={labelStyle}>Size</div>
                                <div style={labelStyle}>{filesize(selectedFile.size)}</div>
                            </div>
                        </>
                    }
                    {/*Upload Panel*/}
                    {(selectedFile && showProgressPanel) &&
                        <UploadDiv selectedFile={selectedFile} upload={upload}/>
                    }
                    {/*Error*/}
                    {uploadError &&
                        <>
                            <div>Error</div>
                            <div>{uploadError.message} in {uploadError.fileName} at Row {uploadError.lineNumber}</div>
                        </>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <hr style={hrStyle}/>
                <ButtonToolbar>
                    <Button disabled={upload.state === UploadState.UPLOADING} style={{float: "left"}} onClick={() => inputFile.current.click()}>Select File</Button>
                    <input type='file' id='file' ref={inputFile} style={{display: "none"}} onChange={(event) => setSelectedFile(event.target.files[0])}/>
                    <Button disabled={!canUpload} onClick={onUploadClick}>Upload</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    )
};

export default KnowledgeUploadDlg;