import {Badge, Nav} from "rsuite";
import React from "react";
import PropTypes from "prop-types";
import {MachineTab} from "../../Resources/StringConstants";
import {tabStyle} from "../../Styles/ComponentStyles";
import {SelectorMachineGeneral} from "./Selector/SelectorMachineGeneral";
import {useSelector} from "react-redux";
import {getActiveMachine} from "../../Reducers/CorrugatorSlice";

const MachineNavBar = ({onSelect, active, countArray, ...props}) => {
    const machineName = useSelector(getActiveMachine);
    const genObj = SelectorMachineGeneral(machineName);

    return (
        <Nav {...props} activeKey={active} onSelect={onSelect} justified>
            <Nav.Item eventKey={MachineTab.general}>
                <div style={{...tabStyle, color: '#e5e5ea'}}>General Info</div>
            </Nav.Item>
            <Nav.Item eventKey={MachineTab.options}>
                <div style={{...tabStyle, color: '#e5e5ea'}}>Machine Options</div>
            </Nav.Item>
            <Nav.Item eventKey={MachineTab.visu}>
                <div style={{...tabStyle, color: '#e5e5ea'}}>Visu</div>
            </Nav.Item>
            <Nav.Item eventKey={MachineTab.drawings}>
                {countArray[0] >= 1 ?
                    (<Badge content={countArray[0]}>
                        <div style={{...tabStyle, color: '#e5e5ea'}}>E-Drawings</div>
                    </Badge>) :
                    (<div style={{...tabStyle, color: '#e5e5ea'}}>E-Drawings</div>)
                }
            </Nav.Item>
            <Nav.Item eventKey={MachineTab.drives}>
                {countArray[1] >= 1 ?
                    (<Badge content={countArray[1]}>
                        <div style={{...tabStyle, color: '#e5e5ea'}}>Drive Parameters</div>
                    </Badge>) :
                    (<div style={{...tabStyle, color: '#e5e5ea'}}>Drive Parameters</div>)
                }
            </Nav.Item>
            <Nav.Item eventKey={MachineTab.plc}>
                {countArray[2] >= 1 ?
                    (<Badge content={countArray[2]}>
                        <div style={{...tabStyle, color: '#e5e5ea'}}>PLC Programs</div>
                    </Badge>) :
                    (<div style={{...tabStyle, color: '#e5e5ea'}}>PLC Programs</div>)
                }
            </Nav.Item>
            <Nav.Item eventKey={MachineTab.various}>
                {countArray[3] >= 1 ?
                    (<Badge content={countArray[3]}>
                        <div style={{...tabStyle, color: '#e5e5ea'}}>Various Docus</div>
                    </Badge>) :
                    (<div style={{...tabStyle, color: '#e5e5ea'}}>Various Docus</div>)
                }
            </Nav.Item>
            <Nav.Item eventKey={MachineTab.info}>
                {genObj.info !== undefined && genObj.info.length >= 1 ?
                    (<Badge content={'!'}>
                        <div style={{...tabStyle, color: '#e5e5ea'}}>Info</div>
                    </Badge>) :
                    (<div style={{...tabStyle, color: '#e5e5ea'}}>Info</div>)
                }
            </Nav.Item>

        </Nav>
    );
};

MachineNavBar.propTypes = {
    active: PropTypes.string,
    countArray: PropTypes.array,
    onSelect: PropTypes.func,
};

const MachineHeaderNavBar = ({onSelect, active, countArray}) => {
    return (
        <MachineNavBar appearance="tabs" onSelect={onSelect} active={active} countArray={countArray}/>
    );
};

MachineHeaderNavBar.propTypes = {
    active: PropTypes.string,
    countArray: PropTypes.array,
    onSelect: PropTypes.func,
};

export default MachineHeaderNavBar;
