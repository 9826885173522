export const ASMOptions = {
    autodropheight: false,
    doublemotortable: false,
    encodertable: false,
    saftyplc: false,
    level: '',                                   // simple, duplex, triplex
    syncbrush: false,
    wastebelt: false,
    chamberlength: {1: 0, 2: 0, 3: 0},
    dischargeside: {1: 1, 2: 1, 3: 2},           // 1 = OS 2 = BS  3 = Both
    brushtype: {1: 0, 2: 0, 3: 0},               // 1 = OS 2 = BS  3 = Both
}
