export const HQOptions = {
    doubleoutfeed: false,
    knifebar: '',           // Steel , Carbon
    level: '',              // simple, duplex, triplex
    loadcontrol: false,
    mainmotordrive: '',
    mainmotorpower: 0,
    mainmotortype: '',
    motorcooling: false,
    wasteflap: false,
    cutoffpctype: '',
    cutoffpcversion: '',
    cutoffpcipaddress: {1: '', 2: '', 3: ''},
}
