export const KQFOptions = {
    evacuation: '',
    mainmotordrive: '',
    mainmotorpower: 0,
    mainmotortype: '',
    motorcooling: false,
    speedcut1x: 0,
    speedcutnx: 0,
    knifebar: '',
}
