import {str_All} from "../Helpers/GeneralHelpers";

export const Facer_KnowledgeData = [
    {'label': 'MF-A', 'value': 'MFA'},
    {'label': 'MF-B', 'value': 'MFB'},
    {'label': 'AF-P', 'value': 'AFP'},
    {'label': 'QF-P', 'value': 'QFP'},
    {'label': 'EF-P', 'value': 'EF-P'},
];

export const Category_KnowledgeData = [
    {'label': 'Mechanical', 'value': 'mechanical'},
    {'label': 'Electrical', 'value': 'electrical'},
    {'label': str_All, 'value': 'all'},
];