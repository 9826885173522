import {MachineName} from "../Resources/StringConstants";

export const str_All = 'All';
export const str_idle = 'idle';
export const str_succeeded = 'succeeded';
export const str_loading = 'loading';
export const str_failed = 'failed';
export const str_NEW = 'NEW';
export const str_EDIT = 'EDIT';
export const str_DELETE = 'DELETE';
export const str_DOWNLOAD = 'DOWNLOAD';

export function GetMachineNameForTimeSheet() {
    const temp = Object.values(MachineName);
    // add entrie Upgarde
    temp.push('upgrade');
    return temp.sort();
}


