import React from "react";
import {Panel, Content, FlexboxGrid, Container, Button} from "rsuite";
import {useUserAuth} from "./Authentication/UserAuthContext";

const DashboardPage = () => {
    const {user} = useUserAuth();
    // console.log('RENDER DASHBOARD PAGE --> user', user);

    function handleChangeName() {
        //changeDisplayName('Steve');
    }

    return (
        <Container>
            <Content style={{marginTop: '3.13rem'}}>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={18}>
                        <Panel header={<h2>Hello, {String(user?.displayName).toUpperCase()}</h2>} bordered>
                            <Button onClick={handleChangeName}> Change Name</Button>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    );
};

export default DashboardPage;
