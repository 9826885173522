import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import PropTypes from "prop-types";
import MachinePanel from "../MachinePanel";
import RSOptionContent from "./RSOptionContent";
import {SelectorRS} from "../../Selector/SelectorRS";

const RSContent = ({rsId}) => {
    const rsObj = SelectorRS(rsId);

    const handleUpload = (para) => {
        //remove white space
        para.refmachine = rsObj.id.replace(/\s+/g, '').toLowerCase();
    };

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel
                optionComponent={<RSOptionContent rsId={rsId}/>}
                onUploadClick={(para) => handleUpload(para)}
            />
        </Panel>
    );
};

RSContent.propTypes = {
    rsId: PropTypes.number,
};

export default RSContent;
