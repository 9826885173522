import React from "react";
import {Button, Tooltip, Whisper} from "rsuite";
import GeneralEditDlg from "./GeneralEditDlg";
import {cardStyle, fieldStyle, hrStyle, labelStyle} from "../../../../Styles/ComponentStyles";
import {ContentRow} from "../../../ContentRow";
import {SelectorMachineGeneral} from "../../Selector/SelectorMachineGeneral";
import {useDispatch, useSelector} from "react-redux";
import {getActiveMachine, TOGGLE_DLG} from "../../../../Reducers/CorrugatorSlice";

const GeneralContent = () => {
    const dispatch = useDispatch();
    const machineFilter = useSelector(getActiveMachine);
    const genObj = SelectorMachineGeneral(machineFilter);
    const openDlg = useSelector((state) => state.corrObjReducer.openDlg);
    // console.log(`GENRAL-CONTENT-${machineFilter} --> genObj:`, genObj);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Manufacturer'} value={genObj.manufacturer}/>
                    <ContentRow label={'Machine Name'} value={genObj.machinename}/>
                    <ContentRow label={'Machine Type'} value={genObj.machinetype}/>
                    <ContentRow label={'Machine No.'} value={genObj.machinenumber}/>
                    <ContentRow label={'Work No.'} value={genObj.worknumber}/>
                </div>

                <div style={cardStyle}>
                    <ContentRow label={'Year of constr.'} value={genObj.built}/>
                    <hr style={hrStyle}/>
                    <ContentRow label={'Voltage'} value={`${genObj.volt} V`}/>
                    <ContentRow label={'Current'} value={`${genObj.current} A`}/>
                    <ContentRow label={'Apparent Power'} value={`${genObj.kva} KVA`}/>
                </div>

                <div style={cardStyle}>
                    <ContentRow label={'Plc Type'} value={genObj.plctype}/>
                    <ContentRow label={'IP Address'} value={genObj.ipaddressplc}/>
                    <hr style={hrStyle}/>
                    <div>
                        <div style={labelStyle}>Max Speed</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(genObj.speed * 3.281).toFixed()} ft/min</Tooltip>}>
                            <div style={fieldStyle}>{genObj.speed} m/min</div>
                        </Whisper>
                    </div>
                    <div>
                        <div style={labelStyle}>Paper width</div>
                        <Whisper placement="top" trigger="hover"
                                 speaker={<Tooltip>{(genObj.width / 25.4).toFixed()} "</Tooltip>}>
                            <div style={fieldStyle}>{genObj.width} mm</div>
                        </Whisper>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <GeneralEditDlg open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default GeneralContent;
