import {addHours, getTime, startOfHour} from "date-fns";

export const TimesheetStruct = {
    facility: '',
    task: '',
    machine: '',
    starttime: getTime(startOfHour(new Date())),
    endtime: getTime(addHours(startOfHour(new Date()), 1)),
    done: false,
    remote: false,
    user: '',
};
