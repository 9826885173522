export const KnowledgeMenu = [
    {key: 'facer', title: 'Single Facer'},
    {key: 'sp', title: 'Splicer'},
    {key: 'rs', title: 'Rollstand'},
    {key: 'blc', title: 'Bridge'},
    {key: 'webtrol', title: 'Webtrol'},
    {key: 'df', title: 'Double Baker'},
    {key: 'gu', title: 'Glue Unit'},
    {key: 'ph', title: 'Preheater'},
    {key: 'kq', title: 'Rotary Shear'},
    {key: 'aoc', title: 'AOC'},
    {key: 'hb', title: 'Hyper Blade'},
    {key: 'sr', title: 'Slitter Scorer'},
    {key: 'bdc', title: 'BDC'},
    {key: 'webdiverter', title: 'Web-Diverter'},
    {key: 'hq', title: 'Cut Off / Preprint'},
    {key: 'asm', title: 'Stacker'},
    {key: 'desk', title: 'Panel Desk'},
    {key: 'drives', title: 'Drives'},

]