import {geo} from "./Geo";

export const hotels = [{
    id: '',
    name: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    rating: 0,
    distance: 0,
    geo: geo
}]

