import {useSelector} from "react-redux";
import {getCorrObj} from "../../../Reducers/CorrugatorSlice";

export const SelectorSPOption = (id) => {
    const corrObj = useSelector(getCorrObj);
    console.log('SP-OptionHook', id, corrObj.sp1.options);

    switch (id) {
        case 1:
            return corrObj.sp1.options;
        case 2:
            return corrObj.sp2.options;
        case 3:
            return corrObj.sp3.options;
        case 4:
            return corrObj.sp4.options;
        case 5:
            return corrObj.sp5.options;
        case 6:
            return corrObj.sp6.options;
        case 7:
            return corrObj.sp7.options;
        default:
            throw new Error('Unhandled SelectorSplicer Options type');
    }
};
