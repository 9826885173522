import * as React from "react";
import {Routes, Route} from "react-router-dom";
// TODO: Color Theme ?
import 'rsuite/styles/index.less';
import 'rsuite/dist/rsuite.min.css';
import LoginPage from "./Pages/Authentication/LoginPage";
import RecoverPasswordPage from "./Pages/Authentication/RecoverPasswordPage";
import {UserAuthContextProvider} from './Pages/Authentication/UserAuthContext';
import {
    HomePage, DashboardPage, CorrugatorPage, ErrorPage,
    SettingPage, FormInputPage, TestDataPage,
    TestPage, SharedLayout, ProtectedRoute, TimesheetPage
} from './Pages'
import KnowledgePage from "./Pages/KnowledgePage";
import './App.css';
import {useEffect} from "react";
import packageJson from "../package.json";


function App() {
    //set the title of the page regarding the version
    useEffect(() => {
        document.title = '🤞 corrPratt V' + packageJson.version;
        //String.fromCodePoint(0x1F91E)
    }, [])

    return (
        <div className='rs-theme-dark'>
            <UserAuthContextProvider>
                <Routes>
                    <Route exact path="/" element={<LoginPage/>}/>

                    {/*Those are all protected routs*/}
                    <Route path='/home' element={<ProtectedRoute><SharedLayout/></ProtectedRoute>}>
                        <Route index element={<HomePage/>}/>
                        <Route path="dashboard" element={<DashboardPage/>}/>
                        <Route path="knowledge/:key" element={<KnowledgePage/>}/>
                        <Route path="timeSheet/:key" element={<TimesheetPage/>}/>
                        <Route path='corrugator/:corrId' exact element={<CorrugatorPage/>}/>
                        <Route path='testpage' element={<TestPage/>}/>
                        <Route path='testdatapage' element={<TestDataPage/>}/>
                        <Route path='forminput' element={<FormInputPage/>}/>
                        <Route path="settings" element={<SettingPage/>}/>
                    </Route>
                    {/*/!*end of protected routs*!/*/}

                    <Route path="/revoverpassword" element={<RecoverPasswordPage/>}/>
                    <Route path='*' element={<ErrorPage/>}/>
                </Routes>
            </UserAuthContextProvider>
        </div>
    );
}

export default App;
