import React from "react";
import {Button} from "rsuite";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import CorrEditVisu from "./CorrEditVisu";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_CORR_VISU_DLG} from "../../../../Reducers/CorrugatorSlice";

const CorrVisuContent = () => {
    const dispatch = useDispatch();
    const corrObj = useSelector((state) => state.corrObjReducer.corrObj);
    const openDlg = useSelector((state) => state.corrObjReducer.openCorrVisuDlg);

    const corrbase = corrObj.corrbase;
    console.log('CORR-VISU-CONTENT corrbase-->', corrbase);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_CORR_VISU_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'CCC'} value={'What should go in here?'}/>
                    <ContentRow label={'ZDS'} value={'What should go in here?'}/>
                    <ContentRow label={'WEC'} value={'What should go in here?'}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <CorrEditVisu open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default CorrVisuContent;
