import React, {useEffect, useState} from "react";
import {Button, ButtonToolbar, Input, Modal, Toggle} from "rsuite";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {cloneDeep, isEqual} from "lodash";
import {SelectorRSOption} from "../../Selector/SelectorRSOption";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrId, TOGGLE_DLG, UPDATE_RS_OPTIONS} from "../../../../Reducers/CorrugatorSlice";

const RSEditOptionsDlg = ({rsId, open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const options = SelectorRSOption(rsId);
    let tempState = cloneDeep(options);
    const [optObj, setOptObj] = useState(options);
    // console.log(`Edit-RS${rsId}-DLG options`, options);
    // console.log(`Edit-RS${rsId}-DLG optObj`, optObj);

    useEffect(() => {
        tempState = cloneDeep(options);
        setOptObj(options);
    }, [rsId])

    const handleSaveDlg = async () => {
        const tmp = ['rs', rsId.toString(), '.', 'options'].join('');
        await CorrugatorDataServices.updateCorrugator(corrId, {[tmp]: optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_RS_OPTIONS({id: rsId, obj: optObj}));
            handleCloseDlg();
        }).catch(error => {
            throw new Error(`RS${rsId} OPTIONS WRITE FUCKED UP -->`, error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    const handleInputChange = (value, e) => {
        setOptObj({...optObj, [e.target.id]: value});
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='sm'
        >
            <Modal.Header>
                <Modal.Title>RS - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    {/*Auto Alignment*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Auto alignment</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.autoalignment}
                            onChange={checked => setOptObj({...optObj, autoalignment: checked})}
                        />
                    </div>
                    {/*Split roll*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Split roll</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.splitroll}
                            onChange={checked => setOptObj({...optObj, splitroll: checked})}
                        />
                    </div>
                    <hr style={hrStyle}/>
                    {/*Brake Pads*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Brake Pad</div>
                        <Input
                            id='brakepads'
                            placeholder='Brake pads...'
                            value={optObj.brakepads}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, optObj)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    );
};

RSEditOptionsDlg.propTypes = {
    rsId: PropTypes.number,
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default RSEditOptionsDlg;
