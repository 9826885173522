import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import COMOptionContent from "./COMOptionContent";

const COMContent = () => {

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<COMOptionContent/>}/>
        </Panel>
    );
};

export default COMContent;
