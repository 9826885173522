import {getCorrObj} from "../../../Reducers/CorrugatorSlice";
import {useSelector} from "react-redux";

export const SelectorHBOption = (hbId) => {
    const corrObj = useSelector(getCorrObj)
    console.log('HB-OptionHook', hbId, corrObj.hb1.options);

    switch (hbId) {
        case 1:
            return corrObj.hb1.options;
        case 2:
            return corrObj.hb2.options;
        default:
            throw new Error('Unhandled SelectorHyperblade Options type');
    }
};
