// noinspection JSUnusedAssignment

import {Input, Message, Table} from 'rsuite';
import React, {useEffect, useMemo, useState} from "react";
import {CheckBoxCell, ExpandCell, HeaderSummary, MachineCell, TimeDateCell, TimesheetActionCell, TimeSheetCell} from "../TableCell";
import {cloneDeep} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {
    ASYNC_DELETE_TIMESHEET_OBJ,
    getTimesheetArray,
    getTimesheetFetchListError,
    getTimesheetFetchListStatus,
    TIMESHEET_TOGGLE_DLG,
    SET_TIMESHEET_FETCH_LIST_STATUS
} from "./TimesheetSlice";
import {getTimeSheetDays, getTimeSheetHours} from "../../Helpers/DateTimeFunctions";
import {confirmAlert} from "react-confirm-alert";
import TimesheetEditDlg from "./TimesheetEditDlg";
import {str_DELETE, str_EDIT, str_failed, str_idle, str_loading, str_succeeded} from "../../Helpers/GeneralHelpers";

const {Column, HeaderCell, Cell} = Table;

const rowKey = 'id';

const TimesheetTable = () => {
    const dispatch = useDispatch(); // for use without hook -> store.dispatch(SET_ERROR_MSG('error'));
    // const timesheets = cloneDeep(useSelector(getTimesheetArray));
    const timesheets = useSelector(getTimesheetArray);
    const timeSheetStatus = useSelector(getTimesheetFetchListStatus);
    const timeSheetError = useSelector(getTimesheetFetchListError);

    //const [timesheets, setTimesheet] = useState(cloneDeep(useSelector(getTimesheetArray)));
    const openDlg = useSelector((state) => state.timesheetReducer.openTimesheetDlg);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [sumWorkHours, setSumWorkHours] = useState(0.0);
    const [sumWorkDays, setSumWorkDays] = useState(0.0);

    // console.log('TimeSheetTable -> Sheets', timesheets);

    const calculateSummary = useMemo(() => {
        let summaryWorkHours = 0.0;
        let summaryDays = 0.0;
        if (timesheets.length > 0) {
            // console.log('TimeSheetTable -> Length', timesheets.length);
            timesheets.forEach(dicItem => {
                summaryWorkHours += getTimeSheetHours(dicItem.docData.endtime, dicItem.docData.starttime);
                summaryDays += getTimeSheetDays(dicItem.docData.endtime, dicItem.docData.starttime)
            });
            return [summaryWorkHours, summaryDays];
        }
        return [0, 0];
    },[timesheets]);

    useEffect(() => {
        if (timeSheetStatus === str_loading) {
            setLoading(true);
            setErrorLoading(false);
        } else if (timeSheetStatus === str_succeeded) {
            setLoading(false);
            setErrorLoading(false);
            dispatch(SET_TIMESHEET_FETCH_LIST_STATUS(str_idle))
        } else if (timeSheetStatus === str_failed) {
            setLoading(false);
            setErrorLoading(true);
        }
    }, [dispatch, timeSheetStatus]);

    useEffect(() => {
        // console.log('HALLO')
        const summary = calculateSummary;
        setSumWorkHours(summary[0]);
        setSumWorkDays(summary[1])
    }, [calculateSummary]);
    

    const renderRowExpanded = rowData => {
        console.log('RowExpanded -> rowData', rowData);
        return (
            <div style={{display: "flex", width: 700}}>
                <div
                    style={{
                        width: 60,
                        height: 120,
                        marginRight: 15,
                        marginLeft: 40
                    }}
                >
                    <p><b>Task:</b></p>
                </div>
                {/*<p>{rowData.docData.task}</p>*/}
                <Input disabled as="textarea" rows={3} placeholder="Task informations" value={rowData.docData.task}/>
            </div>
        );
    };
    const handleExpanded = (rowData) => {
        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };
    const onTimesheetAction = async (timeSheetActionObj) => {
        const {timesheet, command} = timeSheetActionObj;
        // console.log('TimeSheetAction -> timeSheetActionObj', timeSheetActionObj);
        // console.log('TimeSheetAction -> timeSheetObj', timesheet);

        if (!timeSheetActionObj || timesheet.id === undefined || timesheet.id === '') {
            console.error('TimeSheetAction not an object');
            return;
        }

        if (command === str_EDIT) {
            //setTimeSheetDlg(timeSheetObj);
            //console.log('Edit TimeSheet -> TimeSheet', timeSheetObj);
            dispatch(TIMESHEET_TOGGLE_DLG({open: true, timeSheet: timesheet, mode: str_EDIT}));
        } else if (command === str_DELETE) {
            confirmAlert({
                title: 'Delete Timesheet',
                message: `User: ${timesheet.docData.user} -- Corrugator: ${timesheet.docData.facility} -- Machine: ${timesheet.docData.machine} -- Duration: ${timesheet.docData.duration}h`,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteTimeSheet(timesheet.id)
                    },
                    {
                        label: 'No',
                        onClick: () => console.log('Click No')
                    }
                ]
            });
        }
    };

    const getData = () => {
        if (sortColumn && sortType) {
            let sortList = cloneDeep(timesheets);
            return sortList.sort((a, b) => {
                // console.log('Sort Column:', sortColumn);
                // console.log('Sort Type:', sortType); // asc or desc

                let x;
                let y;

                if (sortColumn === 'durationHours') {
                    x = getTimeSheetHours(a.docData.endtime, a.docData.starttime);
                    y = getTimeSheetHours(b.docData.endtime, b.docData.starttime);
                } else if (sortColumn === 'durationDays') {
                    x = getTimeSheetDays(a.docData.endtime, a.docData.starttime);
                    y = getTimeSheetDays(b.docData.endtime, b.docData.starttime);
                } else {
                    x = a.docData[sortColumn];
                    y = b.docData[sortColumn];
                }

                // console.log('a.docData:', a.docData[sortColumn]);
                // console.log('b.docData:', b.docData[sortColumn]); // asc or desc

                // console.log('X', x);
                // console.log('Y', y);

                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return timesheets;
    };
    const deleteTimeSheet = async (timesheet_id) => {
        console.log('TimeSheetTable delete timesheet', timesheet_id);
        try {
            await dispatch(ASYNC_DELETE_TIMESHEET_OBJ(timesheet_id)).unwrap();
        } catch(error)  {
            throw new Error('Delete Time sheet failed', error);
        }
    };
    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 300);
    };

    if (errorLoading) {
        return (
            <Message showIcon closable type="error" header="Error">{timeSheetError}</Message>
        );
    } else {
        return (
            <div style={{paddingTop: 15}}>
                <Table
                    shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
                    height={420}
                    headerHeight={80}
                    loading={loading}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    data={getData()}
                    rowKey={rowKey}
                    expandedRowKeys={expandedRowKeys}
                    rowExpandedHeight={150}
                    renderRowExpanded={renderRowExpanded}
                    // onRowClick={data => {
                    //     console.log(data);
                    // }}
                >
                    <Column width={50} align="center">
                        <HeaderCell>#</HeaderCell>
                        <ExpandCell dataKey="task" expandedRowKeys={expandedRowKeys} onChange={handleExpanded}/>
                    </Column>

                    <Column width={150} sortable>
                        <HeaderCell>Corrugator</HeaderCell>
                        <TimeSheetCell dataKey="facility"/>
                    </Column>

                    <Column width={130} sortable>
                        <HeaderCell>Machine</HeaderCell>
                        <MachineCell dataKey="machine"/>
                    </Column>

                    <Column width={170} sortable>
                        <HeaderCell>Start</HeaderCell>
                        <TimeDateCell dataKey="starttime"/>
                    </Column>

                    <Column width={170} sortable>
                        <HeaderCell>End</HeaderCell>
                        <TimeDateCell dataKey="endtime"/>
                    </Column>

                    <Column width={100} sortable align='right'>
                        <HeaderCell>
                            <HeaderSummary title="Workhours" summary={sumWorkHours} unit={'h'}/>
                        </HeaderCell>
                        <Cell dataKey='durationHours'>{rowData => `${getTimeSheetHours(rowData.docData.endtime, rowData.docData.starttime)}`}</Cell>
                    </Column>

                    <Column width={80} sortable align='right'>
                        <HeaderCell>
                            <HeaderSummary title="Days" summary={sumWorkDays} unit={'d'}/>
                        </HeaderCell>
                        <Cell dataKey='durationDays'>{rowData => `${getTimeSheetDays(rowData.docData.endtime, rowData.docData.starttime)}`}</Cell>
                    </Column>

                    <Column width={80} sortable align="center">
                        <HeaderCell style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                            <div>Online</div>
                            <div>Support</div>
                        </HeaderCell>
                        <CheckBoxCell dataKey="remote"/>
                    </Column>

                    <Column width={100} sortable align="center">
                        <HeaderCell>Finished</HeaderCell>
                        <CheckBoxCell dataKey="done"/>
                    </Column>

                    <Column width={100} sortable align="center">
                        <HeaderCell>User</HeaderCell>
                        <TimeSheetCell dataKey="user"/>
                    </Column>

                    <Column width={200} >
                        <HeaderCell>Action</HeaderCell>
                        <TimesheetActionCell onTimesheetAction={onTimesheetAction}/>
                    </Column>

                </Table>

                <TimesheetEditDlg open={openDlg}/>

            </div>
        );
    }
};

export default TimesheetTable;