import React from "react";
import {cardStyle} from "../../../../Styles/ComponentStyles";
import {Button} from "rsuite";
import CorrEditIPAdress from "./CorrEditIPAdress";
import {ContentRow} from "../../../ContentRow";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_CORR_IP_DLG} from "../../../../Reducers/CorrugatorSlice";

const CorrIPAdressContent = () => {
    const dispatch = useDispatch();
    const corrObj = useSelector((state) => state.corrObjReducer.corrObj);
    const openDlg = useSelector((state) => state.corrObjReducer.openCorrIPDlg);
    const corrbase = corrObj.corrbase;
    console.log('CORR-IPADDRESS-CONTENT corrbase-->', corrbase);

    const handleOpenDlg = () => {
        dispatch(TOGGLE_CORR_IP_DLG(true));
    };

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={cardStyle}>
                    <ContentRow label={'Remote'} value={corrbase.ipaddress}/>
                    <ContentRow label={'Firewall'} value={corrbase.firewallipaddress}/>
                    <ContentRow label={'Hostsystem'} value={corrbase.hostipaddress}/>
                    <ContentRow label={'Printer'} value={corrbase.printeripaddress}/>
                    <ContentRow label={'Conveyor'} value={corrbase.conveyoripaddress}/>
                    <ContentRow label={'Steam'} value={corrbase.steamipaddress}/>
                </div>
            </div>
            <div style={{display: "flex", marginLeft: '0.5rem', marginTop: '0.5rem'}}>
                <Button onClick={handleOpenDlg} style={{width: '6rem'}}>Edit</Button>
            </div>
            <CorrEditIPAdress open={openDlg} dispatch={dispatch}/>
        </>
    );
};

export default CorrIPAdressContent;
