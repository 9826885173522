import React, {useState} from "react";
import {Button, ButtonToolbar, InputNumber, InputPicker, Modal, Toggle} from "rsuite";
import {cardStyle, hrStyle, labelStyle, rowStyle} from "../../../../Styles/ComponentStyles";
import {dataBrushType, dataDischargeSide, dataLevel} from "../../../../Resources/InputFormData";
import {cloneDeep, isEqual} from "lodash";
import PropTypes from "prop-types";
import CorrugatorDataServices from "../../../../Services/CorrugatorDataServices";
import {useSelector} from "react-redux";
import {getCorrASM, getCorrId, TOGGLE_DLG, UPDATE_ASM_OPTIONS} from "../../../../Reducers/CorrugatorSlice";

const ASMEditOptionsDlg = ({open, dispatch}) => {
    const corrId = useSelector(getCorrId);
    const asmObj = useSelector(getCorrASM);
    const options = asmObj.options;
    const tempState = cloneDeep(options);
    const [optObj, setOptObj] = useState(options);
    console.log('Edit-ASM-DLG', options);

    const handleSaveDlg = async () => {
        await CorrugatorDataServices.updateCorrugator(corrId, {'asm.options': optObj}).then((response) => {
            // console.log('THEN RETURN VALUE -->', response);
            dispatch(UPDATE_ASM_OPTIONS(optObj));
            handleCloseDlg();
        }).catch(error => {
            throw new Error("ASM OPTIONS WRITE FUCKED UP -->", error);
        });
    };

    const handleCloseDlg = () => {
        dispatch(TOGGLE_DLG(false));
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseDlg}
            className='rs-theme-dark'
            style={{marginTop: '2rem'}}
            backdrop={'static'}
            keyboard={false}
            size='lg'
        >
            <Modal.Header>
                <Modal.Title>ASM - Options</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                <div style={cardStyle}>
                    {/*Number of Levels*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>No of Levels</div>
                        <InputPicker
                            data={dataLevel}
                            id={'level'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={optObj.level}
                            onSelect={(value) => setOptObj({...optObj, level: value})}
                            style={{width: 110}}
                        />
                    </div>
                    <hr style={hrStyle}/>
                    {/*Safty Plc*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Safty Plc</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.saftyplc}
                            onChange={checked => setOptObj({...optObj, saftyplc: checked})}
                        />
                    </div>
                    {/*Auto Drop Height*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Auto Drop Height</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.autodropheight}
                            onChange={checked => setOptObj({...optObj, autodropheight: checked})}
                        />
                    </div>
                    {/*Sync Brush*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Sync Brush</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.syncbrush}
                            onChange={checked => setOptObj({...optObj, syncbrush: checked})}
                        />
                    </div>
                    {/*Waste belts*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Waste Belts</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.wastebelt}
                            onChange={checked => setOptObj({...optObj, wastebelt: checked})}
                        />
                    </div>
                </div>
                <div style={cardStyle}>
                    {/*Table double Motor*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Table double Motor</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.doublemotortable}
                            onChange={checked => setOptObj({...optObj, doublemotortable: checked})}
                        />
                    </div>
                    {/*Table Encoder*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>Table Encoder</div>
                        <Toggle
                            size="md"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={optObj.encodertable}
                            onChange={checked => setOptObj({...optObj, encodertable: checked})}
                        />
                    </div>
                    <hr style={hrStyle}/>
                    {/*AS1 Length*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>AS1 Length</div>
                        <InputNumber
                            id='chamberlength1'
                            value={optObj.chamberlength[1]}
                            onChange={(value) => setOptObj({...optObj, chamberlength: {...optObj.chamberlength, 1: Number(value)}})}
                            min={0}
                            max={10000}
                            style={{width: 130}}
                            postfix={'mm'}
                            step={50}
                        />
                    </div>
                    {/*AS2 Length*/}
                    {(optObj.level === 'DUPLEX' || optObj.level === 'TRIPLEX') &&
                        <div style={rowStyle}>
                            <div style={labelStyle}>AS2 Length</div>
                            <InputNumber
                                id='chamberlength2'
                                value={optObj.chamberlength[2]}
                                onChange={(value) => setOptObj({...optObj, chamberlength: {...optObj.chamberlength, 2: Number(value)}})}
                                min={0}
                                max={10000}
                                style={{width: 130}}
                                postfix={'mm'}
                                steps={50}
                            />
                        </div>
                    }
                    {/*AS3 Length*/}
                    {(optObj.level === 'TRIPLEX') &&
                        <div style={rowStyle}>
                            <div style={labelStyle}>AS3 Length</div>
                            <InputNumber
                                id='chamberlength3'
                                value={optObj.chamberlength[3]}
                                onChange={(value) => setOptObj({...optObj, chamberlength: {...optObj.chamberlength, 3: Number(value)}})}
                                min={0}
                                max={10000}
                                style={{width: 130}}
                                postfix={'mm'}
                                steps={50}
                            />
                        </div>
                    }
                </div>
                <div style={cardStyle}>
                    {/*AS1 Discharge Side*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>AS1 Discharge Side</div>
                        <InputPicker
                            data={dataDischargeSide}
                            id={'dischargeside1'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={optObj.dischargeside[1]}
                            onSelect={(value) => setOptObj({...optObj, dischargeside: {...optObj.dischargeside, 1: value}})}
                            style={{width: 100}}
                        />
                    </div>
                    {/*AS2 Discharge Side*/}
                    {(optObj.level === 'DUPLEX' || optObj.level === 'TRIPLEX') &&
                        <div style={rowStyle}>
                            <div style={labelStyle}>AS2 Discharge Side</div>
                            <InputPicker
                                data={dataDischargeSide}
                                id={'dischargeside2'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.dischargeside[2]}
                                onSelect={(value) => setOptObj({...optObj, dischargeside: {...optObj.dischargeside, 2: value}})}
                                style={{width: 100}}
                            />
                        </div>
                    }
                    {/*AS3 Discharge Side*/}
                    {(optObj.level === 'TRIPLEX') &&
                        <div style={rowStyle}>
                            <div style={labelStyle}>AS3 Discharge Side</div>
                            <InputPicker
                                data={dataDischargeSide}
                                id={'dischargeside3'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.dischargeside[3]}
                                onSelect={(value) => setOptObj({...optObj, dischargeside: {...optObj.dischargeside, 3: value}})}
                                style={{width: 100}}
                            />
                        </div>
                    }
                    <hr style={hrStyle}/>
                    {/*AS1 Brush Type*/}
                    <div style={rowStyle}>
                        <div style={labelStyle}>AS1 Brush type</div>
                        <InputPicker
                            data={dataBrushType}
                            id={'brushtype1'}
                            placeholder=''
                            cleanable={false}
                            menuClassName='rs-theme-dark'
                            value={optObj.brushtype[1]}
                            onSelect={(value) => setOptObj({...optObj, brushtype: {...optObj.brushtype, 1: value}})}
                            style={{width: 100}}
                        />
                    </div>
                    {/*AS2 Brush Type*/}
                    {(optObj.level === 'DUPLEX' || optObj.level === 'TRIPLEX') &&
                        <div style={rowStyle}>
                            <div style={labelStyle}>AS2 Brush type</div>
                            <InputPicker
                                data={dataBrushType}
                                id={'brushtype2'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.brushtype[2]}
                                onSelect={(value) => setOptObj({...optObj, brushtype: {...optObj.brushtype, 2: value}})}
                                style={{width: 100}}
                            />
                        </div>
                    }
                    {/*AS3 Brush Type*/}
                    {(optObj.level === 'TRIPLEX') &&
                        <div style={rowStyle}>
                            <div style={labelStyle}>AS3 Brush type</div>
                            <InputPicker
                                data={dataBrushType}
                                id={'brushtype3'}
                                placeholder=''
                                cleanable={false}
                                menuClassName='rs-theme-dark'
                                value={optObj.brushtype[3]}
                                onSelect={(value) => setOptObj({...optObj, brushtype: {...optObj.brushtype, 3: value}})}
                                style={{width: 100}}
                            />
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button disabled={isEqual(tempState, optObj)} onClick={handleSaveDlg}>Save</Button>
                    <Button onClick={handleCloseDlg}>Cancel</Button>
                </ButtonToolbar>
            </Modal.Footer>

        </Modal>
    );
};

ASMEditOptionsDlg.propTypes = {
    open: PropTypes.bool,
    dispatch: PropTypes.func
};

export default ASMEditOptionsDlg;
