import {Panel} from "rsuite";
import MachineHeader from "../../MachineHeader";
import MachinePanel from "../MachinePanel";
import BLCOptionContent from "./BLCOptionContent";

const BLCContent = () => {

    return (
        <Panel>
            <MachineHeader/>
            <MachinePanel optionComponent={<BLCOptionContent/>}/>
        </Panel>
    );
};

export default BLCContent;
