import {general} from "../General";
import {preprint} from "./preprint";
import {HQOptions} from "./HQOptions";

export const hq = {
    general: general,
    options: HQOptions,
    preprint1: preprint,
    preprint2: preprint,
    preprint3: preprint,
}
