import {visu} from "./VisuStruct";

export const general = {
    built: 1900,
    speed: 0,
    width: 0,
    machinenumber: '',      // 46000082
    worknumber: '',
    machinename: '',        // AS-M, MF-B, EFP, AFP, QFP
    machinetype: '',        // only used on new machine
    manufacturer: '',
    ipaddressplc: '',
    plctype: '',
    visu1: visu,            // local
    visu2: visu,            // remote
    volt: 0,
    kva: 0,
    current: 0,
    info: ''
};
