import {useSelector} from "react-redux";
import {getCorrObj} from "../../../Reducers/CorrugatorSlice";

export const SelectorRS = (rsId) => {
    const corrObj = useSelector(getCorrObj);
    console.log('Selector RS', rsId, corrObj.rs1);

    switch (rsId) {
        case 1:
            return corrObj.rs1;
        case 2:
            return corrObj.rs2;
        case 3:
            return corrObj.rs3;
        case 4:
            return corrObj.rs4;
        case 5:
            return corrObj.rs5;
        case 6:
            return corrObj.rs6;
        case 7:
            return corrObj.rs7;
        default:
            throw new Error('Unhandled SelectorRollstand type');
    }
};
