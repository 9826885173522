import React from "react";
import {dataMotorDrives, dataMotorType, findLabelForKeyInArray} from "../../../Resources/InputFormData";
import {ContentRow} from "../../ContentRow";

const MotorCard = ({optObj}) => {
    return (
        <>
            <ContentRow label={'Main Drive Type'} value={findLabelForKeyInArray(dataMotorDrives, optObj.mainmotordrive)}/>
            <ContentRow label={'Main Motor Type'} value={findLabelForKeyInArray(dataMotorType, optObj.mainmotortype)}/>
            <ContentRow label={'Main Motor Power'} value={`${optObj.mainmotorpower} KW`}/>
            <ContentRow label={'Liquid Cooling'} value={optObj.motorcooling === true ? 'Yes' : 'No'}/>
        </>
    );
};

export default MotorCard;
